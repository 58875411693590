import React, { useState, useEffect, useRef } from "react";

function Dropdown({
  options,
  onSelect,
  onBlur,
  placeholder,
  label,
  labelClass,
  isManditory,
  selectedValue
}) {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selectedValue);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0)
  const [isMouseActive, setIsMouseActive] = useState(false);
  const dropdownRef = useRef(null);

  const handleKeyDown = (e) => {
    setSelectedOptionIndex((prevIndex) => {
      if (e.key === "ArrowUp") {
        return Math.max(0, prevIndex - 1);
      } else if (e.key === "ArrowDown") {
        return Math.min(options.length - 1, prevIndex + 1);
      } else if (e.key === 'Enter') {
        setSelectedOption(options[selectedOptionIndex]);
        onSelect(options[selectedOptionIndex]);
        onBlur(true)
        return prevIndex;
      }
      return prevIndex;
    });
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener('keydown', (event) => { setIsMouseActive(false) });

    document.addEventListener('mousemove', () => { setIsMouseActive(true) });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    onBlur(true); // Reset form-wide validation state
    setIsOpen(false);
  };

  const handleBlur = () => {
    if (!selectedOption) {
      onBlur(false); // Update form-wide validation state
    }
  };

  return (
    <div className="relative flex flex-col gap-[6px]">
      <label htmlFor={label} className={labelClass}>
        {label}
        {isManditory && <span> *</span>}
      </label>
      <div
        onKeyDown={handleKeyDown}
        ref={dropdownRef}
        className="p-[10px] rounded border border-loginFieldBorder h-10 flex items-center justify-between gap-5 text-[#333] cursor-pointer relative"
      >
        <button
          className="dropdown-toggle absolute w-full h-full left-0 right-0 px-[10px] text-left text-sm text-[#666] flex justify-between items-center"
          onClick={() => setIsOpen(!isOpen)}
          onBlur={handleBlur}
          aria-expanded={isOpen}
        >
          {selectedValue !== null ? selectedOption.value : placeholder}

          <div className="eyeIcon cursor-pointer absolute right-[10px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M7.4 7.9999L6 9.3999L12 15.3999L18 9.3999L16.6 7.9999L12 12.5999L7.4 7.9999Z" fill="#718BAE"></path>
            </svg>
          </div>
        </button>
        {isOpen && (
          <div className="dropdownContent left-0 absolute right-0 z-50 top-full mt-1 max-h-32 overflow-auto shadow-md">
            <div className="bg-white min-w-[190px] rounded-lg">
              <ul className="*:px-[14px] *:py-2">
                {options.map((option, index) => (
                  <li
                    key={option.id}
                    onClick={() => handleOptionSelect(option)}
                    className={isMouseActive ? "flex signIn gap-3 cursor-pointer hover:bg-[#F4F8FD]" : index === selectedOptionIndex ? "flex signIn gap-3 cursor-pointer bg-[#F4F8FD]" : "flex signIn gap-3 cursor-pointer"}
                  >
                    <span className="text-[#666] font-normal tracking-[0.15px] text-sm">
                      {option.value}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dropdown;
