import { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { updateUserProfile, logout } from '../../lib/api';
import { logOff } from '../../redux/userSlice';

import useInput from '../../hooks/use-input';
import Modal from '../UI/Modal';
import Button from '../UI/Button';
import LoadingSpinner from '../UI/LoadingSpinner';
import { isPassword, isOldPassword } from '../UI/Validations';

const ChangePassword = (props) => {

  const { currentUser } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const history = useHistory();

  const oldpasswordInputRef = useRef();
  const newpasswordInputRef = useRef();
  const confirmpasswordInputRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isChangePasswordFailed, setIsChangePasswordFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [oldpasswordtype, setOldPasswordType] = useState('password');
  const [newpasswordtype, setNewPasswordType] = useState('password');
  const [confirmpasswordtype, setConfirmPasswordType] = useState('password');

  const {
    value: oldpasswordValue,
    isValid: oldpasswordIsValid,
    hasError: oldpasswordHasError,
    valueChangeHandler: oldpasswordChangeHandler,
    inputBlurHandler: oldpasswordBlurHandler,
  } = useInput(isOldPassword);

  const {
    value: newpasswordValue,
    isValid: newpasswordIsValid,
    hasError: newpasswordHasError,
    valueChangeHandler: newpasswordChangeHandler,
    inputBlurHandler: newpasswordBlurHandler,
  } = useInput(isPassword);

  const {
    value: confirmpasswordValue,
    isValid: confirmpasswordIsValid,
    hasError: confirmpasswordHasError,
    valueChangeHandler: confirmpasswordChangeHandler,
    inputBlurHandler: confirmpasswordBlurHandler,
  } = useInput(isPassword, newpasswordValue);

  let formIsValid = false;

  if (
    !oldpasswordIsValid &&
    !newpasswordIsValid &&
    !confirmpasswordIsValid
  ) {
    formIsValid = true;
  }

  const toggleShowOldPassword = () => {
    if (oldpasswordtype === 'password') {
      setOldPasswordType('text')
    } else {
      setOldPasswordType('password')
    }
  }

  const toggleShowNewPassword = () => {
    if (newpasswordtype === 'password') {
      setNewPasswordType('text')
    } else {
      setNewPasswordType('password')
    }
  }

  const toggleShowConfirmPassword = () => {
    if (confirmpasswordtype === 'password') {
      setConfirmPasswordType('text')
    } else {
      setConfirmPasswordType('password')
    }
  }

  const signOut = () => {
    logout(currentUser.logout_token, currentUser.access_token, currentUser.csrf_token)
      .then((response) => {
        dispatch(logOff());
        history.push('/');
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const submitHandler = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    setIsLoading(true);

    const enteredNewPassword = newpasswordInputRef.current.value;
    const enteredOldPassword = oldpasswordInputRef.current.value;

    const updateData = {
      pass: [
        {
          existing: enteredOldPassword,
          value: enteredNewPassword
        }
      ],
    }

    updateUserProfile(currentUser.access_token, currentUser.current_user.uid, updateData)
      .then(async (res) => {
        setIsLoading(false)
        props.onClose()
        signOut()
      })
      .catch((error) => {
        setIsLoading(false)
        setIsChangePasswordFailed(true);
        setErrorMessage(error.message);
      })

  };

  const oldpasswordClasses = oldpasswordHasError ? 'passwordField p-[10px] rounded border border-loginFieldBorder h-10 flex items-center justify-between gap-5 bg-white invalid-password' : 'passwordField p-[10px] rounded border border-loginFieldBorder h-10 flex items-center justify-between gap-5 bg-white';

  const oldpasswordInputClass = oldpasswordHasError ? '!text-sm text-[#666] w-full block outline-none shadow-none invalid-pass-bg placeholder:text-[#666]' : '!text-sm text-[#666] w-full block outline-none shadow-none  placeholder:text-[#666]';

  const newpasswordClasses = (newpasswordIsValid) ? 'passwordField p-[10px] rounded border border-loginFieldBorder h-10 flex items-center justify-between gap-5 bg-white invalid-password' : 'passwordField p-[10px] rounded border border-loginFieldBorder h-10 flex items-center justify-between gap-5 bg-white';

  const newpasswordInputClass = newpasswordHasError ? '!text-sm text-[#666] w-full block outline-none shadow-none invalid-pass-bg placeholder:text-[#666]' : '!text-sm text-[#666] w-full block outline-none shadow-none  placeholder:text-[#666]';

  const confirmpasswordClasses = confirmpasswordHasError ? 'passwordField p-[10px] rounded border border-loginFieldBorder h-10 flex items-center justify-between gap-5 bg-white invalid-password' : 'passwordField p-[10px] rounded border border-loginFieldBorder h-10 flex items-center justify-between gap-5 bg-white';

  const confirmpasswordInputClass = confirmpasswordHasError ? '!text-sm text-[#666] w-full block outline-none shadow-none invalid-pass-bg placeholder:text-[#666]' : '!text-sm text-[#666] w-full block outline-none shadow-none  placeholder:text-[#666]';

  return (
    <Modal>
      <div className="loginHolder h-full flex grow mx-0 sm:mx-0 md:mx-4">
        <div className="flex items-stretch justify-normal  rounded-lg max-w-full sm:max-w-[480px] max-h-full sm:max-h-full h-full sm:max-h-auto sm:h-auto m-auto bg-white relative grow">
          {isLoading && (
            <div className="parentDisable">
              <LoadingSpinner />
            </div>
          )}
          <div className="loginFormHolder grow rounded-none sm:rounded-lg flex justify-center items-center shrink sm:shrink-0 md:shrink-0 bg-[#F4F8FD] sm:bg-[#F4F8FD] md:bg-white landscape:overflow-auto basis-full sm:basis-3/4 md:basis-1/2 lg:basis-[40%]">
            <div className="p-10 w-full loginSection">
              <div className='flex justify-between gap-4 items-center'>
                <h2 className="loginTitle text-[#354665] barlowBold text-xl">
                  Change Password
                </h2>
                <div className="closeIconHolder absolute sm:static left-10 sm:right-5 sm:left-auto top-5 cursor-pointer sm:landscape: right-10" onClick={props.onClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z"
                      fill="#717375"
                      stroke="#717375"
                    />
                    <path
                      d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z"
                      fill="#717375"
                      stroke="#717375"
                    />
                  </svg>
                </div>
              </div>
              <form className="loginForm mt-6" onSubmit={submitHandler}>
                <div className="space-y-6">
                  <div className="passwordSection gap-[6px] flex flex-col relative">
                    <div className="passwordLabel flex justify-between">
                      <span className="text-[#333] text-sm font-normal leading-5">
                        Old Password
                      </span>
                    </div>
                    <div className={oldpasswordClasses}>
                      <input
                        ref={oldpasswordInputRef}
                        type={oldpasswordtype}
                        id="oldpassword"
                        name="oldpassword"
                        onChange={oldpasswordChangeHandler}
                        onBlur={oldpasswordBlurHandler}
                        value={oldpasswordValue}
                        className={oldpasswordInputClass}
                        placeholder="Enter Your Old Password"
                      />

                      <div
                        className="eyeIcon cursor-pointer opacity-50"
                        onClick={toggleShowOldPassword}
                      >
                        {oldpasswordtype === "password" ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_3379_10992)">
                              <path
                                d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M15.5104 12.7C15.2504 14.11 14.1004 15.26 12.6904 15.52"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9.47 14.53L2 22"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M22.0003 2L14.5303 9.47"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3379_10992">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_3379_10991)">
                              <path
                                d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3379_10991">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                      </div>
                    </div>
                    {oldpasswordHasError && (
                      <p className="error-text">{oldpasswordIsValid}</p>
                    )}
                  </div>
                  <div className="passwordSection gap-[6px] flex flex-col relative">
                    <div className="passwordLabel flex justify-between">
                      <span className="text-[#333] text-sm font-normal leading-5">
                        New Password
                      </span>
                    </div>
                    <div className={newpasswordClasses}>
                      <input
                        ref={newpasswordInputRef}
                        type={newpasswordtype}
                        id="newpassword"
                        name="newpassword"
                        onChange={newpasswordChangeHandler}
                        onBlur={newpasswordBlurHandler}
                        value={newpasswordValue}
                        className={newpasswordInputClass}
                        placeholder="Enter Your New Password"
                      />

                      <div
                        className="eyeIcon cursor-pointer opacity-50"
                        onClick={toggleShowNewPassword}
                      >
                        {newpasswordtype === "password" ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_3379_10992)">
                              <path
                                d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M15.5104 12.7C15.2504 14.11 14.1004 15.26 12.6904 15.52"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9.47 14.53L2 22"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M22.0003 2L14.5303 9.47"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3379_10992">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_3379_10991)">
                              <path
                                d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3379_10991">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                      </div>
                    </div>
                    {newpasswordHasError && (
                      <p className="error-text">{newpasswordIsValid}</p>
                    )}
                  </div>
                  <div className="passwordSection gap-[6px] flex flex-col relative">
                    <div className="passwordLabel flex justify-between">
                      <span className="text-[#333] text-sm font-normal leading-5">
                        Confirm Password
                      </span>
                    </div>
                    <div className={confirmpasswordClasses}>
                      <input
                        ref={confirmpasswordInputRef}
                        type={confirmpasswordtype}
                        id="confirmpassword"
                        name="confirmpassword"
                        onChange={confirmpasswordChangeHandler}
                        onBlur={confirmpasswordBlurHandler}
                        value={confirmpasswordValue}
                        className={confirmpasswordInputClass}
                        placeholder="Enter Your Confirm Password"
                      />

                      <div
                        className="eyeIcon cursor-pointer opacity-50"
                        onClick={toggleShowConfirmPassword}
                      >
                        {confirmpasswordtype === "password" ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_3379_10992)">
                              <path
                                d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M15.5104 12.7C15.2504 14.11 14.1004 15.26 12.6904 15.52"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9.47 14.53L2 22"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M22.0003 2L14.5303 9.47"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3379_10992">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_3379_10991)">
                              <path
                                d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3379_10991">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                      </div>
                    </div>
                    {confirmpasswordHasError && (
                      <p className="error-text">Passwords doesn't matched!</p>
                    )}
                    {isChangePasswordFailed && (
                      <div className="error-message">
                        <span className="error-text">{errorMessage}</span>
                      </div>
                    )}
                  </div>
                  {!isLoading && (
                    <Button
                      disabled={!formIsValid}
                      className={!formIsValid ? "!mt-10 disabled btn loginBtn w-full bg-primaryBlue h-[46px] font-bold leading-5 text-base  justify-center items-center rounded text-white text-center cursor-pointer flex" : "!mt-10 btn loginBtn w-full bg-primaryBlue h-[46px] font-bold leading-5 text-base  justify-center items-center rounded text-white text-center cursor-pointer flex"}
                      type="submit"
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePassword;
