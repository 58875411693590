import { useState, useRef, useEffect } from 'react';
import Input from '../UI/Input';
import useInput from '../../hooks/use-input';
import Modal from '../UI/Modal';
import Button from '../UI/Button';
import { contactUs } from '../../lib/api';
import contactus_video from "../../assets/videos/Email.gif";
import { useModal } from '../../store/ModalContext';
import { isComments, isSubject, isEmail, isFirstName, isLastName, isCountry } from '../UI/Validations';
import LoadingSpinner from '../UI/LoadingSpinner';

const ContactUsForm = (props) => {
  const [successMessage, setSuccessMessage] = useState(false);
  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();
  const emailInputRef = useRef();
  const subjectInputRef = useRef();
  const countryInputRef = useRef();
  const commentsInputRef = useRef();
  const { closeContactUsModal } = useModal();
  const [isLoading, setIsLoading] = useState(false); 

  const {
    value: firstNameValue,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
  } = useInput(isFirstName);

  const {
    value: lastNameValue,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
  } = useInput(isLastName);

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(isEmail,);

  const {
    value: commentsValue,
    isValid: commentsIsValid,
    hasError: commentsHasError,
    valueChangeHandler: commentsChangeHandler,
    inputBlurHandler: commentsBlurHandler,
  } = useInput(isComments);

  const {
    value: subjectValue,
    isValid: subjectIsValid,
    hasError: subjectHasError,
    valueChangeHandler: subjectChangeHandler,
    inputBlurHandler: subjectBlurHandler,
  } = useInput(isSubject);

  const {
    value: CountryValue,
    isValid: countryIsValid,
    hasError: countryHasError,
    hasEmptyError: countryHasEmptyError,
    valueChangeHandler: countryChangeHandler,
    inputBlurHandler: countryBlurHandler,
  } = useInput(isCountry);

  let formIsValid = false;

  if (
    !firstNameIsValid &&
    !lastNameIsValid &&
    !emailIsValid &&
    !commentsIsValid &&
    !subjectIsValid &&
    !countryIsValid
  ) {
    formIsValid = true
  }

  const submitHandler = (event) => {
    setIsLoading(true);
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    // if (!isChecked) {
    //   setUnCheckedError('Please check the checkbox to proceed further.')
    //   return
    // }

    const enteredFirstname = firstNameInputRef.current.value;
    const enteredLastname = lastNameInputRef.current.value;
    const enteredEmail = emailInputRef.current.value;
    const enteredComments = commentsInputRef.current.value;
    const enteredSubject = subjectInputRef.current.value;
    const enteredCountry = countryInputRef.current.value;

    const formData = {
      contact_form: [{
        target_id: "need_help_form",
      }],
      name: [{
        value: enteredFirstname + ' ' + enteredLastname,
      }],
      mail: [{
        value: enteredEmail,
      }],
      field_country: [{
        value: enteredCountry
      }],
      subject: [{
        value: enteredSubject,
      }],
      message: [{
        value: enteredComments,
      }]
    };
    contactUs(formData)
      .then(async (res) => {
        if (res.ok) {
          setIsLoading(false);
          setSuccessMessage(true);
          return res.json();
        }
      })
      .catch((err) => {
        return err;
      });
  }

  useEffect(() => {

  }, []);

  const closeContactUs = () => {
    closeContactUsModal();
  }

  return (
    <Modal>
      {successMessage === false ? (
        <div className="appWrapper h-full w-full mx-0 md:mx-4">
          {isLoading && (
            <div className="parentDisable">
              <LoadingSpinner />
            </div>
          )}           
          <div className="supportFormHolder h-full flex">
            <div className="formHolder contactUsFormBgHolder sm:max-w-[480px] w-full max-h-full  h-full sm:h-auto sm:max-h-[620px]  bg-[#F4F8FD] sm:bg-[#F4F8FD] md:bg-white flex justify-center m-auto overflow-auto rounded-none landscape:rounded-lg md:rounded-lg relative">
              <div className="px-10 sm:px-10 md:px-10 lg:px-14 w-full  max-[480px]:max-h-[85vh] overflow-auto sm:h-full py-10 flex m-auto">
                <div className="flex items-stretch flex-col sm:flex-row w-full">
                  <div className="videoIconHolder basis-4/12 aspect-square self-center hidden">
                    <img src={contactus_video} className="w-full h-full rounded-tl-lg rounded-tr-lg object-cover" />
                  </div>
                  <div className="bar w-[1px] bg-[#7DA1C4] opacity-60 ml-[55px] mr-[46px]  hidden"></div>
                  <div className="contactUsFormHolder basis-full lg:basis-8/12 h-full w-full">
                    <div className="flex flex-col h-full">

                      <form className="flex flex-col justify-between h-full gap-3 static z-50" onSubmit={submitHandler}>
                        <div className="flex flex-col gap-6 sm:gap-8 basis-[85%] sm:basis-auto sm:overflow-visible overflow-auto formWrapper">
                          <div className="formHeader">
                            <div className="flex justify-between items-center">
                              <h2 className="modalTitle text-[#354665] barlowBold text-xl">Contact Us For Help</h2>
                              <div className="closeIconHolder absolute sm:static left-10 sm:right-5 sm:left-auto top-5 cursor-pointer sm:landscape: right-10" onClick={closeContactUs}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z"
                                    fill="#717375"
                                    stroke="#717375"
                                  />
                                  <path
                                    d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z"
                                    fill="#717375"
                                    stroke="#717375"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="contactUsForm h-full">
                            <div className="space-y-4 sm:space-y-6">
                              <div className="firstNameSection gap-[6px] flex flex-col relative">
                                <div className="flex flex-col gap-[6px]">
                                  <Input
                                    ref={firstNameInputRef}
                                    label="First Name"
                                    labelClass="firstNameLabel text-[#333] text-sm font-normal leading-5"
                                    isManditory={true}
                                    input={{
                                      id: "firstName",
                                      name: "firstName",
                                      type: "text",
                                      value: firstNameValue,
                                      placeholder: "Enter First Name",
                                      onChange: (event) => {
                                        firstNameChangeHandler(event);
                                      },
                                      onBlur: firstNameBlurHandler,
                                      className:
                                        "p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm placeholder:text-[#666]  !text-[#333]",
                                    }}
                                  />
                                  {firstNameHasError && (
                                    <p className="error-text">
                                      {firstNameIsValid}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className='lastNameSection gap-[6px] flex flex-col relative'>
                                <div className="flex flex-col gap-[6px]">
                                  <Input
                                    ref={lastNameInputRef}
                                    label="Last Name"
                                    labelClass="lastNameLabel text-[#333] text-sm font-normal leading-5"
                                    isManditory={true}
                                    input={{
                                      id: "lastName",
                                      name: "lastName",
                                      type: "text",
                                      value: lastNameValue,
                                      placeholder: "Enter Last Name",
                                      onChange: lastNameChangeHandler,
                                      onBlur: lastNameBlurHandler,
                                      className:
                                        "p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm placeholder:text-[#666]  !text-[#333]",
                                    }}
                                  />
                                  {lastNameHasError && (
                                    <p className="error-text">
                                      {lastNameIsValid}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="emailSection gap-[6px] flex flex-col relative">
                                <Input
                                  ref={emailInputRef}
                                  label="Email"
                                  labelClass="emailNameLabel text-[#333] text-sm font-normal leading-5"
                                  isManditory={true}
                                  input={{
                                    id: "email",
                                    name: "email",
                                    type: "email",
                                    value: emailValue,
                                    placeholder: "Enter Your Email",
                                    onChange: emailChangeHandler,
                                    onBlur: emailBlurHandler,
                                    className:
                                      "p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm placeholder:text-[#666]  !text-[#333]",
                                  }}
                                />
                                {emailHasError && (
                                  <p className="error-text">
                                    {emailIsValid}
                                  </p>
                                )}
                              </div>
                              <div className="interestedSection gap-[6px] flex flex-col relative">
                                <Input
                                  ref={countryInputRef}
                                  label="Country"
                                  labelClass="firstNameLabel text-[#333] text-sm font-normal leading-5"
                                  isManditory={true}
                                  input={{
                                    id: "country",
                                    name: "country",
                                    type: "country",
                                    value: CountryValue,
                                    placeholder: "Enter Country",
                                    onChange: countryChangeHandler,
                                    onBlur: countryBlurHandler,
                                    className:
                                      "p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm placeholder:text-[#666]  !text-[#333]",
                                  }}
                                />
                                {countryHasError && (
                                  <p className="error-text">
                                    {countryIsValid}
                                  </p>
                                )}
                              </div>
                              <div className="interestedSection gap-[6px] flex flex-col relative">
                                <Input
                                  ref={subjectInputRef}
                                  label="Subject"
                                  labelClass="firstNameLabel text-[#333] text-sm font-normal leading-5"
                                  isManditory={true}
                                  input={{
                                    id: "subject",
                                    name: "subject",
                                    type: "text",
                                    value: subjectValue,
                                    placeholder: "Enter Subject",
                                    onChange: subjectChangeHandler,
                                    onBlur: subjectBlurHandler,
                                    className:
                                      "p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm placeholder:text-[#666]  !text-[#333]",
                                  }}
                                />
                                {subjectHasError && (
                                  <p className="error-text">
                                    {subjectIsValid}.
                                  </p>
                                )}
                              </div>
                              <div className="commentsSection gap-[6px] flex flex-col">
                                <label className="titleLabel text-[#333] text-sm font-normal leading-5">Comments *</label>
                                <textarea id="message" className="p-[10px] rounded border border-loginFieldBorder text-[#666]  placeholder:text-[#666] text-sm " placeholder="Enter Your Comments Here...." rows="4" cols="50" onBlur={commentsBlurHandler} onChange={commentsChangeHandler} ref={commentsInputRef} value={commentsValue}></textarea>
                              </div>
                              {commentsHasError && (
                                <p className="error-text">
                                  {commentsIsValid}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="buttonHolder basis-auto flex items-end">
                          <Button
                            disabled={!formIsValid}
                            type="submit"
                            className={!formIsValid ? "btn mt-8 submitBtn w-full bg-primaryBlue h-[46px] px-20 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center disabled mb-0" : "btn mt-8 continueBtn w-full bg-primaryBlue h-[46px] px-20 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center cursor-pointer mb-0"}
                          >
                            Submit
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>) : (<div className="appWrapper flex flex-col h-screen md:h-auto relative justify-center w-full">
          <div className="thanksMsgHolder max-w-full w-full sm:w-[480px] sm:max-w-[480px]  min-h-full sm:min-h-[220px] bg-[#F4F8FD] sm:bg-[#F4F8FD] flex items-center justify-start rounded-none sm:rounded-lg  mx-auto">
            <div className="px-10 sm:px-10 md:px-10 lg:px-14 py-10 w-full">
              <div className="flex items-stretch flex-col sm:flex-row">
                <div className="bar w-[1px] bg-[#7DA1C4] opacity-60 ml-[55px] mr-[46px] hidden"></div>
                <div className="message w-full">
                  <p className="text-base sm:text-lg leading-6  text-[#333] font-normal tracking-[0.2px]">Thanks for contacting us our team will get back to you shortly.</p>
                  <Button className="btn mt-10 continueBtn  bg-primaryBlue h-[46px] px-20 py-[10px] font-bold leading-5  text-base justify-center  rounded text-white inline-flex items-center cursor-pointer w-full" onClick={closeContactUs}>Continue</Button>
                </div>
              </div>
            </div>
          </div>
        </div>)}
    </Modal>
  );
};

export default ContactUsForm;