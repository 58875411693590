import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getPagesData } from '../../lib/api';
import LoadingSpinner from '../UI/LoadingSpinner';

const FooterLinkData = () => {

    const { linkname } = useParams();

    const [linkData, setLinkData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const linkDataSendRequest = useCallback(() => {
        window.scrollTo(0, 0);
        setIsLoading(true)
        getPagesData(linkname)
            .then((response) => {
                setIsLoading(false)
                setLinkData(response);
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error);
            });
    },[linkname])

    useEffect(() => {
        linkDataSendRequest();
    }, [linkDataSendRequest])

    return (
        <div className='footerContentHolder  px-5 sm:px-5 md:px-5 lg:px-14 py-8 sm:py-8 md:py-8 lg:py-12'>
            {isLoading && (
                <div className="parentDisable">
                    <LoadingSpinner />
                </div>
            )}
            {linkData && linkData[0] && <>
                <div>{linkData[0].title}</div>
                <div dangerouslySetInnerHTML={{ __html: linkData[0].body }}></div>
            </>
            }
        </div>
    )
}

export default FooterLinkData;