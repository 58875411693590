import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const OphthalmicPrecision = () => {
    return (
    <section className="section ophthalmicPrecisionSection">
    <div className="ophthalmicPrecision bg-[#006FBA] px-5 sm:px-5 md:px-5 lg:px-14 py-8 sm:py-8 md:py-8 lg:py-12 h-[280px] sm:h-[360px] md:h-[460px] flex items-center justify-start overflow-hidden">
    <Carousel
    key={1}
    showArrows={false}
    showThumbs={false}
    autoPlay={true}
    interval={3000}
    infiniteLoop={true}
    stopOnHover={true}
    showStatus={false}
    >
        <div key={1} className="sliders contentOne">
            <h2 className="flex items-center text-white barlowMedium text-[32px] leading-10 sm:text-[46px] sm:leading-[52px] tracking-[-0.25px] text-left px-5 sm:px-5 md:px-5 lg:px-14">Education is the key to unlocking your full potential</h2>
        </div>
        <div key={2} className="sliders contentTwo">
            <div className="flex flex-col gap-4 *:text-white *:text-left !h-auto px-5 sm:px-5 md:px-5 lg:px-14">
                <h2 className="text-white barlowMedium text-[32px] leading-10 sm:text-[46px] sm:leading-[52px] tracking-[-0.25px]">Video Based Learning</h2>
                <p className="text-[#333] font-normal sm:text-2xl sm:leading-8 text-base leading-6">Visualize surgical steps and techniques through videos of real procedure</p>
            </div>
        </div>
        <div key={3} className="sliders contentThree">
            <div className="flex flex-col gap-4 *:text-white *:text-left !h-auto px-5 sm:px-5 md:px-5 lg:px-14">
                <h2 className="text-white barlowMedium text-[32px] leading-10 sm:text-[46px] sm:leading-[52px] tracking-[-0.25px]">A Customizable Approach</h2>
                <p className="text-[#333] font-normal sm:text-2xl sm:leading-8 text-base leading-6">Tailor your search to meet your skill level and time pressure</p>
            </div>
        </div>
        <div key={4} className="sliders contentFour">
            <div className="flex flex-col gap-4 *:text-white *:text-left !h-auto px-5 sm:px-5 md:px-5 lg:px-14">
                <h2 className="text-white barlowMedium text-[32px] leading-10 sm:text-[46px] sm:leading-[52px] tracking-[-0.25px]">Practical Insights</h2>
                <p className="text-[#333] font-normal sm:text-2xl sm:leading-8 text-base leading-6">Access to masters in cataract and refractive surgery</p>
            </div>
        </div>                        
    </Carousel>        
    </div>
    </section>
    );
}

export default OphthalmicPrecision;