import { useState, useEffect, useContext } from 'react';
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import AuthContext from "../../store/auth-context";
import useHttp from "../../hooks/use-http";
import { register } from '../../lib/api';
import LoadingSpinner from '../UI/LoadingSpinner';

const GdprConsent = ({ onClose, openRegisterConfirmModal }) => {
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const { sendRequest, status, error } = useHttp(register);
  const userRegister = () => {
    setIsLoading(true);
    sendRequest(authCtx.regUser);
  }
  const cancelRegister = () => {
    onClose();
  }
  useEffect(() => {
    if (status === 'completed' && error === null) {
      setIsLoading(false);
      authCtx.regUser = [];
      openRegisterConfirmModal();
    } else if (error != null) {
      setIsLoading(false)
    }
  }, [status, error, authCtx, openRegisterConfirmModal])
  return (
    <Modal>
      <div className="gdprConsentHolder h-full sm:h-full md:h-screen flex grow mx-0 sm:mx-0 md:mx-4">
        <div className="gdprConsentHolderBg flex items-stretch justify-normal h-screen landscape:h-auto sm:h-screen sm:landscape:h-auto rounded-none sm:rounded-none md:rounded-lg max-w-[1162px] max-h-full sm:max-h-full  md:max-h-[620px] md-h-[620px] m-auto bg-white relative px-10 py-8 grow">
          {isLoading && (
            <div className="parentDisable">
              <LoadingSpinner />
            </div>
          )}
          <div className="w-full flex flex-col gap-6">
            <div className="gdprHeaderAndBody flex flex-col h-full gap-6 basis-full overflow-auto">
              <div className="gdprHeader flex justify-between items-center gap-6">
                <h2 className="pageTitle  text-[#354665] barlowBold text-base  sm:text-xl uppercase">
                  GDPR CONSENT STATEMENT
                </h2>
              </div>
              <div className="gdprBody basis-full overflow-auto">
                <div className="gdprBodyContent">
                  <div className="p-5 b border border-[#D9D9D9] bg-[#FCFCFC] flex flex-col gap-4 *:text-[#333] *:font-normal *:tex-sm *:leading-5 *:sm:text-base *:md:text-xl *:md:leading-7">
                    <p>
                      We, at MastersVideoLibrary, believe the protection of personal data
                      involves far more than just complying with legislation. We
                      are therefore happy that you are interested in how we
                      handle personal data.
                    </p>
                    <p>
                      We always process personal data such as a person’s name,
                      address, e-mail address, or phone number in accordance
                      with applicable privacy law.
                    </p>
                    <p>
                      With this privacy notice, we would like to inform everyone
                      about the nature, scope, and purpose of the personal data
                      that we collect, use, and process. Furthermore, we would
                      like to explain to the persons concerned the rights to
                      which they are entitled.
                    </p>
                    <p>
                      MastersVideoLibrary has implemented numerous technical and
                      organizational measures to ensure that the personal data
                      processed via this Web site is protected as
                      comprehensively as possible.
                    </p>
                    <p>
                      Despite the care we take, Internet-based data transmission
                      is vulnerable to security flaws and, consequently,
                      absolute protection can never be guaranteed.
                    </p>
                    <p>
                      For this reason, you of course have the option of
                      transmitting your personal data by alternative means, for
                      example, by telephone or post.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdprFooter basis-1/4 flex  items-end">
              <div className="gdprActions flex max-[420px]:flex-wrap  sm:flex-nowrap gap-4 sm:gap-5 grow *:min-w-[154px] *:max-[420px]:w-full">
                <Button onClick={userRegister} className="btn concernBtn w-auto bg-primaryBlue h-[46px] px-5 py-[10px] font-bold leading-5 text-sm sm:text-base flex justify-center items-center rounded text-white whitespace-nowrap">I Consent</Button>
                <Button onClick={cancelRegister} className="btn dontConcernBtn  w-auto bg-primaryBlue h-[46px] px-5 py-[10px] font-bold leading-5 text-sm sm:text-base flex justify-center items-center rounded text-white whitespace-nowrap">I Do Not Consent</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GdprConsent;
