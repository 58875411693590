import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    authorList: null,
};

export const authorSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        updateAuthorList: (state, action) => {
            state.authorList = action.payload;
        },
        clearAuthorList: (state) => {
            state.authorList = null;
        },        
    },
});

export const { updateAuthorList, clearAuthorList } = authorSlice.actions;

export default authorSlice.reducer;