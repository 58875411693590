import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    videoDetail: null,
};

export const videodetailSlice = createSlice({
    name: "videodetail",
    initialState,
    reducers: {
        updateVideoDetail: (state, action) => {
            state.videoDetail = action.payload;
        },        
        clearVideoDetail: (state) => {
            state.videoDetail = null;
        },        
    },
});

export const { updateVideoDetail, clearVideoDetail } = videodetailSlice.actions;

export default videodetailSlice.reducer;