import { useHistory } from 'react-router-dom';
import AuthorList from '../TeamMembers/AuthorList';

const TeamMembers = () => {
    const history = useHistory()
    const handleViewAllTeamMembers = () => {
        history.push('/allTeamMembers')
    }
    return (
        <section className="section authorsSection" id='authorDetails'>
            <div className="bg-[#F4F8FD] px-5 sm:px-5 md:px-5 lg:px-14 pt-5 sm:pt-5 md:pt-5 lg:pt-7 pb-10 sm:pb-10 md:pb-10 lg:pb-14">
                <div className="flex justify-between items-start gap-x-6 gap-y-4 max-[420px]:flex-wrap">
                    <div className='basis-3/4'>
                        <h2 className="text-[#333] text-lg barlowBold leading-5 sm:text-[28px] sm:leading-9 sm:tracking-[-0.25px]">Masters</h2>
                        <p className="text-sm sm:text-base leading-5 sm:leading-6 font-normal text-[#333] mt-[2px]">Learn from the below Masters along with over 300 contributing experts</p>
                    </div>
                    <button className="viewAll text-[#005BC3] font-bold sm:text-lg sm:leading-8 cursor-pointer" onClick={handleViewAllTeamMembers}>View all</button>
                </div>
                <AuthorList />
            </div>
        </section>
    )
}

export default TeamMembers;