import React from "react";

const AuthContext = React.createContext({
  regUser: [],
});

export const AuthContextProvider = (props) => {

  const contextValue = {
    regUser: [],
  };
  
  return (
    <AuthContext.Provider value={{ contextValue }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;