import { useRef, useState } from 'react';
import Button from '../UI/Button';
import Modal from '../UI/Modal';
import Input from '../UI/Input';
import useInput from '../../hooks/use-input';
import { emailPasswordLinkSend } from '../../lib/api';
import LoadingSpinner from '../UI/LoadingSpinner';
import { isEmail } from '../UI/Validations';

const ForgotPasswordForm = (props) => {

  const emailInputRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(isEmail);

  let formIsValid = false;

  if (!emailIsValid) {
    formIsValid = true;
  }

  const submitHandler = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    setIsLoading(true);

    const enteredEmail = emailInputRef.current.value;

    const updateData = {
      "mail": enteredEmail
    }

    emailPasswordLinkSend(updateData)
      .then(async (res) => {
        setIsLoading(false);
        setSuccessMessage(true);
      })
      .catch((error) => {
        setIsLoading(false)
      })

  };

  const emailClasses = emailHasError ? 'emailSection gap-1 flex flex-col invalid' : 'emailSection gap-1 flex flex-col';

  return (
    <Modal>
      <div className="loginHolder h-full flex grow mx-0 sm:mx-0 md:mx-4">
        <div className="flex items-stretch justify-normal  rounded-lg max-w-full sm:max-w-[480px] sm:min-h-[220px] min-h-full max-h-full sm:max-h-full h-full sm:max-h-auto sm:h-auto m-auto bg-white relative grow">
          {isLoading && (
            <div className="parentDisable">
              <LoadingSpinner />
            </div>
          )}
          <div className="loginFormHolder grow   rounded-none sm:rounded-lg flex justify-center items-center shrink sm:shrink-0 md:shrink-0 bg-[#F4F8FD] sm:bg-[#F4F8FD] md:bg-white landscape:overflow-auto basis-full sm:basis-3/4 md:basis-1/2 lg:basis-[40%]">
            <div className="p-10 w-full loginSection">
              <div className='flex justify-between gap-4 items-center'>
                <h2 className="forgotPasswordTitle text-[#354665] barlowBold text-xl leading-normal">
                  Forgot password?
                </h2>
                <div className={successMessage === false ? "closeIconHolder absolute sm:static left-10 sm:right-5 sm:left-auto top-5 cursor-pointer sm:landscape: right-10" : "closeIconHolder absolute sm:static left-10 sm:right-5 sm:left-auto top-5 cursor-pointer sm:landscape: right-10 hidden"} onClick={props.onClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z"
                      fill="#717375"
                      stroke="#717375"
                    />
                    <path
                      d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z"
                      fill="#717375"
                      stroke="#717375"
                    />
                  </svg>
                </div>
              </div>
              {successMessage === false ? (
                <>
                  <p className="text-dark text-sm leading-5 font-normal mt-2">
                    Enter your email below to receive your
                    <br /> password reset instructions
                  </p>
                  <form className="forgotPasswordForm mt-8" onSubmit={submitHandler}>
                    <div className="space-y-6">
                      <div className="registeredEmailSection gap-[6px] flex flex-col hidden">
                        <label className="passwordLabel text-[#333] text-sm font-normal leading-5">
                          Registered Email
                        </label>
                        <input
                          type="email"
                          className="p-[10px] !text-sm rounded border border-loginFieldBorder text-[#666] h-10 placeholder:text-[#666]"
                          placeholder="Enter Email"
                        />
                      </div>
                      <div className={emailClasses}>
                        <Input
                          ref={emailInputRef}
                          label="Email"
                          labelClass="emailLabel text-[#333] text-sm font-normal leading-5"
                          input={{
                            id: "email",
                            type: "email",
                            value: emailValue,
                            placeholder: "Enter Your Email",
                            onChange: emailChangeHandler,
                            onBlur: emailBlurHandler,
                            className:
                              "!text-sm p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 bg-white loginField placeholder:text-[#666]",
                          }}
                        />
                        {emailHasError && (
                          <p className="error-text">
                            {emailIsValid}
                          </p>
                        )}
                      </div>
                      <Button
                        disable={!formIsValid}
                        type="submit" className={!formIsValid ? "btn sendBtn w-full bg-primaryBlue h-[46px] px-5 py-[10px] font-bold leading-5 text-base flex justify-center items-center rounded text-white disabled" : "btn sendBtn w-full bg-primaryBlue h-[46px] px-5 py-[10px] font-bold leading-5 text-base flex justify-center items-center rounded text-white"}>
                        Send
                      </Button>
                      <button
                        onClick={props.backToLogin}
                        className="backToLoginBtn w-full text-primaryText h-[46px] px-5 pt-[10px] font-normal leading-5 text-base flex justify-center items-center rounded"
                      >
                        Back to Login
                      </button>
                    </div>
                  </form>
                </>
              ) : (<div className="searchResutsVideos">
                <p className="text-dark text-sm leading-5 font-normal mt-2">Further instructions have been sent to <br />your email address.</p>
                <div className="buttonHolder" onClick={props.onClose}>
                  <button className="btn mt-10 submitBtn w-full bg-primaryBlue h-[46px] px-5 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center min-w-[140px]">OK</button>
                </div>
              </div>)}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ForgotPasswordForm;