import React from "react";

const Options = (props) => {
  const { i, answer, questionId, selected, handleSelect, interest } = props;

  if (interest) {
    return answer.map((ans) => (
      <div
        key={ans.tid}
        className={selected[i]?.options.includes(ans.tid) ? "active text-center" : "text-center"}
        onClick={() => handleSelect(questionId, ans.tid, ans.name)}
      >
        {ans.name}
      </div>
    ));
  }

  return answer.map((ans) => (
    <div
      key={ans.id}
      className={selected[i]?.options.includes(ans.id) ? "active text-center" : "text-center"}
      onClick={() => handleSelect(questionId, ans.id)}
    >
      {ans.answer}
    </div>
  ));
};

export default Options;
