import { useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { loginFailure, loginStart, loginSuccess, updateUserImage, updateUserName } from '../../redux/userSlice';
import { login } from '../../lib/api';
import Input from '../UI/Input';
import useInput from '../../hooks/use-input';
import MastersVideoLibraryLogo from '../../assets/images/logoColor.svg';
import Modal from '../UI/Modal';
import Button from '../UI/Button';
import LoadingSpinner from '../UI/LoadingSpinner';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { isEmail, isLoginPassword } from '../UI/Validations'

const LoginForm = (props) => {

  const dispatch = useDispatch();

  const history = useHistory();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [errorMessage, setIsErrorMessage] = useState("");
  const [type, setType] = useState('password')

  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
  } = useInput(isLoginPassword);

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(isEmail);

  let formIsValid = false;

  if (!passwordIsValid && !emailIsValid) {
    formIsValid = true;
  }

  const toggleShowPassword = () => {
    if (type === 'password') {
      setType('text')
    } else {
      setType('password')
    }
  }

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(loginStart());

    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    setIsLoading(true);

    const credential = ({
      name: enteredEmail,
      pass: enteredPassword
    })

    login(credential)
      .then((res) => {
        setIsLoading(false);
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let errMessage = 'Invalid email or password.';
            setIsLoginFailed(true);
            setIsErrorMessage(errMessage);
            if (data && data.error && data.error.message) {
              errMessage = data.error.message;
            }
            throw new Error(errMessage);
          });
        }
      })
      .then((data) => {
        dispatch(loginSuccess(data));
        dispatch(updateUserName(data.user_extra_details?.first_name + " " + data.user_extra_details?.last_name))
        dispatch(updateUserImage(data.user_extra_details?.user_picture))
        props.onClose();
        let video_path = location.pathname.split("/");
        if (video_path[1] === 'video-details') {
          window.location.reload();
        }
        if (data.user_extra_details.user_persona_status === 1) {
          history.push({
            pathname: '/specialization',
            state: { from: 'login' }
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        dispatch(loginFailure());
      });
  };

  const passwordClasses = passwordHasError ? 'passwordField p-[10px] rounded border border-loginFieldBorder h-10 flex items-center justify-between gap-5 bg-white invalid-password' : 'passwordField p-[10px] rounded border border-loginFieldBorder h-10 flex items-center justify-between gap-5 bg-white';
  const emailClasses = emailHasError ? 'emailSection gap-1 flex flex-col invalid' : 'emailSection gap-1 flex flex-col';
  const passwordInputClass = passwordHasError ? '!text-sm text-[#666] w-full block outline-none shadow-none invalid-pass-bg placeholder:text-[#666]' : '!text-sm text-[#666] w-full block outline-none shadow-none  placeholder:text-[#666]'

  return (
    <Modal>
      <div className="loginHolder h-full flex grow mx-0 sm:mx-0 md:mx-4">
        <div className="flex items-stretch justify-normal  rounded-lg max-w-full sm:max-w-[480px] max-h-full sm:max-h-full h-full sm:max-h-auto sm:h-auto m-auto bg-white relative grow">
          {isLoading && (
            <div className="parentDisable">
              <LoadingSpinner />
            </div>
          )}
          <div className="loginFormHolder grow rounded-none sm:rounded-lg flex justify-center items-center shrink sm:shrink-0 md:shrink-0 bg-[#F4F8FD] sm:bg-[#F4F8FD] md:bg-white landscape:overflow-auto basis-full sm:basis-3/4 md:basis-1/2 lg:basis-[40%]">
            <div className="p-10 w-full loginSection">
              <img
                src={MastersVideoLibraryLogo}
                alt="logo"
                className="w-[280px] sm:w-[280px] md:w-[280px] mb-6 m-auto object-cover hidden"
              />
              <div className='flex justify-between gap-4 items-center'>
                <h2 className="loginTitle text-[#354665] barlowBold text-xl">
                  LOGIN
                </h2>
                <div className="closeIconHolder absolute sm:static left-10 sm:right-5 sm:left-auto top-5 cursor-pointer sm:landscape: right-10" onClick={props.onClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z"
                      fill="#717375"
                      stroke="#717375"
                    />
                    <path
                      d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z"
                      fill="#717375"
                      stroke="#717375"
                    />
                  </svg>
                </div>
              </div>
              <form className="loginForm mt-6" onSubmit={submitHandler}>
                <div className="space-y-6">
                  <div className={emailClasses}>
                    <Input
                      ref={emailInputRef}
                      label="Email"
                      labelClass="emailLabel text-[#333] text-sm font-normal leading-5"
                      input={{
                        id: "email",
                        type: "email",
                        value: emailValue,
                        placeholder: "Enter Your Email",
                        onChange: emailChangeHandler,
                        onBlur: emailBlurHandler,
                        className:
                          "!text-sm p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 bg-white loginField placeholder:text-[#666]",
                      }}
                    />
                    {emailHasError && (
                      <p className="error-text">
                        {emailIsValid}
                      </p>
                    )}
                  </div>
                  <div className="passwordSection gap-[6px] flex flex-col relative">
                    <div className="passwordLabel flex justify-between">
                      <span className="text-[#333] text-sm font-normal leading-5">
                        Password
                      </span>

                      <button
                        onClick={props.handleForgotPassword}
                        className="text-primaryBlue leading-5 font-normal text-sm cursor-pointer"
                        type='button'
                      >
                        Forgot password?
                      </button>
                    </div>
                    <div className={passwordClasses}>
                      <input
                        ref={passwordInputRef}
                        type={type}
                        id="password"
                        name="password"
                        onChange={passwordChangeHandler}
                        onBlur={passwordBlurHandler}
                        value={passwordValue}
                        className={passwordInputClass}
                        placeholder="Enter Your Password"
                      />

                      <div
                        className="eyeIcon cursor-pointer opacity-50"
                        onClick={toggleShowPassword}
                      >
                        {type === "password" ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_3379_10992)">
                              <path
                                d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M15.5104 12.7C15.2504 14.11 14.1004 15.26 12.6904 15.52"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9.47 14.53L2 22"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M22.0003 2L14.5303 9.47"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3379_10992">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_3379_10991)">
                              <path
                                d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3379_10991">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                      </div>
                    </div>
                    {passwordHasError && (
                      <p className="error-text">{passwordIsValid}</p>
                    )}
                    {!emailHasError && !passwordHasError && isLoginFailed && (
                      <div className="error-message">
                        <span className="error-text">{errorMessage}</span>
                      </div>
                    )}
                  </div>
                  {/* <ReCAPTCHA sitekey="6LfIYpwpAAAAADgd0BqPy8ioxAcK1NorcZY_LUO8" onChange={onChangeCaptcha} ref={recaptchaInputRef} /> */}
                  <GoogleReCaptchaProvider reCaptchaKey="6LenOb8pAAAAADs2rIP_G_8qjmJLTXWdPwvlzHqU">
                    <GoogleReCaptcha
                      className="google-recaptcha-custom-class"
                    />
                  </GoogleReCaptchaProvider>
                  {/* {captchaError && (
                      <p className="error-text">Please select Captcha.</p>
                    )} */}
                  {!isLoading && (
                    <Button
                      disabled={!formIsValid}
                      className={!formIsValid ? "disabled btn loginBtn w-full bg-primaryBlue h-[46px] font-bold leading-5 text-base  justify-center items-center rounded text-white text-center flex" : "btn loginBtn w-full bg-primaryBlue h-[46px] font-bold leading-5 text-base  justify-center items-center rounded text-white text-center flex"}
                      type="submit"
                    >
                      Login
                    </Button>
                  )}
                  <p className="dontHaveAccount text-[#354665] leading-normal text-base font-normal text-center !mt-8">
                    Don’t have an account yet?
                  </p>

                  <Button
                    onClick={props.handleRegister}
                    className="!mt-4 createAccountBtn h-[46px] rounded text-[#005BC3] leading-5 text-base font-bold w-full border border-primaryBlue whitespace-wrap bg-white  flex items-center justify-center hover:bg-primaryBlue hover:text-white transition-all ease-out duration-300"
                    target="_blank"
                  >
                    Create an account
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginForm;
