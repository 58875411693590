import Modal from "../UI/Modal";
import { useSelector } from 'react-redux'

const ForgotPasswordConfirmationMessage = (props) => {
    return (
        <Modal>
            <div className="loginHolder h-screen flex grow mx-0 sm:mx-0 md:mx-4">
                <div className="flex items-stretch justify-normal  rounded-lg max-w-full sm:max-w-[480px] max-h-full sm:h-[250px] sm:max-h-[250px] h-full sm:max-h-auto  m-auto bg-white relative grow">
                    <div className="loginFormHolder grow rounded-none sm:rounded-lg  flex justify-center items-center shrink sm:shrink-0 md:shrink-0 bg-[#F4F8FD] sm:bg-[#F4F8FD] md:bg-white landscape:overflow-auto basis-full sm:basis-3/4 md:basis-1/2 lg:basis-[40%]">
                        <div className="p-10 loginSection">
                            <div className='flex justify-between gap-4 items-center'>
                                <h2 className="forgotPasswordTitle text-[#354665] barlowBold text-xl leading-normal">Check Your Email</h2>
                                <div className="closeIconHolder absolute left-10 sm:right-5 sm:left-auto top-5 cursor-pointer sm:landscape: right-10" onClick={props.onClose}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z" fill="#717375" stroke="#717375" />
                                        <path d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z" fill="#717375" stroke="#717375" />
                                    </svg>
                                </div>
                            </div>
                            <p className="text-dark text-sm leading-5 font-normal mt-2 max-[420px]:max-w-full max-w-[70%]">We’ve sent instructions on how to reset your password to registered <span><button onClick={props.openResetPassword} className="text-primaryText cursor-pointer">email</button></span></p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ForgotPasswordConfirmationMessage;