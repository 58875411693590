import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import Options from "./Options";
import { addInterestCategories, getCategory, updateUserPersonaStatus, getQuestionnaire, getQuestionList, addAnswer } from "../../lib/api";
import { updateInterestedCategories } from "../../redux/userSlice";
import { useSelector } from "react-redux";
import LoadingSpinner from "../UI/LoadingSpinner";
const Specialization = () => {
  const { currentUser, interestedCategories } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [next, setNext] = useState(0);
  const [selectedInterest, setSelectedInterest] = useState([]);
  const [selected, setSelected] = useState([]);
  const [interest, setInterest] = useState(true);
  const [interestList, setInterestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [interestNext, setInterestNext] = useState(true);
  const [interestModify, setInterestModify] = useState(false);
  const [rangeNext, setRangeNext] = useState(true);
  const [interestedCategory, setInterestedCategory] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [answer, setAnswer] = useState();
  const [backStatus, setBackStatus] = useState(false);
  const [back, setBack] = useState(true);

  const handlerSkip = (i) => {
    setNext((count) => count + 1);
    setBackStatus(false)
    setRangeNext(selected[i + 1]?.options.length === 0 ?? false);
  };

  const currentList = () => {
    const interestedArray = [];
    interestedCategories.map((details) => {
      interestedArray.push(details.id);
    });
    return [{ options: interestedArray }];
  }

  const handlerNext = (i) => {
    setNext((count) => count + 1);
    setAnswer();
    setBackStatus(false)
    if (typeof answer != "undefined") {
      setIsLoading(true);
      addAnswer(currentUser.access_token, answer).then(() => {
        setIsLoading(false);
      });
    }
    setRangeNext(selected[i + 1]?.options.length === 0 ?? false);
  };

  const handlerNextInterest = () => {
    if (interestModify) {
      setIsLoading(true);
      addInterestCategories(
        currentUser.access_token,
        currentUser.current_user.uid,
        { categories_id: selectedInterest[0].options }
      ).then(() => {
        setIsLoading(false);
        dispatch(updateInterestedCategories(interestedCategory));
      });
    }
    setInterest(false);
    setInterestNext(false);
    setNext(0);
    setBackStatus(false)
    setRangeNext(selected[0].options.length === 0 ?? false);
    setInterestModify(false);
    getQuestionAns();
  };

  const handlerBack = (i) => {
    setInterest(next === 0 ?? false);
    setNext((count) => count - 1);
    if (location.state !== undefined) {
      if (next - 1 === 0 && back) {
        setBackStatus(true);
      }
    }

    if (i !== 0 && selected[i - 1].options.length === 0) {
      setRangeNext(true);
      return;
    }
    setRangeNext(false);
  };

  const handleSelect = (quesId, ansId) => {
    const arrData = selected;
    setRangeNext(false);
    for (let i = 0; i < arrData.length; i++) {
      if (parseInt(arrData[i].type) === 1) {
        if (arrData[i].id === quesId && !arrData[i].options.includes(ansId)) {
          arrData[i].options = [ansId];

          const answer = {
            qid: quesId,
            aid: [ansId],
            uid: parseInt(currentUser.current_user.uid)
          }
          setAnswer(answer);
          setSelected([...arrData]);
          return;
        }
      } else {
        if (arrData[i].id === quesId) {
          if (!arrData[i].options.includes(ansId)) {
            arrData[i].options.push(ansId);
          } else {
            var index = arrData[i].options.indexOf(ansId);
            if (index >= 0) {
              arrData[i].options.splice(index, 1);
            }

            if (arrData[i].options.length === 0) {
              setRangeNext(true);
            }
          }
          const answer = {
            qid: quesId,
            aid: arrData[i].options,
            uid: parseInt(currentUser.current_user.uid)
          };
          setAnswer(answer);
          setSelected([...arrData]);
          return;
        }
      }
    }
  };

  const handleSelectInterest = (quesId, ansId, name) => {
    const newObj = [
      {
        id: ansId,
        category_name: name,
      },
    ];
    const arrData = selectedInterest;

    for (let i = 0; i < arrData.length; i++) {
      if (!arrData[i].options.includes(ansId)) {
        arrData[i].options.push(ansId);
      } else if (arrData[i].options.includes(ansId)) {
        var index = arrData[i].options.indexOf(ansId);
        if (index >= 0) {
          arrData[i].options.splice(index, 1);
        }
      }
    }
    setInterestNext(selectedInterest[0].options.length > 0 ? false : true)
    setSelectedInterest([...arrData]);
    setInterestModify(true);

    const ind = interestedCategory.findIndex((a) => a.id === ansId);

    if (ind === -1) {
      setInterestedCategory((oldArray) => [...oldArray, ...newObj]);
      return;
    }
    setInterestedCategory(
      interestedCategory.filter(function (val) {
        return val.id !== ansId;
      })
    );
  };

  const skipAll = () => {
    history.replace("/");
  };

  const getCategories = useCallback(() => {
    setIsLoading(true);
    getCategory(currentUser.access_token).then((data) => {
      let resultData = [
        {
          question: "Interested Topics",
          options: data,
        },
      ];
      setInterestList(resultData);
      setIsLoading(false);
      return;
    });
  }, [currentUser.access_token]);

  const checkExists = (arr, id) => {
    const found = arr.some(el => el.id === id);
    return found;
  }

  const getQuestionAns = () => {
    setIsLoading(true);
    getQuestionnaire(currentUser.access_token, currentUser.current_user.uid).then((data) => {
      if (location.state !== undefined) {
        //const obj = [];
        getQuestionList(currentUser.access_token).then((res) => {
          const result = res.filter(x => !data.some(y => x.id === y.id));
          if (result.length === 0) {
            updateUserPersona();
            history.replace('/')
          }
          const array = [];
          result.map((details) => {
            const data = {
              id: details.id,
              options: [],
              type: details.type
            };
            array.push(data);
          });
          setIsLoading(false);
          setQuestions(result);
          setSelected(array);
          setRangeNext(true);
          setBackStatus(back)
        });
        setRangeNext(false);
        return;
      }
      const arrData = selected;
      for (let i = 0; i < arrData.length; i++) {
        if (checkExists(data, arrData[i].id)) {
          for (let k = 0; k < data.length; k++) {
            if (arrData[i].id === data[k].id) {
              arrData[i].options = data[k].options
            }
          }
        }
      }
      setSelected([...arrData]);
      setIsLoading(false);
      setRangeNext(arrData[0].options.length === 0 ?? false);
    });
  };

  const getQuestion = () => {
    setIsLoading(true);
    getQuestionList(currentUser.access_token).then((res) => {
      setQuestions(res);
      const array = [];
      res.map((details) => {
        const data = {
          id: details.id,
          options: [],
          type: details.type
        };
        array.push(data);
      });
      setIsLoading(false);
      setSelected(array);
    });
  };

  const updateUserPersona = () => {
    const updateData = {
      field_user_persona_status: { value: 0 },
    };
    updateUserPersonaStatus(currentUser.access_token, currentUser.current_user.uid, updateData)
      .then(() => {
        console.log('updating status');
      })
      .catch((error) => {
        console.log(error);
      })
  };

  useEffect(() => {
    setInterestedCategory(interestedCategories);
    setSelectedInterest(currentList());

    if (location.state !== undefined &&
      interestedCategories.length === 0 ||
      location.state === undefined
    ) {
      setInterestNext(interestedCategories.length === 0 ?? false);
      getCategories();
      getQuestion();
      setBack(false);
      return;
    }
    getQuestionAns();
    setBackStatus(true)
    setInterest(false);
    setNext(0);
    setInterestNext(false);

  }, []);

  useEffect(() => {
    if (questions.length !== 0) {
      if (questions.length <= next) {
        history.replace('/');
      }
    }
  }, [questions.length, next, history]);
  return (
    <div className="flex justify-center specializationSection items-center flex-col grow">
      <div className="specialization  px-5 sm:px-5 md:px-5 lg:px-14 basis-[85vh] grow">
        {isLoading && (
          <div className="parentDisable">
            <LoadingSpinner />
          </div>
        )}
        <div className="flex justify-between items-center">
          <div className="closeIconHolder cursor-pointer hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect width="24" height="24" fill="white" />
              <path
                d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z"
                fill="#717375"
                stroke="#717375"
              />
              <path
                d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z"
                fill="#717375"
                stroke="#717375"
              />
            </svg>
          </div>
        </div>
        {interest &&
          interestList.map((details, i) => (
            <div
              key={"interested-categories"}
              className="max-w-full w-full sm:w-[600px] md:w-[720px] sm:max-w-[720px] justify-center mx-auto flex flex-col p-6 sm:p-10 h-full gap-10"
            >
              <div className="basis-[70vh] grow">
                <p className="Title barlowBold text-xl leading-7 sm:text-[32px] sm:leading-10 text-[#333] text-center mb-6">To help us provide the most relevant videos to you, please answer the following question</p>
                <h1 className="sectionTitle barlowBold text-[18px] leading-7 sm:text-[28px] sm:leading-10 text-[#333] text-center">
                  {details.question}
                </h1>
                <div className="specAreas mt-6 *:text-[#333] text-base leading-5 sm:*:text-xl sm:*:leading-9 *:font-medium *:tracking-[-0.25px] *:rounded-lg *:border *:border-[#7DA1C4] *:px-5 *:py-[5px] gap-5 flex flex-wrap  justify-center *:cursor-pointer">
                  <Options
                    answer={details.options}
                    questionId={details.id}
                    handleSelect={handleSelectInterest}
                    selected={selectedInterest}
                    interest={interest}
                    i={i}
                  />
                </div>
              </div>
              <div className="actions flex gap-6 items-center max-[480px]:flex-col-reverse max-[480px]:justify-center justify-between flex-wrap">
                <button
                  className="text-primaryText w-fit flex flex-nowrap font-bold disabled"
                >
                  Skip All
                </button>
                <div className="flex gap-3 sm:gap-6 *:w-[85px] sm:*:min-w-[120px]">
                  <button
                    className="skipBtn h-10 sm:h-[46px] rounded text-[#005BC3] leading-5 text-sm sm:text-base font-bold w-full border border-primaryBlue whitespace-wrap bg-white  flex items-center justify-center hover:bg-primaryBlue hover:text-white transition-all ease-out duration-300 disabled"
                  >
                    Previous
                  </button>
                  <button
                    className="skipBtn h-10 sm:h-[46px] rounded text-[#005BC3] leading-5 text-sm sm:text-base font-bold w-full border border-primaryBlue whitespace-wrap bg-white  flex items-center justify-center hover:bg-primaryBlue hover:text-white transition-all ease-out duration-300 disabled"
                  >
                    Skip
                  </button>
                  <button
                    className={`${interestNext && "disabled"
                      } btn nextBtn w-full bg-primaryBlue h-10 sm:h-[46px] font-bold leading-5 text-sm sm:text-base  justify-center items-center rounded text-white text-center flex`}
                    onClick={handlerNextInterest}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ))}
        {!interest &&
          questions.map(
            (details, i) =>
              i === next && (
                <div
                  key={details.id}
                  className="max-w-full w-full sm:w-[600px] md:w-[720px] sm:max-w-[720px] justify-center mx-auto flex flex-col p-6 sm:p-10 h-full gap-10"
                >
                  <div className="basis-[70vh] grow">
                    <h1 className="sectionTitle barlowBold text-[18px] leading-7 sm:text-[28px] sm:leading-10 text-[#333] text-center">
                      {details.question}
                    </h1>
                    <div className="specAreas mt-10 *:text-[#333] text-base leading-5 sm:*:text-xl sm:*:leading-9 *:font-medium *:tracking-[-0.25px] *:rounded-lg *:border *:border-[#7DA1C4] *:px-5 *:py-[5px] gap-4 sm:gap-5 flex flex-wrap  justify-center *:cursor-pointer *:text-center">
                      <Options
                        answer={details.options}
                        questionId={details.id}
                        handleSelect={handleSelect}
                        interest={interest}
                        selected={selected}
                        i={i}
                      />
                    </div>
                  </div>
                  <div className="actions flex gap-6 items-center max-[480px]:flex-col-reverse max-[480px]:justify-center justify-between flex-wrap">
                    <button
                      className="text-primaryText w-fit flex flex-nowrap font-bold"
                      onClick={skipAll}
                    >
                      Skip All
                    </button>
                    <div className="flex gap-3 sm:gap-6 *:w-[85px] sm:*:min-w-[120px]">
                      <button
                        className={`${backStatus && "disabled"
                          } skipBtn h-10 sm:h-[46px] rounded text-[#005BC3] leading-5 text-sm sm:text-base font-bold w-full border border-primaryBlue whitespace-wrap bg-white  flex items-center justify-center hover:bg-primaryBlue hover:text-white transition-all ease-out duration-300`}
                        onClick={() => handlerBack(i)}
                      >
                        Previous
                      </button>
                      <button
                        onClick={() => handlerSkip(i)}
                        className="skipBtn h-10 sm:h-[46px] rounded text-[#005BC3] leading-5 text-sm sm:text-base font-bold w-full border border-primaryBlue whitespace-wrap bg-white  flex items-center justify-center hover:bg-primaryBlue hover:text-white transition-all ease-out duration-300"
                      >
                        Skip
                      </button>

                      <button
                        className={`${rangeNext && "disabled"
                          } btn nextBtn w-full bg-primaryBlue h-10 sm:h-[46px] font-bold leading-5 text-sm sm:text-base  justify-center items-center rounded text-white text-center flex`}
                        onClick={() => handlerNext(i)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )
          )}
      </div>
    </div>
  );
};

export default Specialization;
