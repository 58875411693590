import React, { useRef, useEffect } from 'react'
import "../../assets/videojs/skins/treso/videojs.css";
import videojs from "video.js";
import "../../assets/videojs/components/nuevo.js";
import "../../assets/videojs/components/thumbnails.js";
import { useModal } from '../../store/ModalContext.js';

const BannerVideoplayer = (props) => {
    const { isBannerVideo } = useModal();
    const videoNode = useRef(props.useRef);
    let player;
    
    if(props.playvideo_option  && typeof videoNode.current  === 'object') {      
      if (props.playvideo_option === props.useRef) {
        videoNode.current.play();
      } else {
        videoNode.current.muted = 'true';
        videoNode.current.pause();
      }
    }

    if(props.pausevideo_option && typeof videoNode.current  === 'object') {
      videoNode.current.muted = 'true';
      videoNode.current.pause();
    }

    if(isBannerVideo === false && typeof videoNode.current  === 'object') {
      videoNode.current.play();
    }    

    useEffect(() => {
        // video properties
        const videoJsOptions = {
          controls: true,
          preload: "auto",
          playsinline: true,
          poster: "",
          sources: [
            {
              src: props.src,
              type: props.type,
            },
          ]  
        };
    
        // initialize Video.js
        player = videojs(
          videoNode.current,
          videoJsOptions,
          function onPlayerReady() {
            console.log("Player ready.");
          }
        );
    
        // Nuevo plugin options
        const nuevoOptions = {
          logo: "",
          logourl: "",
          zoomMenu: true,
          zoomInfo: true,
          settingsButton: false,
          buttonRewind: false,
          buttonForward: false,
          shareMenu: true,
          rateMenu: true,
          qualityMenu: true,
          pipButton: false,  
          controlbar: true,
        };
    
        // Initialize Nuevo plugin
        player.nuevo(nuevoOptions);

         // Hide duration display
        const durationDisplay = player.controlBar.getChild('durationDisplay');
        durationDisplay.el().style.display = 'none';

        player.on('ended', function() {
          //console.log('Awww...over so soon?!');
          videoNode.current.play();
        });
        
        player.on('pause', function() {
          //console.log('Awww...paused?!');
        });        
    
        // destroy player on unmount
        return () => {
          if (player) {
            player.dispose();
          }
        };
      }, []);

    return (
      <video ref={videoNode} className="video-js vjs-fluid banner-video" data-vjs-player muted autoPlay={props.autoplayvideo_option} />
    )
}

export default BannerVideoplayer