import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    highlightList: null,
};

export const highlightSlice = createSlice({
    name: "highlight",
    initialState,
    reducers: {
        updateHighlight: (state, action) => {
            state.highlightList = action.payload;
        },
        clearHighlight: (state) => {
            state.highlightList = null;
        },        
    },
});

export const { updateHighlight, clearHighlight } = highlightSlice.actions;

export default highlightSlice.reducer;