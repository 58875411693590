import { Fragment, useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'

import classes from './Modal.module.css'

const Backdrop = (props) => {
    return (
        <div
            className={classes.backdrop}
            onClick={props.onClose}
        />
    )
}

const ModalOverlay = (props) => {
    const { orientation } = useSelector((state) => (state.orientation))
    const { mobile, tab } = useSelector((state) => (state.deviceType))

    const handleModalClassName = useCallback(() => {
        if (mobile && orientation === 'landscape') {
            return "flex items-center justify-center h-auto landscape:m-5 md:landscape:m-5 lg:landscape:m-0"
        } else if (!tab && !mobile && orientation === 'landscape') {
            return "flex items-center justify-center h-full landscape:m-5 md:h-full md:landscape:m-5 lg:landscape:m-0"
        } else if ((tab || mobile) && orientation === 'portrait') {
            return "flex items-center justify-center h-full landscape:m-5 md:h-full md:landscape:m-5 lg:landscape:m-0"
        } else if (!tab && !mobile && orientation === null) {
            return "flex items-center justify-center h-full landscape:m-5 md:h-full md:landscape:m-5 lg:landscape:m-0"
        } else if (!tab && !mobile && orientation === 'portrait') {
            return "flex items-center justify-center h-full landscape:m-5 md:h-full md:landscape:m-5 lg:landscape:m-0"
        } else if (!tab && mobile && orientation === null) {
            return "flex items-center justify-center h-full landscape:m-5 md:h-full md:landscape:m-5 lg:landscape:m-0"
        }
    }, [orientation, mobile, tab])

    useEffect(() => {
        handleModalClassName()
    }, [handleModalClassName])

    document.body.classList.add('h-screen', 'overflow-hidden');

    useEffect(()=>{
        return () => {
            document.body.classList.remove('h-screen', 'overflow-hidden');
        }
    },[])

    return (
        <div className={classes.modal}>
            <div className="flex items-center justify-center h-full landscape:m-5 md:h-full md:landscape:m-5 lg:landscape:m-0">{props.children}</div>
        </div>
    )
}

const portalElement = document.getElementById('overlays')

const Modal = (props) => {
    return (
        <Fragment>
            {ReactDOM.createPortal(
                <Backdrop onClose={props.onClose} />,
                portalElement,
            )}
            {ReactDOM.createPortal(
                <ModalOverlay>{props.children}</ModalOverlay>,
                portalElement,
            )}
        </Fragment>
    )
}

export default Modal