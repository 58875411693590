import { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useModal } from '../../store/ModalContext';
import NoResults from '../../pages/NoResults';
import LoadingSpinner from '../UI/LoadingSpinner';
import { getCategoryVideos, getsubCategory } from '../../lib/api';
import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import defaultThumbnail from '../../assets/images/thumbnails/thumbnailsPlaceholder.png';

const SearchResult = () => {
    const history = useHistory();
    const location = useLocation();
    const cardRef = useRef();
    const { currentUser } = useSelector((state) => state.user);
    const { EncryptionData, DecryptionData, secondsToMinutes } = useModal();
    const [leftScroll, setLeftScroll] = useState('disabled');
    const [rightScroll, setRightScroll] = useState('disabled');
    const [hideScroll, setHideScroll] = useState('hidden');
    const [isLoading, setIsLoading] = useState(true);
    const [subCategoryvalue, setSubCategoryvalue] = useState([]);
    const [categoryVideos, setCategoryVideos] = useState([]);
    const [categoryName, setCategoryName] = useState();
    const [areNoResults, setAreNoResults] = useState(false)
    const [activeSubCategory, setActiveSubCategory] = useState('');
    const [categoryId, setCategoryId] = useState('');


    const handleNav = (speed, distance, step) => {
        let scrollAmount = 0;
        const slideTimer = setInterval(() => {
            cardRef.current.scrollLeft += step;
            scrollAmount += Math.abs(step);
            if (scrollAmount >= distance) {
                if (cardRef.current.scrollLeft === cardRef.current.scrollLeftMax) {
                    setRightScroll('disabled');
                    setLeftScroll('');
                } else {
                    setRightScroll('');
                    setLeftScroll('');
                }
                if (cardRef.current.scrollLeft > 10) {
                    setLeftScroll('');
                } else {
                    setLeftScroll('disabled');
                }
                clearInterval(slideTimer);
            }
        }, speed);
    };
    const subcategorysendRequest = useCallback(() => {
        setIsLoading(true);
        window.scrollTo(0, 0);
        let path = location.pathname.split("/");
        const categoryData = DecryptionData(path[2]).split("##");
        if (categoryData) {
            getsubCategory(categoryData[0])
                .then((response) => {
                    setCategoryId(categoryData[0]);
                    response.sort(function(a,b) {
                        return a.name === 'Others' ? 1 : 0
                    })                    
                    setSubCategoryvalue(response);
                })
                .catch((error) => {
                    console.log(error);
                });

            getCategoryVideos(categoryData[1], currentUser)
                .then((response) => {
                    response.length > 0 ? setCategoryVideos(response) : setAreNoResults(true);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
            if(categoryData[1] === 'Presbyopia' || categoryData[1] === 'Astigmatism') {
                setCategoryName(categoryData[1] + ' Management');
            } else {
                setCategoryName(categoryData[1]);
            }
            
        }
    }, [location.pathname, currentUser, DecryptionData]);

    useEffect(() => {
        subcategorysendRequest();
    }, [subcategorysendRequest]);

    const handleViewVideo = (title) => {
        history.push('/video-details'+title);
    }

    useEffect(() => {
        if (cardRef.current.scrollHeight !== 0 && cardRef.current.clientHeight !== 0) {
            if (cardRef.current.scrollHeight > cardRef.current.clientHeight) {
                setHideScroll('');
            }
        }
    }, [subCategoryvalue]);

    const handleSubCategorySearch = (event) => {
        setActiveSubCategory(event.target.id);
        if (categoryId !== '') {
            var data = categoryId + '##' + event.target.textContent;
        }
        history.replace('/search-result/' + EncryptionData(data));
    }

    const onImageError = (e) => {
        e.target.src = defaultThumbnail;
    }
    
    return (
        <div className="searchResults flex flex-col grow bg-[#F4F8FD] px-5 sm:px-5 md:px-5 lg:px-14 pt-4 pb-10 lg:pt-8  lg:pb-20">
        <Helmet>
            (<title>{`MVL - ${categoryName}`}</title>)
            <meta name="description" content={categoryName} />
            <meta name="keywords" content={categoryName} />              
            <meta property="og:title" content={categoryName} />
            <meta property="og:description" content={categoryName} />
            <meta property="og:image" content="../../assets/images/eyeLearnNewLogo.png"></meta>                 
        </Helmet>            
            {isLoading && (
                <div className="parentDisable">
                    <LoadingSpinner />
                </div>
            )}
            <div className="searchResultsHeader">
                <div className="flex justify-between items-center gap-3 sm:gap-4 flex-wrap">
                    <h2 className="title text-lg leading-5 sm:text-[28px] sm:leading-9 barlowBold">Search Results</h2>
                    <p className="text-sm sm:text-lg leading-5 text-[#333] font-normal tracking-[0.2px]">Your <span className="font-bold">{categoryName}</span> search returned {categoryVideos.length} results</p>
                </div>
            </div>
            <div className="subCatagoryAndfilters flex-nowrap gap-4  items-center mt-3 lg:mt-8 relative flex">
                <div onClick={() => handleNav(5, 400, -10)} className={`leftScrollIcon  items-center h-full  rotate-90 size-7 cursor-pointer opacity-50 ${leftScroll} ${hideScroll}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
                        <path d="M7.4 7.9999L6 9.3999L12 15.3999L18 9.3999L16.6 7.9999L12 12.5999L7.4 7.9999Z" fill="#666"></path>
                    </svg>
                </div>
                <div className="subCatagoryHolder  text-center whitespace-nowrap *:rounded-2xl *:text-sm *:font-normal *:text-[#333] *:bg-[#fff] *:px-4 *:py-1 *:whitespace-nowrap *:cursor-pointer *:border-[0.5px] *:border-[#D9D9D9] flex flex-nowrap gap-x-3 gap-y-2 overflow-x-auto grow" ref={cardRef}>
                    {subCategoryvalue.map((details) =>
                        <span className={`subCatagory` + (activeSubCategory === details.tid ? ' active' : '')} key={details.tid} id={details.tid} onClick={handleSubCategorySearch}>{details.name}</span>
                    )}
                </div>
                <div onClick={() => handleNav(5, 400, +10)} className={`rightScrollIcon   items-center h-full  -rotate-90 size-7 cursor-pointer opacity-50 ${rightScroll} ${hideScroll}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
                        <path d="M7.4 7.9999L6 9.3999L12 15.3999L18 9.3999L16.6 7.9999L12 12.5999L7.4 7.9999Z" fill="#666"></path>
                    </svg>
                </div>
            </div>
            {categoryVideos.length > 0 &&
                <div className="searchResutsVideos">
                    <div className="cards flex gap-4 sm:gap-6  mt-3 sm:mt-6 *:rounded-lg *:shadow-cardShadow *:cursor-pointer *:flex-shrink-0 pb-[6px]">
                        {categoryVideos.map((details) =>
                            <div className="card flex flex-col" onClick={() => handleViewVideo(details.slug_url)} key={details.mid}>
                                <div className="thumbnail h-[190px] sm:h-[206px] md:h-[226px] lg:h-[256px] relative group">
                                    <img src={details.thumbnail_url || defaultThumbnail} className="w-full h-full rounded-tl-lg rounded-tr-lg object-cover" alt="thumbnail" onError={onImageError} />
                                    {details.duration && details.duration > 0 ?
                                    <p className="duration  bg-videoDurationBg text-white text-xs font-normal leading-4 rounded px-2 py-1 absolute right-4 bottom-4">{secondsToMinutes(details.duration)}</p>
                                    : <></>}
                                    <div className="actionsHolder absolute right-4 top-4 flex-col gap-4 *:p-[6px] *:rounded !hidden group-hover:flex z-50">
                                        <div className="heart bg-videoDurationBg hover:bg-[#006FBA]">
                                            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M6.56 1.75C3.90771 1.75 1.75 3.9107 1.75 6.59C1.75 9.84052 3.25068 12.4449 5.13923 14.3799C7.03683 16.3242 9.27995 17.542 10.6223 18.0002L10.6296 18.0027L10.6296 18.0028C10.6944 18.0256 10.8286 18.05 11 18.05C11.1714 18.05 11.3056 18.0256 11.3704 18.0028L11.3777 18.0002L11.3777 18.0002C12.7201 17.542 14.9632 16.3242 16.8608 14.3799C18.7493 12.4449 20.25 9.84052 20.25 6.59C20.25 3.9107 18.0923 1.75 15.44 1.75C13.8778 1.75 12.477 2.50911 11.6021 3.68716C11.4606 3.87768 11.2373 3.99 11 3.99C10.7627 3.99 10.5394 3.87768 10.3979 3.68716C9.52255 2.5085 8.13147 1.75 6.56 1.75ZM0.25 6.59C0.25 3.0893 3.07229 0.25 6.56 0.25C8.29674 0.25 9.86456 0.955987 11.0003 2.09472C12.1385 0.955639 13.7122 0.25 15.44 0.25C18.9277 0.25 21.75 3.0893 21.75 6.59C21.75 10.3395 20.0107 13.3001 17.9342 15.4276C15.8683 17.5443 13.4235 18.8861 11.8657 19.4186C11.5914 19.5147 11.2773 19.55 11 19.55C10.7227 19.55 10.4086 19.5147 10.1343 19.4186C8.57655 18.8861 6.13169 17.5443 4.06577 15.4276C1.98932 13.3001 0.25 10.3395 0.25 6.59Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div className="clock bg-videoDurationBg hover:bg-[#006FBA]">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M11 1.75003C5.89137 1.75003 1.75 5.8914 1.75 11C1.75 16.1087 5.89137 20.25 11 20.25C16.1086 20.25 20.25 16.1087 20.25 11C20.25 5.8914 16.1086 1.75003 11 1.75003ZM0.25 11C0.25 5.06297 5.06294 0.250031 11 0.250031C16.9371 0.250031 21.75 5.06297 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C5.06294 21.75 0.25 16.9371 0.25 11ZM11 5.25003C11.4142 5.25003 11.75 5.58582 11.75 6.00003V10.4595L14.2372 11.2885C14.6301 11.4195 14.8425 11.8442 14.7115 12.2372C14.5805 12.6302 14.1558 12.8425 13.7628 12.7115L10.7628 11.7115C10.4566 11.6095 10.25 11.3229 10.25 11V6.00003C10.25 5.58582 10.5858 5.25003 11 5.25003Z" fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="playBtn absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 cursor-pointer hidden group-hover:block">
                                        <svg width="38" height="44" viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M36.9612 23.5936L2.99228 43.0044C1.65896 43.7663 0 42.8036 0 41.2679V21.8571V2.44636C0 0.910713 1.65896 -0.052021 2.99227 0.709873L36.9611 20.1207C38.3048 20.8884 38.3048 22.8258 36.9612 23.5936Z" fill="white" fillOpacity="0.8" />
                                        </svg>
                                    </div>
                                    <div className="overlay"></div>
                                </div>
                                <div className="description bg-white p-4 flex flex-col gap-1">
                                    <p className="videoCatagory text-videoCatagory text-xs sm:text-sm leading-5 font-medium tracking-[0.035px] !hidden">{details.educational_content}</p>
                                    <p className="videoCaption text-videoCaption  leading-5 sm:leading-6 font-medium  text-sm sm:text-base">{details.title}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            }
            {areNoResults && <NoResults />}
        </div>
    )
}

export default SearchResult