import { useRef, useState } from "react";
import { useSelector } from 'react-redux';
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import contactus_video from "../../assets/videos/Email.gif";
import useInput from "../../hooks/use-input";
import { contactUs } from "../../lib/api";
import Input from "../UI/Input";
import { isEmail, isFirstName, isSubject, isCountry } from "../UI/Validations";

const SendQuery = (props) => {
  const { currentUser } = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [messageIsValid, setMessageIsValid] = useState(false);
  const [enteredMessage, setEnteredMessage] = useState('');
  const [querySubmitted, setQuerySubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const firstNameInputRef = useRef();
  const emailInputRef = useRef();
  const subjectInputRef = useRef();
  const countryInputRef = useRef();

  const {
    value: firstNameValue,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
  } = useInput(isFirstName);

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(isEmail);

  const {
    value: subjectValue,
    isValid: subjectIsValid,
    hasError: subjectHasError,
    valueChangeHandler: subjectChangeHandler,
    inputBlurHandler: subjectBlurHandler,
  } = useInput(isSubject);

  const {
    value: countryValue,
    isValid: countryIsValid,
    hasError: countryHasError,
    valueChangeHandler: countryChangeHandler,
    inputBlurHandler: countryBlurHandler,
  } = useInput(isCountry);

  const handleChange = (e) => {
    e.preventDefault();
    let { id, value } = e.target;
    if (id === "message") {
      if (value[0] === ' ') { setErrorMessage("Message cannot start with space"); setMessageIsValid(false) }
      else if (value.trim().length === 0) { setErrorMessage("Please enter message"); setMessageIsValid(false); }
      else { setMessageIsValid(true); setEnteredMessage(value)};
    }
  }

  let formIsValid = false;

  if (
    !firstNameIsValid &&
    !emailIsValid &&
    !countryIsValid
  ) {
    formIsValid = true;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    if (!currentUser && !formIsValid) {
      return;
    }

    if (!messageIsValid || subjectIsValid) {
      return;
    }

    setIsLoading(true);

    const enteredFirstname = currentUser ? currentUser?.user_extra_details?.first_name : firstNameInputRef.current.value;
    const enteredEmail = currentUser ? currentUser?.current_user?.name : emailInputRef.current.value;
    const enteredSubject = subjectInputRef.current.value;
    const enteredCountry = currentUser ? "" : countryInputRef.current.value;

    const formData = {
      contact_form: [
        {
          target_id: "send_query"
        }
      ],
      name: {
        value: enteredFirstname,
      },
      subject: {
        value: enteredSubject
      },
      mail: {
        value: enteredEmail,
      },
      field_country: [{
        value: enteredCountry
      }],
      message: {
        value: enteredMessage
      },
      field_video_id: {
        value: props.videoId
      }
    };

    contactUs(formData)
      .then(() => {
        setIsLoading(false);
        setQuerySubmitted(true);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      })
  }

  const firstNameClasses = (firstNameHasError)
    ? "flex flex-col gap-[6px] invalid"
    : "flex flex-col gap-[6px]";

  const emailClasses = emailHasError
    ? "emailSection gap-1 flex flex-col invalid"
    : "emailSection gap-1 flex flex-col";

  const countryClasses = countryHasError
    ? "emailSection gap-1 flex flex-col invalid"
    : "emailSection gap-1 flex flex-col";

  const subjectClasses = (subjectHasError)
    ? "emailSection gap-1 flex flex-col invalid"
    : "emailSection gap-1 flex flex-col";

  return (
    <Modal>
      {!querySubmitted ?
        <div className="appWrapper h-full w-full mx-0 md:mx-4">
          <div className="supportFormHolder h-full flex">
            <div className="formHolder contactUsFormBgHolder max-w-[480px] w-full max-h-full sm:max-h-full h-full sm:h-auto md:max-h-[620px]  bg-[#F4F8FD] sm:bg-[#F4F8FD] md:bg-white flex justify-center m-auto overflow-auto rounded-none landscape:rounded-lg md:rounded-lg relative">
              <div className="px-10 sm:px-10 md:px-10 lg:px-14 w-full h-full py-10 flex">
                <div className="flex items-stretch flex-col sm:flex-row w-full">
                  <div className="videoIconHolder basis-4/12 aspect-square self-center hidden">
                    <img src={contactus_video} className="w-full h-full rounded-tl-lg rounded-tr-lg object-cover" />
                  </div>
                  <div className="bar w-[1px] bg-[#7DA1C4] opacity-60 ml-[55px] mr-[46px]  hidden"></div>
                  <div className="contactUsFormHolder h-full w-full">
                    <div className="flex flex-col h-full">

                      <form className="flex flex-col justify-center h-full gap-3 static z-50" onSubmit={submitHandler}>
                        <div className="flex flex-col gap-6 sm:gap-8 sm:overflow-visible overflow-auto formWrapper">
                          <div className="formHeader">
                            <div className="flex justify-between items-center">
                              <h2 className="modalTitle text-[#354665] barlowBold text-xl">Send a Feedback</h2>
                              <div className="closeIconHolder absolute sm:static left-10 sm:right-5 sm:left-auto top-5 cursor-pointer sm:landscape: right-10" onClick={props.onClose}>
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z"
                                    fill="#717375"
                                    stroke="#717375"
                                  />
                                  <path
                                    d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z"
                                    fill="#717375"
                                    stroke="#717375"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="contactUsForm h-full">
                            <div className="space-y-4 sm:space-y-6">
                              {!currentUser && <>
                                <div className={firstNameClasses}>
                                  <Input
                                    ref={firstNameInputRef}
                                    label="First Name"
                                    labelClass="firstNameLabel text-[#333] text-sm font-normal leading-5"
                                    isManditory={true}
                                    input={{
                                      id: "firstName",
                                      name: "firstName",
                                      type: "text",
                                      value: firstNameValue,
                                      placeholder: "Enter First Name",
                                      onChange: (event) => {
                                        firstNameChangeHandler(event);
                                      },
                                      onBlur: firstNameBlurHandler,
                                      className:
                                        "p-[10px] rounded border border-loginFieldBorder h-10 !text-sm placeholder:text-[#666] bg-transparent  !text-[#333]",
                                    }}
                                  />
                                  {firstNameHasError && (
                                    <p className="error-text">
                                      {firstNameIsValid}
                                    </p>
                                  )}
                                </div>
                                <div className={emailClasses}>
                                  <Input
                                    ref={emailInputRef}
                                    label="Email"
                                    labelClass="emailLabel text-[#333] text-sm font-normal leading-5"
                                    isManditory={true}
                                    input={{
                                      id: "email",
                                      name: "email",
                                      type: "email",
                                      value: emailValue,
                                      placeholder: "Enter Your Email",
                                      onChange: emailChangeHandler,
                                      onBlur: emailBlurHandler,
                                      className:
                                        "!text-sm p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 bg-white loginField placeholder:text-[#666]",
                                    }}
                                  />
                                  {emailHasError && (
                                    <p className="error-text">
                                      {emailIsValid}
                                    </p>
                                  )}
                                </div>
                                <div className={countryClasses}>
                                  <Input
                                    ref={countryInputRef}
                                    label="Country"
                                    labelClass="emailLabel text-[#333] text-sm font-normal leading-5"
                                    isManditory={true}
                                    input={{
                                      id: "country",
                                      name: "country",
                                      type: "text",
                                      value: countryValue,
                                      placeholder: "Enter Your Country",
                                      onChange: countryChangeHandler,
                                      onBlur: countryBlurHandler,
                                      className:
                                        "!text-sm p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 bg-white loginField placeholder:text-[#666]",
                                    }}
                                  />
                                  {countryHasError && (
                                    <p className="error-text">
                                      {countryIsValid}
                                    </p>
                                  )}
                                </div>
                              </>}
                              <div className={subjectClasses}>
                                <Input
                                  ref={subjectInputRef}
                                  label="Subject"
                                  labelClass="emailLabel text-[#333] text-sm font-normal leading-5"
                                  isManditory={true}
                                  input={{
                                    id: "subject",
                                    name: "subject",
                                    type: "text",
                                    value: subjectValue,
                                    placeholder: "Enter Subject",
                                    onChange: subjectChangeHandler,
                                    onBlur: subjectBlurHandler,
                                    className:
                                      "!text-sm p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 bg-white loginField placeholder:text-[#666]",
                                  }}
                                />
                                {subjectHasError && (
                                  <p className="error-text">
                                    {subjectIsValid}
                                  </p>
                                )}
                              </div>
                              <div className="commentsSection gap-[6px] flex flex-col">
                                <label className="titleLabel text-[#333] text-sm font-normal leading-5">
                                  Comments *
                                </label>
                                <textarea
                                  className="p-[10px] rounded border border-loginFieldBorder text-[#666]  placeholder:text-[#666] text-sm "
                                  placeholder="Enter Your Comments Here...."
                                  rows="4"
                                  cols="30"
                                  id='message'
                                  onChange={(e) => handleChange(e)}
                                ></textarea>
                                {(!messageIsValid && errorMessage) && (
                                  <p className="error-text">
                                    {errorMessage}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="buttonHolder basis-auto flex items-end">
                          <Button
                            disabled={!currentUser ? (!formIsValid || !messageIsValid || subjectIsValid) : (!messageIsValid || subjectIsValid)}
                            type="submit"
                            className={(!currentUser ? (!formIsValid || !messageIsValid || subjectIsValid) : (!messageIsValid || subjectIsValid)) ? "btn mt-8 submitBtn w-full bg-primaryBlue h-[46px] px-20 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center disabled" : "btn mt-8 continueBtn w-full bg-primaryBlue h-[46px] px-20 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center cursor-pointer"}
                          >
                            Submit
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> :
        <div className="appWrapper flex flex-col h-screen md:h-auto relative justify-center w-full">
          <div className="thanksMsgHolder max-w-full w-full sm:w-[480px] sm:max-w-[480px]  min-h-full sm:min-h-[220px] bg-[#F4F8FD] sm:bg-[#F4F8FD] flex items-center justify-start rounded-none sm:rounded-lg  mx-auto">
            <div className="px-10 sm:px-10 md:px-10 lg:px-14 py-10 w-full">
              <div className="flex items-stretch flex-col sm:flex-row">
                <div className="bar w-[1px] bg-[#7DA1C4] opacity-60 ml-[55px] mr-[46px] hidden"></div>
                <div className="message w-full">
                  <p className="text-base sm:text-lg leading-6  text-[#333] font-normal tracking-[0.2px]">Thanks for contacting us our team will get back to you shortly.</p>
                  <Button className="btn mt-10 continueBtn  bg-primaryBlue h-[46px] px-20 py-[10px] font-bold leading-5  text-base justify-center  rounded text-white inline-flex items-center cursor-pointer w-full" onClick={props.onClose}>Continue</Button>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </Modal>
  );
}

export default SendQuery;