import { useEffect } from 'react';
import classes from './LoadingSpinner.module.css';

const LoadingSpinner = () => {

  const bodyBodyOverflowAdd = () => {
    document.body.classList.add('h-screen');
    document.body.classList.add('overflow-hidden');
  }

  const bodyBodyOverflowRemove = () => {
    document.body.classList.remove('h-screen');
    document.body.classList.remove('overflow-hidden');
  }

  useEffect(()=>{
    
    bodyBodyOverflowAdd()

    return () => {
      bodyBodyOverflowRemove()
    }
  },[])

  return <div className={classes.loader}></div>;
}

export default LoadingSpinner;
