import { useSelector } from "react-redux";
import { useModal } from "../../store/ModalContext";
import Button from "../UI/Button";
import { useHistory } from 'react-router-dom';

const TalkToUs = () => {
    const history = useHistory();
    const { openContactUsModal, openVideoSubmitModal } = useModal();
    const { currentUser } = useSelector((state) => state.user);    
    const contactusHandle = () => {
        if (currentUser === null) {
            openContactUsModal();
        } else {
            history.push('/support')
        }
    }
    const submitvideoHandle = () => {        
        openVideoSubmitModal();
    }
    const handleRegisterForELos = () => {
        window.open('https://www.hoyasurgicaloptics.com/elos-registration', '_blank');
    }
    const handleSignInForELos = () => {
        window.open('https://esaso.workplace.com/','_blank');
    }
    return (
        <section className="section talktoUs">
            <div className="flex gap-6 flex-wrap sm:flex-wrap md:flex-wrap lg:flex-nowrap *:basis-full *:sm:basis-1/2 *:md:basis-1/3 bg-[#F4F8FD] px-5 sm:px-5 md:px-5 lg:px-14 py-10 sm:py-10 md:py-10 lg:py-14 *:grow">
                <div className="submitVideo hidden">
                    <div className="bg-white sm:px-5 sm:py-[26px] p-4 flex justify-between flex-wrap sm:flex-wrap md:flex-nowrap gap-16 sm:gap-16 md:gap-12 rounded-lg shadow-talkToUsShadow flex-col h-full">
                        <div className="flex flex-col gap-1">
                            <h2 className="text-[#333] barlowBold text-lg leading-6 sm:text-[28px] sm:leading-9 tracking-[-0.25px]">Submit Your Video</h2>
                            <p className="mt-[6px] sm:mt-2 text-[#333] font-normal sm:text-xl sm:leading-7 text-sm leading-5 max-w-full sm:max-w-full xl:max-w-[75%]">Would you like to submit your educational video to Masters video library.</p>
                        </div>
                        <Button
                        className="btn submitVideo bg-primaryBlue text-white rounded px-[10px] py-[5px] sm:px-4 sm:py-[10px] text-sm font-bold leading-5 sm:text-base sm:leading-5 min-w-[200px] sm:min-w-[200px] cursor-pointer mr-auto"
                        onClick={submitvideoHandle}
                        >
                        Contact Us
                        </Button>
                    </div>
                </div>
                <div className="CME_Courses">
                    <div className="bg-white sm:px-5 sm:py-[26px] p-4 flex justify-between  flex-wrap sm:flex-wrap md:flex-nowrap gap-16 sm:gap-16 md:gap-12 rounded-lg shadow-talkToUsShadow flex-col h-full">
                        <div className="flex flex-col gap-1">
                            <h2 className="text-[#333] barlowBold text-lg leading-6 sm:text-[28px] sm:leading-9 tracking-[-0.25px]">CME Courses </h2>
                            <p className="mt-[6px] sm:mt-2 text-[#333] font-normal sm:text-xl sm:leading-7 text-sm leading-5 max-w-full sm:max-w-full xl:max-w-[75%]">Are you looking for courses to support your learning journey while earning Continuing Medical Education credit?</p>
                        </div>
                        <div className="flex gap-3 justify-start flex-wrap">
                            <button className="btn browseCourses bg-primaryBlue text-white rounded px-[10px] py-[5px] sm:px-4 sm:py-[10px] text-sm font-bold leading-5 sm:text-base sm:leading-5 min-w-[200px] sm:min-w-[200px] cursor-pointer" onClick={handleRegisterForELos}>Join eLos</button>
                            <button className="text-[#0057B8] max-[420px]:w-full" onClick={handleSignInForELos}>Already a member ?</button>
                        </div>
                    </div>
                </div>
                <div className="talkToUs">
                    <div className="bg-white sm:px-5 sm:py-[26px] p-4 flex justify-between  flex-wrap sm:flex-wrap md:flex-nowrap gap-16 sm:gap-16 md:gap-12 rounded-lg shadow-talkToUsShadow flex-col h-full">
                        <div className="flex flex-col gap-1">
                            <h2 className="text-[#333] barlowBold text-lg leading-6 sm:text-[28px] sm:leading-9 tracking-[-0.25px]">Need Help?</h2>
                            <p className="mt-[6px] sm:mt-2 text-[#333] font-normal sm:text-xl sm:leading-7 text-sm leading-5 max-w-full sm:max-w-full xl:max-w-[75%]">Connect with <span className="capitalize">Masters Video Library</span> expert for additional support.</p>

                        </div>
                        <Button
                        className="btn submitVideo bg-primaryBlue text-white rounded px-[10px] py-[5px] sm:px-4 sm:py-[10px] text-sm font-bold leading-5 sm:text-base sm:leading-5 min-w-[200px] sm:min-w-[200px] cursor-pointer mr-auto"
                        onClick={contactusHandle}
                        >
                        Contact Us
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TalkToUs;