import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mobile: null,
    tab: null,
    desktop: null
};

export const deviceTypeSlice = createSlice({
    name: "deviceType",
    initialState,
    reducers: {
        checkMobile: (state) => {
            state.mobile = true;
            state.tab = false;
        },
        checkTab: (state) => {
            state.tab = true;
            state.mobile = false;
        },
        checkDesktop: (state) => {
            state.tab = false;
            state.mobile = false;
            state.desktop = true;
        }
    },
});

export const { checkMobile, checkTab, checkDesktop } =
    deviceTypeSlice.actions;

export default deviceTypeSlice.reducer;