import { useRef, useState } from "react"
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";

import { useModal } from '../../store/ModalContext';
import { contactUs } from "../../lib/api";
import LoadingSpinner from "../UI/LoadingSpinner";
import getBase64 from "../UI/Base64";
import useInput from "../../hooks/use-input";
import Input from "../UI/Input";
import { isSubject } from "../UI/Validations";

const RequestForm = () => {
    window.scrollTo(0, 0);
    const { openSupportThankYouModal } = useModal();

    const { currentUser } = useSelector((state) => state.user);

    const [imageUploaded, setImageUploaded] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [fileName, setFileName] = useState(false);
    const [exceededImageSizeError, setExceededImageSizeError] = useState(false)
    const [isFileLoading, setIsFileLoading] = useState(false)
    const [messageIsValid, setMessageIsValid] = useState(false)
    const [enteredMessage, setEnteredMessage] = useState()
    const [errorMessage, setErrorMessage] = useState('')

    const subjectInputRef = useRef();

    const {
        value: subjectValue,
        isValid: subjectIsValid,
        hasError: subjectHasError,
        valueChangeHandler: subjectChangeHandler,
        inputBlurHandler: subjectBlurHandler,
    } = useInput(isSubject);

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length === 0) {
            setIsFileLoading(false);
            setExceededImageSizeError(true)
        } else {
            const reader = new FileReader()
            reader.onload = (e) => {
                try {
                    setFileName(acceptedFiles[0].name)
                } catch (error) {
                    console.error('Error creating object URL:', error);
                }
            }
            getBase64(acceptedFiles[0])
                .then((base64Image) => {
                    setImageUploaded(base64Image);
                })
            reader.readAsDataURL(acceptedFiles[0])
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            "image/jpeg": [],
            "image/png": [],
            "application/pdf": ['.pdf'],
            "application/msword": ['.doc'],
            "application/vnd.ms-excel": ['.xls']
        },
        maxSize: 1000000,
        disabled: isFileLoading ? true : false
    });

    const handlerRequestSubmitted = () => {
        openSupportThankYouModal();
    }

    const handleChange = (e) => {
        e.preventDefault();
        let { id, value } = e.target;
        if (id === "message") {
            if (value[0] === ' ') { setErrorMessage("Message cannot start with space"); setMessageIsValid(false) }
            else if (value.trim().length === 0) { setErrorMessage("Please enter message"); setMessageIsValid(false); }
            else { setMessageIsValid(true) };
        }
        setEnteredMessage(value)
    }

    let formIsValid = false;

    if (
        messageIsValid &&
        !subjectIsValid
    ) {
        formIsValid = true
    }

    const submitHandler = (event) => {
        event.preventDefault();

        if (!formIsValid) {
            return;
        }

        setIsLoading(true);

        const userName = currentUser ? currentUser?.user_extra_details?.first_name : "";
        const userMail = currentUser ? currentUser?.current_user?.name : "";
        const userId = currentUser ? currentUser?.current_user?.uid : "";
        const enteredSubject = subjectInputRef.current.value;

        const formData = {
            contact_form: [
                {
                    target_id: "support"
                }
            ],
            name: {
                value: userName,
            },
            subject: {
                value: enteredSubject
            },
            mail: {
                value: userMail,
            },
            message: {
                value: enteredMessage
            },
            field_userid: {
                value: userId
            },
            field_support_image: {
                value: imageUploaded
            },
            field_support_query_list: {
                value: 0
            }
        };

        contactUs(formData)
            .then(() => {
                setIsLoading(false)
                handlerRequestSubmitted()
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error);;
            })
    }

    const subjectClasses = (subjectIsValid)
        ? "emailSection gap-1 flex flex-col invalid"
        : "emailSection gap-1 flex flex-col";

    return (
        <div className="grow">
            {isLoading && (
                <div className="parentDisable">
                    <LoadingSpinner />
                </div>
            )}
            <section className="section supportSection">
                <div className="px-5 sm:px-5 md:px-5 lg:px-14 pt-5 pb-8 sm:pt-5 sm:pb-8 md:pt-5 md:pb-8 lg:py-8 h-full">
                    <h2 className="text-[#333] barlowBold text-lg leading-8 sm:text-2xl sm:leading-9 uppercase tracking-[-0.25px]">Support</h2>
                    <p className="text-[#333] text-base leading-6 sm:text-lg sm:leading-8 font-normal">Your opinion matters – tell us what you think!</p>
                    <div className="stepsContainer max-w-full lg:max-w-[70%] mx-auto">
                        <div className=" mt-7 sm:mt-9">
                            <div className="supportQueryCatagory mt-8 sm:mt-10 text-center w-fit mx-auto bg-[#e4ecf266] px-4 py-[10px] rounded-lg">
                                <div className="text-primaryBlue text-lg leading-6 sm:text-xl sm:leading-6 font-bold">Masters Video Library</div>
                            </div>
                            <div className="supportQueries mt-6 sm:mt-8 text-center">
                                <h3 className="text-lg leading-6 sm:text-2xl sm:leading-8 font-medium">Tell us about your request</h3>
                            </div>
                            <div className="mt-4 sm:mt-6">
                                <form className="max-w-full sm:max-w-[60%] mx-auto" onSubmit={submitHandler}>
                                    <div className="space-y-[22px]">
                                        <div className={subjectClasses}>
                                            <Input
                                                ref={subjectInputRef}
                                                label="Subject"
                                                labelClass="emailLabel text-[#333] text-sm font-normal leading-5"
                                                isManditory={true}
                                                input={{
                                                    id: "subject",
                                                    name: "subject",
                                                    type: "text",
                                                    value: subjectValue,
                                                    placeholder: "Enter Subject",
                                                    onChange: subjectChangeHandler,
                                                    onBlur: subjectBlurHandler,
                                                    className:
                                                        "!text-sm p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 bg-white loginField placeholder:text-[#666]",
                                                }}
                                            />
                                            {subjectHasError && (
                                                <p className="error-text">
                                                    {subjectIsValid}
                                                </p>
                                            )}
                                        </div>
                                        <div className="commentsSection flex flex-col gap-[6px]">
                                            <label className="titleLabel text-[#333] text-sm font-normal leading-5">Comments *</label>
                                            <textarea onChange={handleChange} id='message' className="w-full font-normal p-[10px] rounded border border-loginFieldBorder text-[#666]  placeholder:text-[#666] text-sm leading-6" placeholder="Enter your comments here...." rows="4" cols="50"></textarea>
                                            {!messageIsValid && errorMessage && (
                                                <p className="error-text">
                                                    {errorMessage}
                                                </p>
                                            )}
                                        </div>
                                        <div className="uploadSection flex flex-col gap-[6px]">
                                            <label className="titleLabel text-[#333] text-sm sm:text-base font-normal leading-5">Please upload supporting documentation below</label>
                                            <div className="uploadField bg-[#e4ecf266] border border-[#7DA1C4] border-dashed py-5 h-20">
                                                <div className="flex items-center gap-5 justify-center">
                                                    <div className="uploadIcon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                                            <path d="M14.9039 36.6654H24.8398C33.1197 36.6654 36.4317 33.332 36.4317 24.9987V14.9987C36.4317 6.66536 33.1197 3.33203 24.8398 3.33203H14.9039C6.62398 3.33203 3.31201 6.66536 3.31201 14.9987V24.9987C3.31201 33.332 6.62398 36.6654 14.9039 36.6654Z" stroke="#7DA1C4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M14.9038 16.6667C16.7329 16.6667 18.2157 15.1743 18.2157 13.3333C18.2157 11.4924 16.7329 10 14.9038 10C13.0746 10 11.5918 11.4924 11.5918 13.3333C11.5918 15.1743 13.0746 16.6667 14.9038 16.6667Z" stroke="#7DA1C4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M4.42139 31.5836L12.5854 26.0669C13.8936 25.1836 15.7814 25.2836 16.9572 26.3003L17.5037 26.7836C18.7953 27.9003 20.8819 27.9003 22.1735 26.7836L29.0624 20.8336C30.3541 19.7169 32.4406 19.7169 33.7323 20.8336L36.4315 23.1669" stroke="#7DA1C4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </div>
                                                    <div className="dragDropUploadField text-sm font-medium leading-5" {...getRootProps({ className: "dropzone" })}>
                                                        <input {...getInputProps()} />
                                                        <p className="text-xs leading-4 text-center mx-2">
                                                            Drag and drop files here or
                                                            <span className='browseImageLink text-[#0057B8] font-medium decoration-1 underline cursor-pointer'>
                                                                {" "}
                                                                Browse
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {exceededImageSizeError && <p className="error-text text-center">Please choose an image lesser than 1Mb to upload.</p>}
                                        {fileName && <div className="fileStatusSection">
                                            <div className="flex justify-between items-center gap-5">
                                                <div className="flex flex-wrap gap-7 items-center">
                                                    <p className="fileName">{fileName}</p>
                                                    <span className="closeIcon cursor-pointer" onClick={() => setFileName()}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                            <rect width="12" height="12" fill="white" />
                                                            <path d="M2.37375 10.0788C2.27875 10.0788 2.18375 10.0437 2.10875 9.96875C1.96375 9.82375 1.96375 9.58375 2.10875 9.43875L8.93875 2.10875C9.08375 1.96375 9.32375 1.96375 9.46875 2.10875C9.61375 2.25375 9.61375 2.49375 9.46875 2.63875L2.63875 9.96875C2.56875 10.0437 2.46875 10.0788 2.37375 10.0788Z" fill="#717375" stroke="#717375" />
                                                            <path d="M9.20375 10.0788C9.10875 10.0788 9.01375 10.0437 8.93875 9.96875L2.10875 2.63875C1.96375 2.49375 1.96375 2.25375 2.10875 2.10875C2.25375 1.96375 2.49375 1.96375 2.63875 2.10875L9.46875 9.43875C9.61375 9.58375 9.61375 9.82375 9.46875 9.96875C9.39375 10.0437 9.29875 10.0788 9.20375 10.0788Z" fill="#717375" stroke="#717375" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <button type="button" className="uploadBtn border border-primaryBlue rounded px-5 py-[10px] font-medium text-primaryBlue h-9 flex items-center justify-center hidden">Upload</button>
                                            </div>
                                        </div>}
                                        <button
                                            className={!formIsValid ? "max-[420px]:w-full btn !mt-6 submitBtn w-fit bg-primaryBlue h-[46px] px-5 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center min-w-[140px] disabled" : "max-[420px]:w-full btn !mt-6 submitBtn w-fit bg-primaryBlue h-[46px] px-5 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center min-w-[140px]"}
                                            type="submit"
                                            disabled={!formIsValid}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default RequestForm