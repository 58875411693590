import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    peerskeywordList: null,
};

export const peersearchSlice = createSlice({
    name: "peersearch",
    initialState,
    reducers: {
        updatePeerSearch: (state, action) => {
            state.peerskeywordList = action.payload;
        },
        clearPeerSearch: (state) => {
            state.peerskeywordList = null;
        },        
    },
});

export const { updatePeerSearch, clearPeerSearch } = peersearchSlice.actions;

export default peersearchSlice.reducer;