import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useModal } from '../../store/ModalContext';
import { getAutocompleteKeywords } from '../../lib/api';
import { useSelector } from 'react-redux'

const MiniSearch = () => {
  const minisearchTextBox = useRef();
  const history = useHistory();
  const location = useLocation();
  const { EncryptionData } = useModal();
  const [searchClose, setSearchClose] = useState('hidden');
  const [autoComplete, setAutoComplete] = useState('hidden');
  const [keywordsList, setKeywordsList] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);

  const clearSearchText = () => {
    setAutoComplete('hidden');
    setSearchClose('hidden');
    minisearchTextBox.current.value = '';
  }

  const handlerSearchAll = () => {
    const textbox_value = minisearchTextBox.current.value.toLowerCase();
    if(textbox_value && textbox_value.length > 2) {
      setAutoComplete('hidden');
      localStorage.setItem('minisearch_value', textbox_value);      
      history.push('/searchall-result/' + EncryptionData(textbox_value));
    }      
  }

  const handleSearchClick = () => {
    getAutocompleteKeywords()
    .then((response) => {
      setKeywordsList(response.result);        
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const sortKeywordsByRelevance = (keywordsList, searchText) => {
    return keywordsList.sort((a, b) => {
      const startsWithSearchTextA = a.startsWith(searchText);
      const startsWithSearchTextB = b.startsWith(searchText);
      if (startsWithSearchTextA && startsWithSearchTextB) {
        return a.length - b.length;
      }
      if (startsWithSearchTextA) {
        return -1;
      }
      if (startsWithSearchTextB) {
        return 1;
      }
      return a.localeCompare(b);
    });
  }

  const handleSearchOnKeyDown = (e) => {
    const textbox_value = minisearchTextBox.current.value.toLowerCase();
    if (e.keyCode === 32 && !textbox_value.length)
        e.preventDefault();    
    if(textbox_value && textbox_value.length > 2) {
      if(keywordsList && keywordsList.length > 0) {
        const suggestionArray = keywordsList.filter(str => str.includes(textbox_value));
        sortKeywordsByRelevance(suggestionArray, textbox_value)
        setSuggestionList(suggestionArray);
      }
      setAutoComplete('block');
      setSearchClose('block');
      if(e.key === 'Enter' || e.keyCode === 13) {
        setAutoComplete('hidden');
        minisearchTextBox.current.value = textbox_value;
        localStorage.setItem('minisearch_value', textbox_value);      
        history.push('/searchall-result/' + EncryptionData(textbox_value));        
      }
    } else {
      setAutoComplete('hidden');
      setSearchClose('hidden');
    }    
  }

  const handlesuggestionClick = (value) => {
    if(value && value.length > 2) {
      setAutoComplete('hidden');
      minisearchTextBox.current.value = value;
      localStorage.setItem('minisearch_value', value);
      history.push('/searchall-result/' + EncryptionData(value));
    }      
  }

  const goBack = () => {
    history.go(-1)
  }

  useEffect(() => {
    var search_value = localStorage.getItem('minisearch_value');
    let path = location.pathname.split("/");
    if(search_value && path[1] === 'minisearch') {
      minisearchTextBox.current.value = search_value;      
    }    
  }, [location.pathname]);
  
  const { mobile, tab } = useSelector((state) => (state.deviceType));
  if(mobile === false || tab === false) {
    //history.push('/');
  }

  return (
    <div className="miniSearchHolder absolute z-[999] inset-0  md:hidden bg-white">
      <div className="searchResults flex flex-col grow  px-5 sm:px-5 md:px-5 lg:px-14 pt-4 pb-10 lg:pt-8  lg:pb-20">
        <div className="searchResultsHeader mt-3">
          <div className="flex justify-between items-center gap-3 sm:gap-4 flex-wrap">
            <h2 className="title text-lg leading-5  barlowBold text-[#333]">
              Search
            </h2>
            <div className="closeIconHolder static h-full flex items-center cursor-pointer" onClick={goBack}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z"
                  fill="#717375"
                  stroke="#717375"
                ></path>
                <path
                  d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z"
                  fill="#717375"
                  stroke="#717375"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="mt-6 searchHolder h-10 basis-auto sm:basis-auto md:basis-auto lg:basis-[40%] rounded-lg justify-between border border-[#C2CED7] bg-white flex shrink grow-0 group relative group">
          <input
            className="bg-white rounded-tl-lg rounded-bl-lg grow px-[14px] leading-6 text-base font-medium placeholder:text-[#717375] outline-none shadow-none"
            type="text"
            name="search"
            autoComplete="off"
            placeholder="Search"
            ref={minisearchTextBox}
            onKeyDown={handleSearchOnKeyDown}
            onClick={handleSearchClick}
          />
          <div className={`closeIconHolder static h-full flex items-center pr-4 cursor-pointer block ${searchClose}`} onClick={clearSearchText}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z"
                fill="#717375"
                stroke="#717375"
              ></path>
              <path
                d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z"
                fill="#717375"
                stroke="#717375"
              ></path>
            </svg>
          </div>
          <button className="searchBtn px-[14px] bg-[#F9F9F9] rounded-tr-lg rounded-br-lg cursor-pointer border-l border-[#C2CED7]" onClick={handlerSearchAll}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 2.75C6.66751 2.75 2.75 6.66751 2.75 11.5C2.75 16.3325 6.66751 20.25 11.5 20.25C16.3325 20.25 20.25 16.3325 20.25 11.5C20.25 6.66751 16.3325 2.75 11.5 2.75ZM1.25 11.5C1.25 5.83908 5.83908 1.25 11.5 1.25C17.1609 1.25 21.75 5.83908 21.75 11.5C21.75 17.1609 17.1609 21.75 11.5 21.75C5.83908 21.75 1.25 17.1609 1.25 11.5Z"
                fill="#717375"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.4697 19.4697C19.7626 19.1768 20.2374 19.1768 20.5303 19.4697L22.5303 21.4697C22.8232 21.7626 22.8232 22.2374 22.5303 22.5303C22.2374 22.8232 21.7626 22.8232 21.4697 22.5303L19.4697 20.5303C19.1768 20.2374 19.1768 19.7626 19.4697 19.4697Z"
                fill="#717375"
              ></path>
            </svg>
          </button>
          <div className={`searchDropdownHolder absolute mt-1 top-full max-h-[260px] overflow-auto rounded-lg border border-[#C2CED7] bg-white left-0 right-0 z-50 ${autoComplete}`}>
            <div className="flex flex-col">
            {suggestionList.length > 0 ? (
              <>
            {suggestionList.map((details) =>
              <div className="searchResultItem px-[14px] py-[7px] hover:bg-[#F0F0F0] cursor-pointer" key={details} onClick={() => handlesuggestionClick(details)}>
                <div className="flex gap-2 items-center">
                  <div className="searchIcon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        d="M8.26369 13.9999C11.8579 13.9999 14.7715 11.1644 14.7715 7.66659C14.7715 4.16878 11.8579 1.33325 8.26369 1.33325C4.66952 1.33325 1.75586 4.16878 1.75586 7.66659C1.75586 11.1644 4.66952 13.9999 8.26369 13.9999Z"
                        stroke="#717375"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M15.4565 14.6666L14.0864 13.3333"
                        stroke="#717375"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <p className="searchText text-black text-sm  leading-7 sm:text-base sm:leading-8 truncate ">
                    {details}
                  </p>
                </div>
              </div>
            )}
              </>
            ): ( 
              <div className='searchResultItem px-[14px] py-[7px] hover:bg-[#F0F0F0]'>--- No results found ---</div>            
            )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MiniSearch