import React, { useRef, useEffect } from 'react'
import "../../assets/videojs/skins/treso/videojs.css";
import videojs from "video.js";
import "../../assets/videojs/components/nuevo.js";

const Highlightsvideo = (props) => {
    const highlightsvideoNode = useRef(props.useRef);    
    let highlightsplayer;
    
    // if(props.pausevideo_option && typeof highlightsvideoNode.current === 'object' && highlightsvideoNode.current.current !== null) {
    //   highlightsvideoNode.current.pause();
    // }

    useEffect(() => {
        // video properties
        const videoJsOptions = {
          controls: true,
          preload: true,
          playsinline: true,          
          poster: "",
          sources: [
            {
              src: props.src,
              type: props.type,
            }
          ]                
        };

        // initialize Video.js
        highlightsplayer = videojs(
          highlightsvideoNode.current,
          videoJsOptions,
          function onPlayerReady() {
          }
        );

        // Nuevo plugin options
        const nuevoOptions = {
          logo: "",
          logourl: "",
          zoomMenu: true,
          zoomInfo: true,
          settingsButton: false,
          buttonRewind: true,
          buttonForward: true,
          shareMenu: false,
          rateMenu: true,
          qualityMenu: true,
          pipButton: true,  
          controlbar: true       
        };
    
        // Initialize Nuevo plugin
        highlightsplayer.nuevo(nuevoOptions);

        highlightsplayer.on('ended', function() {
          //console.log('Awww... highlight video over so soon?!');
        });
        
        highlightsplayer.on('pause', function() {
          //console.log('Awww...highlight video paused?!');
        });        
    
        // destroy player on unmount
        return () => {
          if (highlightsplayer) {
            highlightsplayer.dispose();
          }
        };
      }, []);

    return (
      <div>
      <video ref={highlightsvideoNode} className="video-js vjs-fluid" data-vjs-player/> 
      </div>         
    )
}

export default Highlightsvideo
