import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getWatchLaterVideos } from '../../lib/api';
import { useSelector } from 'react-redux';
import NoResults from '../../pages/NoResults';
import { useModal } from '../../store/ModalContext';
import LoadingSpinner from '../UI/LoadingSpinner';
import defaultThumbnail from '../../assets/images/thumbnails/thumbnailsPlaceholder.png';

const WatchLater = () => {
  const history = useHistory();
  const [watchlaterList, setWatchLaterList] = useState([]);
  const [areNoResults, setAreNoResults] = useState(false)
  const { currentUser } = useSelector((state) => state.user);
  const { secondsToMinutes } = useModal();
  const [isLoading, setIsLoading] = useState(true);

  const watchlatersendRequest = useCallback(() => {
    getWatchLaterVideos(currentUser)
      .then((response) => {
        response.length > 0 ? setWatchLaterList(response) : setAreNoResults(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [currentUser])

  useEffect(() => {
    watchlatersendRequest();
  }, [watchlatersendRequest])

  const handleWatchLaterVideo = (title) => {
    history.push('/video-details'+title);
  }

  const onImageError = (e) => {
    e.target.src = defaultThumbnail;
  }  

  return (
    <div className="authorsListSection h-full flex flex-col grow">
      {isLoading && (
        <div className="parentDisable">
          <LoadingSpinner />
        </div>
      )}
      <div className="bg-[#F4F8FD] px-5 sm:px-5 md:px-5 lg:px-14 pt-5 sm:pt-5 md:pt-5 lg:pt-10 pb-10 sm:pb-20 grow">

        <div>
          <div className="publishedVideos">
            <h3 className="text-[#333] text-lg sm:text-[28px] leading-7 sm:leading-9 barlowBold">
              Watch Later
            </h3>
            {watchlaterList.length > 0 &&
              <div className="searchResutsVideos">
                <div className="cards flex-wrap flex gap-4 sm:gap-6  mt-3 sm:mt-5 *:rounded-lg *:shadow-cardShadow *:cursor-pointer *:flex-shrink-0 pb-[6px]">
                  {watchlaterList.map((details) =>
                    <div className="card flex flex-col" key={details.mid} onClick={() => handleWatchLaterVideo(details.slug_url)}>
                      <div className="thumbnail h-[190px] sm:h-[206px] md:h-[226px] lg:h-[256px]  relative group">
                        <img
                          src={details.thumbnail_url || defaultThumbnail}
                          className="w-full h-full rounded-tl-lg rounded-tr-lg object-cover"
                          alt="thumbnail"
                          onError={onImageError}
                        />
                        {details.duration && details.duration > 0 ?
                        <p className="duration  bg-videoDurationBg text-white text-xs font-normal leading-4 rounded px-2 py-1 absolute right-4 bottom-4">{secondsToMinutes(details.duration)}</p>
                        : <></>}
                        <div className="actionsHolder absolute right-4 top-4 flex-col gap-4 *:p-[6px] *:rounded !hidden group-hover:flex z-50">
                          <div className="heart bg-videoDurationBg hover:bg-[#006FBA]">
                            <svg
                              width="22"
                              height="20"
                              viewBox="0 0 22 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.56 1.75C3.90771 1.75 1.75 3.9107 1.75 6.59C1.75 9.84052 3.25068 12.4449 5.13923 14.3799C7.03683 16.3242 9.27995 17.542 10.6223 18.0002L10.6296 18.0027L10.6296 18.0028C10.6944 18.0256 10.8286 18.05 11 18.05C11.1714 18.05 11.3056 18.0256 11.3704 18.0028L11.3777 18.0002L11.3777 18.0002C12.7201 17.542 14.9632 16.3242 16.8608 14.3799C18.7493 12.4449 20.25 9.84052 20.25 6.59C20.25 3.9107 18.0923 1.75 15.44 1.75C13.8778 1.75 12.477 2.50911 11.6021 3.68716C11.4606 3.87768 11.2373 3.99 11 3.99C10.7627 3.99 10.5394 3.87768 10.3979 3.68716C9.52255 2.5085 8.13147 1.75 6.56 1.75ZM0.25 6.59C0.25 3.0893 3.07229 0.25 6.56 0.25C8.29674 0.25 9.86456 0.955987 11.0003 2.09472C12.1385 0.955639 13.7122 0.25 15.44 0.25C18.9277 0.25 21.75 3.0893 21.75 6.59C21.75 10.3395 20.0107 13.3001 17.9342 15.4276C15.8683 17.5443 13.4235 18.8861 11.8657 19.4186C11.5914 19.5147 11.2773 19.55 11 19.55C10.7227 19.55 10.4086 19.5147 10.1343 19.4186C8.57655 18.8861 6.13169 17.5443 4.06577 15.4276C1.98932 13.3001 0.25 10.3395 0.25 6.59Z"
                                fill="white"
                              ></path>
                            </svg>
                          </div>
                          <div className="clock bg-videoDurationBg hover:bg-[#006FBA]">
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11 1.75003C5.89137 1.75003 1.75 5.8914 1.75 11C1.75 16.1087 5.89137 20.25 11 20.25C16.1086 20.25 20.25 16.1087 20.25 11C20.25 5.8914 16.1086 1.75003 11 1.75003ZM0.25 11C0.25 5.06297 5.06294 0.250031 11 0.250031C16.9371 0.250031 21.75 5.06297 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C5.06294 21.75 0.25 16.9371 0.25 11ZM11 5.25003C11.4142 5.25003 11.75 5.58582 11.75 6.00003V10.4595L14.2372 11.2885C14.6301 11.4195 14.8425 11.8442 14.7115 12.2372C14.5805 12.6302 14.1558 12.8425 13.7628 12.7115L10.7628 11.7115C10.4566 11.6095 10.25 11.3229 10.25 11V6.00003C10.25 5.58582 10.5858 5.25003 11 5.25003Z"
                                fill="white"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div className="playBtn absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 cursor-pointer hidden group-hover:block">
                          <svg
                            width="38"
                            height="44"
                            viewBox="0 0 38 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M36.9612 23.5936L2.99228 43.0044C1.65896 43.7663 0 42.8036 0 41.2679V21.8571V2.44636C0 0.910713 1.65896 -0.052021 2.99227 0.709873L36.9611 20.1207C38.3048 20.8884 38.3048 22.8258 36.9612 23.5936Z"
                              fill="white"
                              fillOpacity="0.8"
                            ></path>
                          </svg>
                        </div>
                        <div className="overlay"></div>
                      </div>
                      <div className="description bg-white p-4 flex flex-col gap-1">
                        <p className="videoCatagory text-videoCatagory text-xs sm:text-sm leading-5 font-medium tracking-[0.035px] ">
                          {details.educational_content}
                        </p>
                        <p className="videoCaption text-videoCaption  leading-5 sm:leading-6 font-medium  text-sm sm:text-base">
                          {details.title}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>}
            {areNoResults && <NoResults />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchLater;