import { useEffect, useRef, useState } from "react";
import useInput from '../../hooks/use-input';
import { useLocation, useHistory } from 'react-router-dom';
import { resetPasswordUpdate, passwordTimeUpdate } from "../../lib/api";
import Button from '../UI/Button';
import LoadingSpinner from '../UI/LoadingSpinner';
import { isPassword } from "../UI/Validations";

const ResetNewPassword = () => {

  const history = useHistory();
  const location = useLocation();
  const newpasswordInputRef = useRef();
  const confirmpasswordInputRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isChangePasswordFailed, setIsChangePasswordFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [newpasswordtype, setNewPasswordType] = useState('password');
  const [confirmpasswordtype, setConfirmPasswordType] = useState('password');
  const [successMessage, setSuccessMessage] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [unCheckedError, setUnCheckedError] = useState('');
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [unTermsError, setUnTermsError] = useState('');  


  const {
    value: newpasswordValue,
    isValid: newpasswordIsValid,
    hasError: newpasswordHasError,
    hasEmptyError: newpasswordHasEmptyError,
    valueChangeHandler: newpasswordChangeHandler,
    inputBlurHandler: newpasswordBlurHandler,
  } = useInput(isPassword);

  const {
    value: confirmpasswordValue,
    isValid: confirmpasswordIsValid,
    hasError: confirmpasswordHasError,
    valueChangeHandler: confirmpasswordChangeHandler,
    inputBlurHandler: confirmpasswordBlurHandler,
  } = useInput(isPassword, newpasswordValue);  

  let formIsValid = false;

  if (!newpasswordIsValid &&
    !confirmpasswordIsValid &&
    isChecked && isTermsChecked
  ) {
    formIsValid = true;
  }

  const toggleShowNewPassword = () => {
    if (newpasswordtype === 'password') {
      setNewPasswordType('text')
    } else {
      setNewPasswordType('password')
    }
  }

  const toggleShowConfirmPassword = () => {
    if (confirmpasswordtype === 'password') {
      setConfirmPasswordType('text')
    } else {
      setConfirmPasswordType('password')
    }
  }

  const handleCheckbox = (event) => {
    setIsChecked(event.target.checked)
    if (!event.target.checked) {
      setUnCheckedError('Please check the checkbox to proceed further');
    } else setUnCheckedError('')
  }

  const handleTermsCheckbox = (event) => {
    setIsTermsChecked(event.target.checked)
    if (!event.target.checked) {
      setUnTermsError('Please check the checkbox to proceed further');
    } else setUnTermsError('')
  }  

  const handleOrganizationMessage = () => {
    window.open("/messages-organization", "_blank");
  }

  const handleTermsandService = () => {
    window.open("/terms-service", "_blank");
  }

  const handlePrivacyPolicy = () => {
    window.open("/privacy-statement", "_blank");
  }

  const goBackHome = () => {
    history.push(`/`);
    window.scrollTo(0, 0);
  }

  let path = location.pathname.split("/");

  const submitHandler = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    setIsLoading(true);

    const emailParams = path[2];
    const enteredNewPassword = newpasswordInputRef.current.value;
    const enteredOldPassword = path[3];

    const updateData = {
      name : emailParams,
      temp_pass : enteredOldPassword,
      new_pass : enteredNewPassword
    }

    const timeUpdateData = {
      email : emailParams
    }

    resetPasswordUpdate(updateData)
      .then(async (res) => {
      passwordTimeUpdate(timeUpdateData)
        .then((response) => {
          //console.log(response,'dsafewrewr');
        })
        .catch((error) => {
            console.log(error);
        });        
          setIsLoading(false);
          setSuccessMessage(true);
        })
      .catch((error) => {
        setIsLoading(false)
        setIsChangePasswordFailed(true);
        setErrorMessage(error.message);
      });

  };

  useEffect(() => {
  }, []);

  const newpasswordClasses = (newpasswordHasError || newpasswordHasEmptyError) ? 'passwordField p-[10px] rounded border border-loginFieldBorder h-10 flex items-center justify-between gap-5 bg-white invalid-password' : 'passwordField p-[10px] rounded border border-loginFieldBorder h-10 flex items-center justify-between gap-5 bg-white';

  const newpasswordInputClass = newpasswordHasError ? '!text-sm text-[#666] w-full block outline-none shadow-none invalid-pass-bg placeholder:text-[#666]' : '!text-sm text-[#666] w-full block outline-none shadow-none  placeholder:text-[#666]';

  const confirmpasswordClasses = confirmpasswordHasError ? 'passwordField p-[10px] rounded border border-loginFieldBorder h-10 flex items-center justify-between gap-5 bg-white invalid-password' : 'passwordField p-[10px] rounded border border-loginFieldBorder h-10 flex items-center justify-between gap-5 bg-white';

  const confirmpasswordInputClass = confirmpasswordHasError ? '!text-sm text-[#666] w-full block outline-none shadow-none invalid-pass-bg placeholder:text-[#666]' : '!text-sm text-[#666] w-full block outline-none shadow-none  placeholder:text-[#666]';

  return (    
    <div className="resetPasswordHolder w-full sm:w-[592px] flex flex-1 justify-center items-stretch sm:items-center sm:m-auto">
      <div className="resetPasswordBg flex flex-col grow px-5 sm:px-5 md:p-7 py-5  lg:px-14  lg:py-10 m-5  bg-transparent sm:bg-white sm:landscape:bg-transparent md:landscape:bg-white rounded-lg justify-center">
        {isLoading && (
          <div className="parentDisable">
            <LoadingSpinner />
          </div>
        )}      
        <div className="searchResultsHeader">
          <div className="flex justify-between items-center gap-3 sm:gap-4 flex-wrap">
            <h2 className="title text-lg leading-5  barlowBold text-[#333]">
              Set Password
            </h2>
          </div>
        </div>
        {successMessage === false ? (
        <>
        <form className="loginForm mt-6" onSubmit={submitHandler}>
          <div className="space-y-6">
            <div className="passwordSection gap-[6px] flex flex-col relative">
              <div className="passwordLabel flex justify-between">
                <span className="text-[#333] text-sm font-normal leading-5">
                  New Password
                </span>
              </div>
              <div className={newpasswordClasses}>
                <input
                  ref={newpasswordInputRef}
                  type={newpasswordtype}
                  id="newpassword"
                  name="newpassword"
                  onChange={newpasswordChangeHandler}
                  onBlur={newpasswordBlurHandler}
                  value={newpasswordValue}
                  className={newpasswordInputClass}
                  placeholder="Enter Your New Password"
                />

                <div
                  className="eyeIcon cursor-pointer opacity-50"
                  onClick={toggleShowNewPassword}
                >
                  {newpasswordtype === "password" ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_3379_10992)">
                        <path
                          d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.5104 12.7C15.2504 14.11 14.1004 15.26 12.6904 15.52"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.47 14.53L2 22"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M22.0003 2L14.5303 9.47"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3379_10992">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_3379_10991)">
                        <path
                          d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3379_10991">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                </div>
              </div>
              {newpasswordHasError && (
                <p className="error-text">{newpasswordIsValid}</p>
              )}
            </div>
            <div className="passwordSection gap-[6px] flex flex-col relative">
              <div className="passwordLabel flex justify-between">
                <span className="text-[#333] text-sm font-normal leading-5">
                  Confirm Password
                </span>
              </div>
              <div className={confirmpasswordClasses}>
                <input
                  ref={confirmpasswordInputRef}
                  type={confirmpasswordtype}
                  id="confirmpassword"
                  name="confirmpassword"
                  onChange={confirmpasswordChangeHandler}
                  onBlur={confirmpasswordBlurHandler}
                  value={confirmpasswordValue}
                  className={confirmpasswordInputClass}
                  placeholder="Enter Your Confirm Password"
                />

                <div
                  className="eyeIcon cursor-pointer opacity-50"
                  onClick={toggleShowConfirmPassword}
                >
                  {confirmpasswordtype === "password" ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_3379_10992)">
                        <path
                          d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.5104 12.7C15.2504 14.11 14.1004 15.26 12.6904 15.52"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.47 14.53L2 22"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M22.0003 2L14.5303 9.47"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3379_10992">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_3379_10991)">
                        <path
                          d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3379_10991">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                </div>
              </div>
              {confirmpasswordHasError && (
                <p className="error-text">Passwords doesn't matched!</p>
              )}
              {isChangePasswordFailed && (
                <div className="error-message">
                  <span className="error-text">{errorMessage}</span>
                </div>
              )}
            </div>
            <div className="flex gap-3 items-start mt-8">
              <input
                type="checkbox"
                className="size-5 rounded-lg shrink-0 border-2 border-loginFieldBorder mt-1"
                onChange={(event) => handleCheckbox(event)} />
                <p className="text-[#333] leading-normal font-normal text-sm sm:text-base">
                I permit MastersVideoLibrary to send me updates from its personalized
                alerts and <span className="text-primaryBlue leading-5 font-normal text-sm cursor-pointer" onClick={handleOrganizationMessage}>messages from organization.</span>
              </p>
            </div>
            {!isChecked && unCheckedError && <p className="error-text mt-1">{unCheckedError}</p>}

            <div className="flex gap-3 items-start mt-2">
              <input
                type="checkbox"
                className="size-5 rounded-lg shrink-0 border-2 border-loginFieldBorder mt-1"
                onChange={(event) => handleTermsCheckbox(event)} />
                <div className='flex flex-col items-start'>
                  <p className="text-[#333] leading-normal font-normal text-sm sm:text-base">
                    I agree to the <span className="text-primaryBlue leading-5 font-normal text-sm cursor-pointer" onClick={handleTermsandService} >Terms of Service</span> 
                  </p>
                  <p className="text-[#333] leading-normal font-normal text-sm sm:text-base">
                  Learn how we collect, use and share your data in our <span className="text-primaryBlue leading-5 font-normal text-sm cursor-pointer" onClick={handlePrivacyPolicy} >Privacy Policy</span> and how we use cookies in our Cookies Policy.
                  </p>
                </div>
            </div>                
            {!isTermsChecked && unTermsError && <p className="error-text mt-1">{unTermsError}</p>}

            {!isLoading && (
              <Button
                disabled={!formIsValid}
                className={!formIsValid ? "!mt-10 disabled btn loginBtn w-full bg-primaryBlue h-[46px] font-bold leading-5 text-base  justify-center items-center rounded text-white text-center cursor-pointer flex" : "!mt-10 btn loginBtn w-full bg-primaryBlue h-[46px] font-bold leading-5 text-base  justify-center items-center rounded text-white text-center cursor-pointer flex"}
                type="submit"
              >
                Submit
              </Button>
            )}
          </div>
        </form>
        </>
        ) : (<div className="searchResutsVideos mt-2"><p className="text-sm sm:base leading-5 text-[#333] font-normal tracking-[0.2px]">Your password has been updated.</p>
        <Button
          onClick={goBackHome}
          className="backToLoginBtn w-full text-white bg-primaryBlue h-[46px] px-5 pt-[10px] font-normal leading-5 text-base flex justify-center items-center rounded"
        >
          Back Home
        </Button>        
        </div>
        
        )}
      </div>  
    </div>  
  );
}

export default ResetNewPassword
