import Modal from "../UI/Modal";
import { useHistory } from 'react-router-dom';

const SupportThankYou = (props) => {
    const history = useHistory();

    const handleBack = () => {
        props.onClose();
        history.push(`/`);
    }
    return (
        <Modal>
            <div className="appWrapper flex flex-col h-screen md:h-auto relative justify-center w-full">
                <div className="thanksMsgHolder max-w-full w-full sm:w-[480px] sm:max-w-[480px]  min-h-full sm:min-h-[220px] bg-[#F4F8FD] sm:bg-[#F4F8FD] flex items-center justify-start rounded-none sm:rounded-lg  mx-auto">
                    <div className="px-10 sm:px-10 md:px-10 lg:px-14 py-10 w-full">
                        <div className="flex items-stretch flex-col sm:flex-row">
                            <div className="bar w-[1px] bg-[#7DA1C4] opacity-60 ml-[55px] mr-[46px] hidden"></div>
                            <div className="message mt-5 sm:mt-0 w-full">
                                <h2 className="text-[#354665] barlowMedium sm:text-4xl text-xl uppercase">THANK YOU</h2>
                                <div className="*:text-[#333] *:font-normal sm:*:text-lg sm:*:leading-7 text-base leading-6">
                                    <p className="mt-2">Thanks for submitting your request. Out team will get back to you shortly.</p>
                                </div>
                                <div className="buttonHolder" onClick={handleBack}>
                                    <button className="btn mt-10 submitBtn w-full bg-primaryBlue h-[46px] px-5 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center min-w-[140px]">Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="closeIconHolder absolute left-10 top-10 block hidden" onClick={props.onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect width="24" height="24" fill="white" />
                            <path d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z" fill="#717375" stroke="#717375" />
                            <path d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z" fill="#717375" stroke="#717375" />
                        </svg>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default SupportThankYou;