import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserProfile } from '../../lib/api';
import LoadingSpinner from '../UI/LoadingSpinner';
import UserProfileUpdatedModal from '../Modals/UserProfileUpdated';
import PersonalInfo from './PersonalInfo';
import ProfessionalInfo from './ProfessionalInfo';
import ContactInfo from './ContactInfo';
import { useModal } from '../../store/ModalContext';

const UserProfile = () => {

  const { currentUser } = useSelector((state) => state.user);

  const { bodyBodyOverflow } = useModal()

  const [initialValue, setInitialValue] = useState()
  const [userData, setUserData] = useState({
    picture: null,
    firstName: '',
    lastName: '',
    institution: '',
    email: '',
  })
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [isEditProfessionalInfo, setIsEditProfessionalInfo] = useState(false)
  const [isEditProfileInfo, setIsEditProfileInfo] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [isProfileUpdated, setIsProfileUpdated] = useState(false)

  const getUserProfileDetails = useCallback(() => {
    setIsLoading(true)
    getUserProfile(currentUser.access_token, currentUser.current_user.uid)
      .then((res) => {
        setInitialValue(res.data[0]);
        setUserData({
          picture: res.data[0]?.picture ? res.data[0]?.picture : null,
          firstName: res.data[0]?.first_name ? res.data[0]?.first_name : "",
          lastName: res.data[0]?.last_name ? res.data[0]?.last_name : "",
          institution: res.data[0]?.institution_name ? res.data[0]?.institution_name : "",
          email: res.data[0]?.mail ? res.data[0]?.mail : "--",
          selectedSpecialityId: res.data[0]?.specialty?.termid,
          selectedSpecialityOption: res.data[0]?.specialty?.name ? res.data[0]?.specialty?.name : "--",
          selectedProfessionId: res.data[0]?.profession?.termid,
          selectedProfessionOption: res.data[0]?.profession?.name ? res.data[0]?.profession?.name : "--",
          selectedCountryName: res.data[0]?.country ? res.data[0]?.country : "",
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  }, [currentUser])

  const handleCancel = () => {
    setUserData((prev) => ({
      ...prev,
      picture: initialValue?.picture,
      firstName: initialValue?.first_name,
      lastName: initialValue?.last_name,
      institution: initialValue?.institution_name,
      selectedProfessionOption: initialValue?.profession?.name,
      selectedSpecialityOption: initialValue?.specialty?.name,
    }));
    setUnsavedChanges(false)
    setIsEditProfileInfo(false)
    setIsEditProfessionalInfo(false)
  }

  const handleEdit = (editSectionNumber) => {
    if (unsavedChanges) {
      let text = "Are you sure you want to leave? All provided data will be lost.";
      if (window.confirm(text) === true) {
        handleCancel()
        if (editSectionNumber === 1) { setIsEditProfileInfo(true); setIsEditProfessionalInfo(false) }
        else if (editSectionNumber === 2) { setIsEditProfileInfo(false); setIsEditProfessionalInfo(true) }
      } else return
    } else {
      if (editSectionNumber === 1) { setIsEditProfileInfo(true); setIsEditProfessionalInfo(false) }
      else if (editSectionNumber === 2) { setIsEditProfileInfo(false); setIsEditProfessionalInfo(true) }
    }
  }

  useEffect(() => {
    getUserProfileDetails()

    const handleBeforeUnload = (event) => {
      event.preventDefault()
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [getUserProfileDetails])

  return (
    <div className="profileSection bg-white p-5 sm:p-5 md:p-5 lg:px-14 py-9 lg:py-9">
      <div className="Profile">
        {isLoading && (
          <div className="parentDisable">
            <LoadingSpinner />
          </div>
        )}
        <div className="profileHeader items-center">
          <div className="flex items-center gap-4 flex-wrap md:flex-nowrap">
            <h2 className="title text-lg leading-5 sm:text-[28px] sm:leading-9 barlowBold uppercase">My Profile</h2>
          </div>
        </div>
        <div className="myProfile mt-7 sm:mt-[60px] max-w-full" id="profile">
          <div className="flex gap-[30px] sm:gap-11 md:gap-[90px] flex-wrap sm:flex-nowrap justify-center">
            <PersonalInfo
              handleCancel={handleCancel}
              userData={userData}
              setUserData={setUserData}
              handleEdit={handleEdit}
              setUnsavedChanges={setUnsavedChanges}
              isEditProfileInfo={isEditProfileInfo}
              setIsLoading={setIsLoading}
              initialValue={initialValue}
              setIsEditProfileInfo={setIsEditProfileInfo}
              setIsProfileUpdated={setIsProfileUpdated}
              getUserProfileDetails={getUserProfileDetails}
            />
            <div className="professionalInfo basis-full sm:basis-auto grow flex flex-col gap-8 sm:gap-[70px]  transition-all ease-in-out duration-700">
              <ProfessionalInfo
                handleCancel={handleCancel}
                userData={userData}
                setUserData={setUserData}
                handleEdit={handleEdit}
                setUnsavedChanges={setUnsavedChanges}
                isEditProfessionalInfo={isEditProfessionalInfo}
                setIsLoading={setIsLoading}
                setIsEditProfessionalInfo={setIsEditProfessionalInfo}
                setIsProfileUpdated={setIsProfileUpdated}
                getUserProfileDetails={getUserProfileDetails}
              />
              <ContactInfo userData={userData} />
            </div>
          </div>
        </div>
        {isProfileUpdated && <UserProfileUpdatedModal onClose={() => { setIsProfileUpdated(false); bodyBodyOverflow() }} />}
      </div>
    </div>
  );
};

export default UserProfile;

