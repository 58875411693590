import React, { useRef, useEffect } from 'react'
import "../../assets/videojs/skins/treso/videojs.css";
import videojs from "video.js";
import "../../assets/videojs/components/nuevo.js";
import "../../assets/videojs/components/thumbnails.js";
import "../../assets/videojs/components/upnext.js";
import "../../assets/videojs/components/upnext.css";
import "../../assets/videojs/components/videojs.hotkeys.js";
import "../../assets/videojs/components/videojs.events.js";
import { useModal } from '../../store/ModalContext.js';
import { useSelector } from 'react-redux';
import { saveVideoDuration } from '../../lib/api.js';

const Videoplayer = (props) => {
  const videoNode = useRef(props.useRef);
  const { openRegisterModal, openEndFeedbackModal, closeFeedbackModal, closeThankYouModal } = useModal();
  const { currentUser } = useSelector((state) => state.user);
  let player;
  var videoDurationSeconds = 0;

  useEffect(() => {
    localStorage.setItem('videoduration', '');
    // video properties
    const videoJsOptions = {
      controls: true,
      preload: true,
      playsinline: true,
      poster: "",
      sources: [
        {
          src: props.src,
          type: props.type,
          resolution: 240,
          label: 240,
        },
        {
          src: props.src,
          type: props.type,
          resolution: 360,
          label: 360,
        }
      ]
    };

    // initialize Video.js
    player = videojs(
      videoNode.current,
      videoJsOptions,
      function onPlayerReady() {
        videoNode.current.play();
        console.log("Player ready.");
      }
    );



    const handleClick = (event) => {

      if (event.target.id === 'clickHereLink') {
        event.preventDefault();
        console.log('Clicked on "Click Here"!');
        openRegisterModal();
      }
    }

    player.on('limiturlclick', handleClick);

    var playLimit = '';
    if (props.playLimit === true) {
      playLimit = 15;
    }

    var playBack = '';
    if (currentUser && props.startTime && props.startTime > 0) {
      playBack = props.startTime;
    }    
    
    // Nuevo plugin options
    const nuevoOptions = {
      logo: "",
      logourl: "",
      title: props.videoTitle,
      video_id: props.videoId,
      zoomMenu: true,
      zoomInfo: true,
      settingsButton: true,
      buttonRewind: true,
      buttonForward: true,
      shareMenu: false,
      rateMenu: true,
      qualityMenu: true,
      pipButton: true,
      controlbar: true,
      startTime: playBack,
      limit: playLimit,
      limiturl: " ",
      limitmessage: "<a href='#' id='clickHereLink' class='text-sm sm:text-base md:text-lg lg:text-xl'>Please 'Register' to continue watching - Click here</a>"      
    };
    
    // Initialize Nuevo plugin
    player.nuevo(nuevoOptions);
    player.thumbnails();

    player.events();

    player.on("track", function (event, data) {

      window.dataLayer = window.dataLayer || [];

      if (data.event === "firstPlay") {
        window.dataLayer.push({
          event: "video",
          videoCurrentTime: 0,
          videoPercent: 0,
          videoProvider: 'MVL',
          videoStatus: 'start',
          videoTitle: data.videoTitle,
        });
      } else if (data.event === "pause") {
        window.dataLayer.push({
          event: "video",
          videoProvider: 'MVL',
          videoStatus: 'pause',
          videoTitle: data.videoTitle,
          videoUrl: '',
        });
      } else if (data.event === "resume") {
        window.dataLayer.push({
          event: "video",
          videoProvider: 'MVL',
          videoStatus: 'resume',
          videoTitle: data.videoTitle,
          videoUrl: '',
        });
      } else if (data.event === "10%") {
        window.dataLayer.push({
          event: "video",
          videoCurrentTime: data.currentTime,
          videoDuration: data.duration,
          videoPercent: 10,
          videoProvider: 'MVL',
          videoStatus: 'progress',
          videoTitle: data.videoTitle,
          videoUrl: '',
        });
      } else if (data.event === "25%") {
        window.dataLayer.push({
          event: "video",
          videoCurrentTime: data.currentTime,
          videoDuration: data.duration,
          videoPercent: 25,
          videoProvider: 'MVL',
          videoStatus: 'progress',
          videoTitle: data.videoTitle,
          videoUrl: '',
        });
      } else if (data.event === "50%") {
        window.dataLayer.push({
          event: "video",
          videoCurrentTime: data.currentTime,
          videoDuration: data.duration,
          videoPercent: 50,
          videoProvider: 'MVL',
          videoStatus: 'progress',
          videoTitle: data.videoTitle,
          videoUrl: '',
        });
      } else if (data.event === "75%") {
        window.dataLayer.push({
          event: "video",
          videoCurrentTime: data.currentTime,
          videoDuration: data.duration,
          videoPercent: 75,
          videoProvider: 'MVL',
          videoStatus: 'progress',
          videoTitle: data.videoTitle,
          videoUrl: '',
        });
      } else if (data.event === "90%") {
        window.dataLayer.push({
          event: "video",
          videoCurrentTime: data.currentTime,
          videoDuration: data.duration,
          videoPercent: 90,
          videoProvider: 'MVL',
          videoStatus: 'progress',
          videoTitle: data.videoTitle,
          videoUrl: '',
        });
      }
      else if (data.event === "ended") {
        window.dataLayer.push({
          event: "video",
          videoDuration: data.duration,
          videoProvider: 'MVL',
          videoStatus: 'complete',
          videoTitle: data.videoTitle,
          videoUrl: '',
        });
      }
    });


    // Add event listener to the document
    document.addEventListener('click', handleClick);

    player.on('ended', function () {
      //console.log('Awww...over so soon?!');
      if (currentUser) {
        closeFeedbackModal();
        closeThankYouModal();
        openEndFeedbackModal();
      }      
    });

    player.on('pause', function () {
      //console.log('Awww...paused?!');
    });

    // destroy player on unmount
    return () => {
      if(currentUser) {
        saveDuration(videoDurationSeconds);
      }
      document.removeEventListener('click', handleClick);
      if (player) {
        player.dispose();
      }
    };
  }, []);


  useEffect(() => {
    setInterval(() => {
      if(currentUser && props.videoId !== '' && videoNode.current  !== null) {
        if(videoNode.current.paused === false && videoNode.current.ended === false) {
          videoDurationSeconds = videoNode.current.currentTime;
          localStorage.setItem('videoduration', videoDurationSeconds);         
        }
      }           
    }, 8000);
  }, [videoDurationSeconds]);
      
  function saveDuration(videoDurationSeconds) {
    const formData = {
      "data": {
        "type": "flagging_media_play_back",
        "attributes": {
          "entity_type": "media",
          "entity_id": props.videoId,
          "field_play_back_duration": videoDurationSeconds
        },
        "relationships": {
          "uid": {
            "data": {
              "type": "UserProfile",
              "id": currentUser.user_extra_details.uuid
            }
          }
        }
      }
    };
    saveVideoDuration(currentUser, formData)
      .then(async (res) => {
        if (res.ok) {
        }
      })
      .catch((err) => {
        return err;
      });
  }
  
  const playerOnKeyDown = (e) => {
    if (currentUser && e.keyCode === 39) {
      var currentTimeForward = videoNode.current.currentTime;
      videoNode.current.currentTime = currentTimeForward + 10;
    }
    if (currentUser && e.keyCode === 37) {
      var currentTimeBackward = videoNode.current.currentTime;
      videoNode.current.currentTime = currentTimeBackward - 10;
    }
    if (currentUser && e.keyCode === 32) {
      var currentStatus = videoNode.current.paused;
      currentStatus === false ? videoNode.current.pause() : videoNode.current.play();
      e.preventDefault();
    }    
  }  

  return (
    <div onKeyDown={playerOnKeyDown}>
      <video ref={videoNode} className="video-js vjs-fluid" data-vjs-player/>
    </div>
  )
}

export default Videoplayer