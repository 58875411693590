import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Videoplayer from "../UI/Videoplayer";
import { useSelector, useDispatch } from 'react-redux';
import { getVideoDetails, getCategoryVideos, addVideoHistory, addWatchLater, removeWatchLater, addFavourites, removeFavourites, getAuthorDetail, getEncryptVideoUrl, saveVideoDuration } from "../../lib/api";
import { useModal } from '../../store/ModalContext';
import LoadingSpinner from '../UI/LoadingSpinner';
import { updateVideoDetail } from '../../redux/videodetailSlice';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
  TwitterShareButton,
} from "react-share";
import { Helmet } from "react-helmet";

const VideoDetails = () => {
  const { EncryptionData, openSendQueryModal, openFeedbackModal, secondsToMinutes } = useModal();
  const { currentUser } = useSelector((state) => state.user);
  const { authorList } = useSelector((state)=> state.author);
  const { videoDetail } = useSelector((state)=> state.videodetail);
  const [isLoading, setIsLoading] = useState(true);
  const [videoInfo, setVideoInfo] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [relatedVideos, setRelatedVideos] = useState([]);
  const [validAuthorList, setValidAuthorList] = useState([]);
  const [invalidAuthorList, setInValidAuthorList] = useState([]);
  const [authorArray, setAuthorArray] = useState([]);
  const [videoCount, setVideoCount] = useState(0);
  const [sharePopup, setSharePopup] = useState(false);
  const [multipleAuthors, setMultipleAuthors] = useState(false);
  const [watchLaterShow, setWatchLaterShow] = useState('');
  const [favouriteShow, setFavouriteShow] = useState('');
  const [playBack, setPlayBack] = useState();
  const [mid, setmid] = useState();
  var buttonDisable = false;
  var playLimit = false;  
  const share_url = window.location.href;
  const history = useHistory();
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const sharebtnRef = useRef();
  let path_name = useParams().name;
  
  const videodetailsendRequest = useCallback(() => {
    getVideoDetails(path_name, currentUser)
      .then((response) => {
        if (response.length > 0) {
          setVideoCount(response.length);
          setVideoInfo(response[0]);
          dispatch(updateVideoDetail(response[0]));
          setmid(response[0].mid);
          setIsLoading(false); 
          if (response[0].play_back !== '') {
            setPlayBack(response[0].play_back);
          }          
          if (response[0].author !== '') {
            setAuthorArray(response[0].author.split(','));
          }
          if (response[0].watch_later === 'True') {
            setWatchLaterShow('True');
          } else {
            setWatchLaterShow('False');
          }
          if (response[0].favorite === 'True') {
            setFavouriteShow('True');
          } else {
            setFavouriteShow('False');
          }
          if (response[0].educational_content) {
            var textArray = response[0].educational_content.split(" ");
            var categoryData = '';
            if(textArray[1] === 'Management') {      
              categoryData = textArray[0];
            } else {
              categoryData = response[0].educational_content;
            }
            getEncryptVideoUrl(response[0].ext_date)
              .then((videoresponse) => {
                setVideoUrl(videoresponse.preview);               
              })
              .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });                        
            getCategoryVideos(categoryData, currentUser)
              .then((response) => {
                const newResponse = response.filter((details) => details.mid !== mid);
                setRelatedVideos(newResponse);                
              })
              .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
          } else { setIsLoading(false); }
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, [currentUser, mid])

  const addhistorysendRequest = useCallback(() => {
    addVideoHistory(
      currentUser.access_token,
      { mid: mid, uid: currentUser.current_user.uid }
    ).then(() => {
    })
      .catch((error) => {
        console.log(error);
      });
  }, [currentUser, mid])

  useEffect(() => {
    localStorage.setItem('rating', '');
    if (currentUser && mid && videoInfo.platform.includes("MVL Supporting Asset") === false) {
      addhistorysendRequest();
    } 
    if (currentUser && videoInfo) {
      if(videoInfo.platform.includes("MVL Supporting Asset") === true) {
        history.push('/');
      }       
    }       
  }, [addhistorysendRequest, currentUser]);

  useEffect(() => {
    videodetailsendRequest();
    window.addEventListener("popstate", () => {
      window.location.reload();
    });
    const closeDropdown = e => {
      if(sharebtnRef.current) {
        if (!sharebtnRef.current.contains(e.target)) {
          setSharePopup(false);
        }
      }
    }
    document.body.addEventListener('click', closeDropdown);
    return () => document.body.removeEventListener('click', closeDropdown);
  }, []);

  useEffect(() => {
    if (authorArray.length > 0) {
      authorArray.length > 1 ? setMultipleAuthors(true) : setMultipleAuthors(false);
      authorArray.map((details) => {
        if(authorList) {
          var authorData = authorList.find(author_obj => author_obj.name === details.trim());
        }        
        if(authorData) {          
          if(validAuthorList.includes(details) === false) {
          setValidAuthorList((validAuthorList) => [
            ...validAuthorList,
            details,
          ]);
          }
        } else {          
          if(invalidAuthorList.includes(details) === false) {
          setInValidAuthorList((invalidAuthorList) => [
            ...invalidAuthorList,
            details,
          ]);
          }
        }
      });
    }
  }, [authorArray, authorList, validAuthorList, invalidAuthorList]);
  
  const addToFavourites = () => {
    setIsLoading(true); 
    addFavourites(
      currentUser.access_token,
      { mid: mid, uid: currentUser.current_user.uid }
    ).then(() => {
      setFavouriteShow('True');
      setIsLoading(false); 
    })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeFromFavourites = () => {
    setIsLoading(true);
    removeFavourites(
      currentUser.access_token, mid, currentUser.current_user.uid
    ).then(() => {
      setFavouriteShow('False');
      setIsLoading(false);
    })
      .catch((error) => {
        console.log(error);
      });
  };


  const addToWatchLater = () => {
    setIsLoading(true);
    addWatchLater(
      currentUser.access_token,
      { mid: mid, uid: currentUser.current_user.uid }
    ).then(() => {
      setWatchLaterShow('True');
      setIsLoading(false);
    })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeFromWatchLater = () => {
    setIsLoading(true);
    removeWatchLater(
      currentUser.access_token, mid, currentUser.current_user.uid
    ).then(() => {
      setWatchLaterShow('False');
      setIsLoading(false);
    })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleViewVideo = (title) => {
    setIsLoading(true);
    if(currentUser) {
      var videoduration = localStorage.getItem('videoduration');
      const formData = {
        "data": {
          "type": "flagging_media_play_back",
          "attributes": {
            "entity_type": "media",
            "entity_id": videoDetail.mid,
            "field_play_back_duration": videoduration
          },
          "relationships": {
            "uid": {
              "data": {
                "type": "UserProfile",
                "id": currentUser.user_extra_details.uuid
              }
            }
          }
        }
      };
      saveVideoDuration(currentUser, formData)
        .then(async (res) => {
          if (res.ok) {
            history.push('/video-details'+title);
            window.location.reload(true);          
          }
        })
        .catch((err) => {
          history.push('/video-details'+title);
          window.location.reload(true);        
          return err;
      });
    } else {
      history.push('/video-details'+title);
      window.location.reload(true);
    }
  }

  const handlerSendQuery = (mid) => {
    openSendQueryModal(mid);
  }

  const handlerFeedback = () => {
    var rating_value = localStorage.getItem('rating');
    if (currentUser && rating_value === '') {
      openFeedbackModal();
    } else {
      history.go(-1)
    }
  }

  const handleAuthorClick = (authorName) => {
    getAuthorDetail(authorName.trim())
      .then((response) => {
        if (response.length > 0) {
          history.push('/authorDetails/' + EncryptionData(authorName.trim()));
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleSocialIcons = () => {
    setSharePopup((sharePopup) => !sharePopup); 
  }

  var socialmedia_title = "I thought you might be interested in this video from the Masters Video Library, a platform for educational content in the field of surgical ophthalmology.";
  var email_title = "I am excited to share this valuable resource from the Masters Video Library, a platform for educational content in the field of surgical ophthalmology."; 
  
  if(videoInfo) {
    buttonDisable = playLimit = (currentUser === null && videoInfo.platform.includes("MVL Supporting Asset") === false);   
  }

  const video_type = "video/mp4";
  return (
    <div className="searchResults bg-[#F4F8FD] px-5 sm:px-5 md:px-5 lg:px-14 py-4 lg:pt-9 pb-10 lg:pb-20 flex grow flex-col ">      
      {isLoading && (
        <div className="parentDisable">
          <LoadingSpinner />
        </div>
      )}
      <div className="flex items-start gap-6 flex-wrap sm:flex-wrap md:flex-wrap lg:flex-nowrap">
        <div className=" basis-full sm:basis-full md:basis-full lg:basis-[60%] xl:basisi-[65%] 2xl:basis-[70%]">
          {videoCount > 0 ? (
            <div className="videoDeatils">
            <Helmet>              
              (<title>{`MVL - ${videoInfo.title}`}</title>)
              <meta name="description" content={videoInfo.keywords} />
              <meta name="keywords" content={videoInfo.keywords} />              
              <meta property="og:title" content={videoInfo.title} />
              <meta property="og:description" content={videoInfo.keywords} />
              <meta property="og:image" content={videoInfo.thumbnail_url} />      
            </Helmet>              
              <div className="videoDetailsHeader flex items-center gap-[2px] cursor-pointer w-fit" onClick={handlerFeedback}>
                <div className="backIconHolder -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path d="M20.1201 26.5604L11.4268 17.8671C10.4001 16.8404 10.4001 15.1604 11.4268 14.1338L20.1201 5.44043" stroke="#0057B8" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
                    </path>
                  </svg>
                </div>
                <p
                  className="text-[#333]  leading-7 text-sm lg:text-xl font-bold">
                  Back
                </p>
              </div>
              <div className="videoDetailsBody mt-4 sm:mt-5">
                <div className="videosHolder bg-[#006FBA] max-h-[80vh] overflow-hidden aspect-video w-full rounded-md">
                {videoUrl && (
                  <Videoplayer src={videoUrl} type={video_type} useRef={videoRef} playLimit={playLimit}  startTime={playBack} videoId={videoInfo.mid} videoTitle={videoInfo.title} />
                )}
                </div>
              </div>
              <div className="videoDetailsFooter mt-4 sm:mt-5">
                <p className="text-[#717375] text-xs md:text-sm lg:text-sm leading-5 font-normal tracking-[0.035px] capitalize">
                  {videoInfo.educational_content}
                </p>
                <p className="text-[#333] barlowBold leading-[18px] md:leading-8 font-bold text-sm sm:text-xl lg:text-2xl">
                  {videoInfo.title}
                </p>
                <div className="flex flex-col gap-4 flex-wrap mt-1">
                  <div className="channelInfo">
                    <div className="flex gap-3 items-center">
                      <div className="doctorsName flex flex-wrap items-center gap-x-2 gap-y-0">
                        {validAuthorList.map((details, index) =>
                        <span key={index} className="flex gap-x-2 gap-y-0 items-center">
                          <p className="text-primaryText underline docorName font-medium sm:leading-6 leading-5 text-xs sm:text-sm cursor-pointer" key={index} onClick={() => handleAuthorClick(details)}>{details}</p><span className="text-[#717375] pipe">{multipleAuthors ? "|":""} </span>
                        </span>
                        )}
                        {invalidAuthorList.map((details, index) =>
                        <span key={index} className="flex gap-x-2 gap-y-0 items-center">
                          <p className="text-black docorName font-medium sm:leading-6 leading-5 text-xs sm:text-sm" key={index} >{details}</p><span className="text-[#717375] pipe">{multipleAuthors ? "|":""} </span>
                        </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="videoActions">
                    <div className="flex gap-2 sm:gap-4  flex-wrap items-stretch">
                      <div className="shareOptionsHolder relative" ref={sharebtnRef}>
                        <button className="shareBtn flex gap-2 items-center bg-[#E9EDF2] rounded px-1 sm:px-3 py-[6px]" onClick={handleSocialIcons}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                            >
                              <path
                                d="M13.8878 6.08635L8.91365 1.11225C8.8601 1.05865 8.79186 1.02215 8.71755 1.00736C8.64324 0.992575 8.56622 1.00017 8.49623 1.02918C8.42624 1.0582 8.36644 1.10733 8.32439 1.17035C8.28234 1.23337 8.25993 1.30746 8.26001 1.38322V3.64431C6.92141 3.55471 5.58534 3.85271 4.41168 4.50264C3.23802 5.15258 2.2765 6.12693 1.64216 7.30909C1.00783 8.49125 0.727552 9.83115 0.834879 11.1685C0.942206 12.5058 1.43259 13.7838 2.24734 14.8497C2.29766 14.9155 2.36797 14.9632 2.44772 14.9857C2.52747 15.0081 2.61235 15.0041 2.68962 14.9742C2.76689 14.9443 2.83237 14.8902 2.87624 14.8199C2.9201 14.7496 2.93998 14.667 2.93289 14.5844C2.87026 13.863 2.96643 13.1367 3.21465 12.4564C3.46287 11.7762 3.85711 11.1586 4.36964 10.6471C5.49868 9.51804 6.59671 9.24707 8.26001 9.21515V11.3311C8.25993 11.4069 8.28234 11.481 8.32439 11.544C8.36644 11.607 8.42624 11.6561 8.49623 11.6852C8.56622 11.7142 8.64324 11.7218 8.71755 11.707C8.79186 11.6922 8.8601 11.6557 8.91365 11.6021L13.8878 6.62769C13.9234 6.59214 13.9517 6.54991 13.971 6.50341C13.9903 6.45691 14.0002 6.40706 14.0002 6.35672C14.0002 6.30638 13.9903 6.25653 13.971 6.21003C13.9517 6.16353 13.9234 6.1219 13.8878 6.08635ZM9.02535 10.4068V8.82887C9.02535 8.72734 8.98502 8.62997 8.91323 8.55817C8.84143 8.48638 8.74406 8.44605 8.64253 8.44605C6.91645 8.44605 5.36259 8.571 3.828 10.1057C2.95243 10.9797 2.38377 12.114 2.2073 13.3384C1.74496 12.3913 1.53099 11.3422 1.58549 10.2896C1.63998 9.23709 1.96115 8.21568 2.51883 7.32136C3.0765 6.42704 3.85238 5.68917 4.77355 5.17707C5.69473 4.66497 6.73096 4.39546 7.78491 4.39385C8.05532 4.39392 8.32545 4.41142 8.59361 4.44623C8.64759 4.45326 8.70245 4.44868 8.75452 4.43282C8.8066 4.41695 8.85469 4.39015 8.89558 4.35422C8.93647 4.31828 8.96923 4.27404 8.99166 4.22443C9.01409 4.17483 9.02568 4.12101 9.02565 4.06657V2.30708L13.0751 6.35657L9.02535 10.4068Z"
                                fill="#0F2C4D"
                                stroke="#0F2C4D"
                                strokeWidth="0.3"
                              />
                            </svg>
                          </span>
                          <span className="text-[#0F0F0F] font-medium text-xs sm:text-sm">
                            Share
                          </span>
                          <span className="dropdownIcon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M7.4 7.9999L6 9.3999L12 15.3999L18 9.3999L16.6 7.9999L12 12.5999L7.4 7.9999Z" fill="#718BAE" />
                            </svg>
                          </span>
                        </button>
                        {sharePopup && (
                          <div className="socialMediaIcons flex absolute top-full mt-2 shadow-profileMenuShadow p-3 gap-3 w-auto rounded-lg left-0 bg-white">
                            <FacebookShareButton url={share_url} title={socialmedia_title} className="Demo__some-network__share-button">
                              <FacebookIcon size={24} round />
                            </FacebookShareButton>
                            <EmailShareButton url={share_url} subject="Expand Your Expertise: New Video from Masters Video Library" body={email_title} className="Demo__some-network__share-button">
                              <EmailIcon size={24} round />
                            </EmailShareButton>
                            <WhatsappShareButton url={share_url} title={socialmedia_title} separator=":: " className="Demo__some-network__share-button"
                            >
                              <WhatsappIcon size={24} round />
                            </WhatsappShareButton>
                            <TwitterShareButton
                              url={share_url}
                              title={socialmedia_title}
                              className="Demo__some-network__share-button"
                            >
                              <XIcon size={24} round />
                            </TwitterShareButton>
                          </div>
                        )}
                      </div>
                      {currentUser && <>
                        {favouriteShow === 'False' ? (
                          <button className="addToFavBtn flex gap-2 items-center bg-[#E9EDF2] rounded px-1 sm:px-3 py-[6px]" disabled={buttonDisable} onClick={addToFavourites}>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="14"
                                viewBox="0 0 16 14"
                                fill="none"
                              >
                                <path
                                  d="M8.434 13.167C8.196 13.251 7.804 13.251 7.566 13.167C5.536 12.474 1 9.583 1 4.683C1 2.52 2.743 0.770004 4.892 0.770004C6.166 0.770004 7.293 1.386 8 2.338C8.707 1.386 9.841 0.770004 11.108 0.770004C13.257 0.770004 15 2.52 15 4.683C15 9.583 10.464 12.474 8.434 13.167Z"
                                  stroke="#0F2C4D"
                                  strokeWidth="1.2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                            <span className="text-[#0F0F0F] font-medium text-xs sm:text-sm">
                              Add to favorites
                            </span>
                          </button>
                        ) : (
                          <button className="addToFavBtn flex gap-2 items-center bg-[#E9EDF2] rounded px-1 sm:px-3 py-[6px]" disabled={buttonDisable} onClick={removeFromFavourites}>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="14"
                                viewBox="0 0 16 14"
                                fill="#0057B8"
                              >
                                <path
                                  d="M8.434 13.167C8.196 13.251 7.804 13.251 7.566 13.167C5.536 12.474 1 9.583 1 4.683C1 2.52 2.743 0.770004 4.892 0.770004C6.166 0.770004 7.293 1.386 8 2.338C8.707 1.386 9.841 0.770004 11.108 0.770004C13.257 0.770004 15 2.52 15 4.683C15 9.583 10.464 12.474 8.434 13.167Z"
                                  stroke="#0F2C4D"
                                  strokeWidth="1.2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                            <span className="text-[#0F0F0F] font-medium text-xs sm:text-sm">
                              Remove favorites
                            </span>
                          </button>
                        )}
                        {watchLaterShow === 'False' ? (
                          <button className="watchLaterBtn flex gap-2 items-center bg-[#E9EDF2] rounded px-1 sm:px-3 py-[6px]" disabled={buttonDisable} onClick={addToWatchLater}>
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M8 8H7.4C7.4 8.25826 7.56526 8.48754 7.81026 8.56921L8 8ZM8.6 4.5C8.6 4.16863 8.33137 3.9 8 3.9C7.66863 3.9 7.4 4.16863 7.4 4.5H8.6ZM9.91026 9.26921C10.2246 9.374 10.5644 9.2041 10.6692 8.88974C10.774 8.57537 10.6041 8.23558 10.2897 8.13079L9.91026 9.26921ZM8.6 8V4.5H7.4V8H8.6ZM7.81026 8.56921L9.91026 9.26921L10.2897 8.13079L8.18974 7.43079L7.81026 8.56921ZM14.4 8C14.4 11.5346 11.5346 14.4 8 14.4V15.6C12.1974 15.6 15.6 12.1974 15.6 8H14.4ZM8 14.4C4.46538 14.4 1.6 11.5346 1.6 8H0.4C0.4 12.1974 3.80264 15.6 8 15.6V14.4ZM1.6 8C1.6 4.46538 4.46538 1.6 8 1.6V0.4C3.80264 0.4 0.4 3.80264 0.4 8H1.6ZM8 1.6C11.5346 1.6 14.4 4.46538 14.4 8H15.6C15.6 3.80264 12.1974 0.4 8 0.4V1.6Z"
                                  fill="#0F2C4D"
                                />
                              </svg>
                            </span>
                            <span className="text-[#0F0F0F] font-medium text-xs sm:text-sm">
                            Watch later
                            </span>
                          </button>
                        ) : (
                          <button className="watchLaterBtn flex gap-2 items-center bg-[#E9EDF2] rounded px-1 sm:px-3 py-[6px]" disabled={buttonDisable} onClick={removeFromWatchLater}>
                            <span>
                              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.35 15.57C16.21 15.81 15.96 15.94 15.7 15.94C15.57 15.94 15.44 15.91 15.32 15.83L12.22 13.98C11.45 13.52 10.88 12.51 10.88 11.62V7.52C10.88 7.11 11.22 6.77 11.63 6.77C12.04 6.77 12.38 7.11 12.38 7.52V11.62C12.38 11.98 12.68 12.51 12.99 12.69L16.09 14.54C16.45 14.75 16.57 15.21 16.35 15.57Z" fill="#0057B8" />
                              </svg>
                            </span>
                            <span className="text-[#0F0F0F] font-medium text-xs sm:text-sm">
                              Remove
                            </span>
                          </button>
                        )}
                      </>}
                    </div>
                  </div>
                </div>
                <div className="videoDesc mt-4 lg:mt-6 hidden">
                  <p className="text-[#0F2C4D] text-xs sm:text-sm lg:text-base line-clamp-2 sm:line-clamp-none">
                    {videoInfo.description}
                    <span className="text-[#0F2C4D] font-bold cursor-pointer hidden">
                      ...more
                    </span>
                  </p>
                </div>
                <div className="sendQuery mt-4 lg:mt-6">
                  <div className="bg-[#0000000d] rounded-xl p-[10px] lg:px-3 lg:py-4">
                    <div className="flex flex-col gap-2 lg:gap-3">
                      <p className="text-[#0F2C4D] font-normal text-xs sm:text-sm lg:text-base leading-4 sm:leading-5 md:leading-5 max-w-full sm:max-w-[70%]">
                        Would you like to discuss or share feedback on this video?
                        Drop a message, and we will get back to you with more
                        information.
                      </p>
                      <p className="text-[#0057B8] text-xs sm:text-sm lg:text-base font-bold leading-5 cursor-pointer" onClick={() => handlerSendQuery(videoInfo.mid)}>
                        Send Feedback
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (<div className="videoDeatils"></div>)}
        </div>
        <div className="basis-full sm:basis-full md:basis-full lg:basis-[40%] xl:basis-[35%] 2xl:basis-[30%] grow">
          {relatedVideos.length > 0 && <div className="relatedVideoDeatils">
            <h2 className="relateedVideosTitle text-[#333] leading-6 lg:text-xl text-[18px] barlowBold">
              Related Videos
            </h2>
            <div className="relatedVideos mt-6" >
              <div className="videos flex flex-col gap-6 max-h-full lg:max-h-screen lg:overflow-auto pr-0 md:pr-8">
                {relatedVideos.map((details) =>
                  <div className="video shadow-cardShadow sm:shadow-none rounded-lg cursor-pointer" key={details.mid} onClick={() => handleViewVideo(details.slug_url)}>
                    <div className="flex gap-0 sm:gap-6 items-start flex-wrap sm:flex-nowrap">
                      <div className="videoThumbNail basis-full sm:basis-2/4 md:basis-1/4 lg:basis-1/2 shrink-0">
                        <div className="thumbnail h-[160px]  md:h-[130px] relative">
                          <img
                            src={details.thumbnail_url}
                            className="w-full h-full rounded-lg object-cover rounded-bl-none rounded-br-none sm:rounded-bl-lg sm:rounded-br-lg"
                            alt="thumbnail"
                          />
                        {details.duration && details.duration > 0 ?
                        <p className="duration  bg-videoDurationBg text-white text-xs font-normal leading-4 rounded px-2 py-1 absolute right-4 bottom-4">{secondsToMinutes(details.duration)}</p>
                        : <></>}
                        </div>
                      </div>
                      <div className="videoInfo basis-full sm:basis-2/4 md:basis-3/4 lg:basis-1/2">
                        <div className="description flex flex-col gap-2 p-4 sm:p-0">
                          <p className="videoCaption text-videoCaption  leading-5 lg:leading-5 barlowBold  text-base lg:text-sm line-clamp-2">
                            {details.title}
                          </p>
                          <p className="videoCatagory  text-videoCatagory text-xs lg:text-sm leading-5 font-medium tracking-[0.035px] line-clamp-2 break-all capitalize">
                            {details.educational_content}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>)}
              </div>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default VideoDetails;