import Modal from "../UI/Modal";
import Button from "../UI/Button";
import { useModal } from '../../store/ModalContext';
import { useHistory } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import useInput from "../../hooks/use-input";
import { videoRating, saveVideoDuration } from "../../lib/api";
import { isComments } from "../UI/Validations";

const Feedback = (props) => {
  const { openThankYouModal } = useModal();
  const history = useHistory();
  const commentsInputRef = useRef();
  const { currentUser } = useSelector((state) => state.user);
  const { videoDetail } = useSelector((state)=> state.videodetail);
  const [starRating, setStarRating] = useState('');
  
  const {
    value: commentsValue,
    valueChangeHandler: commentsChangeHandler,
    inputBlurHandler: commentsBlurHandler,
  } = useInput(isComments);  

  let formIsValid = false;

  if (starRating > 0
  ) {
    formIsValid = true
  }

  const goBack = () => {
    var videoduration = localStorage.getItem('videoduration');
    const formData = {
      "data": {
        "type": "flagging_media_play_back",
        "attributes": {
          "entity_type": "media",
          "entity_id": videoDetail.mid,
          "field_play_back_duration": videoduration
        },
        "relationships": {
          "uid": {
            "data": {
              "type": "UserProfile",
              "id": currentUser.user_extra_details.uuid
            }
          }
        }
      }
    };
    saveVideoDuration(currentUser, formData)
      .then(async (res) => {
        props.onClose();
        history.go(-1); 
      })
      .catch((err) => {
        props.onClose();
        history.go(-1);         
        return err;
      });    
 
  }

  const handlerRequestSubmitted = (event) => {
    event.preventDefault();
    
    if (!formIsValid) {
      return;
    }

    const enteredComments = commentsInputRef.current.value;

    const formData = {
      entity_id: [{
        target_id: videoDetail.mid,
      }],
      entity_type: [{
        value: "media",
      }],
      comment_type: [{
        target_id: "media_rating",
      }],
      uid: [{
        target_id: currentUser.current_user.uid,
      }],
      field_name: [{
        value: "field_rating",
      }],
      field_rating: [{
        value: starRating,
      }],
      subject: [{
        value: "Media Rating",
      }],
      comment_body: [{
        value: enteredComments,
        format: "plain_text"
      }]
    };

    videoRating(currentUser, formData)
      .then(async (res) => {
        if (res.ok) {          
          props.onClose();
          openThankYouModal();          
          return res.json();
        }
      })
      .catch((err) => {
        props.onClose();
        openThankYouModal();        
        return err;
    });

  }

  const handleRating = (rate) => {
    setStarRating(rate);
  }
  // const onPointerEnter = () => console.log()
  // const onPointerLeave = () => console.log()
  // const onPointerMove = (value, index) => console.log()

  return (
    <Modal>
      <div className="loginHolder h-full flex grow mx-0 sm:mx-0 md:mx-4">
        <div className="flex items-stretch justify-normal  rounded-lg max-w-full sm:max-w-[480px] max-h-full sm:max-h-full h-full sm:max-h-auto sm:h-auto m-auto bg-white relative grow">
          {/* {isLoading && (
              <div className="parentDisable">
                <LoadingSpinner />
              </div>
            )} */}
          <div className="loginFormHolder grow rounded-none sm:rounded-lg flex justify-center items-center shrink sm:shrink-0 md:shrink-0 bg-[#F4F8FD] sm:bg-[#F4F8FD] md:bg-white landscape:overflow-auto basis-full sm:basis-3/4 md:basis-1/2 lg:basis-[40%]">
            <div className="p-10 w-full loginSection">
              <div className="flex justify-between gap-4 items-center">
                <h2 className="modalTitle text-[#354665] barlowBold text-xl">
                  Rate your Experience
                </h2>
                <div
                  className="closeIconHolder absolute sm:static left-10 sm:right-5 sm:left-auto top-5 cursor-pointer sm:landscape: right-10"
                  onClick={props.onClose}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z"
                      fill="#717375"
                      stroke="#717375"
                    />
                    <path
                      d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z"
                      fill="#717375"
                      stroke="#717375"
                    />
                  </svg>
                </div>
              </div>
              <form className="loginForm mt-6" onSubmit={handlerRequestSubmitted}>
                <div className="space-y-6">
                  <div className="ratingsHolder">
                    <Rating
                      transition
                      onClick={handleRating}
                      SVGstyle={{ display: 'inline-block' }}
                    />
                  </div>
                  <p className="text-[#354665] text-sm sm:text-base leading-6 font-normal tracking-[0.5px]">
                    We highly value your feedback! Kindly take a moment to rate your experience and provide us with your valuable feedback.
                  </p>
                  <div className="commentsSection gap-[6px] flex flex-col">
                    <label className="titleLabel text-[#333] text-sm font-normal leading-5">Comments (optional)</label>
                    <textarea id="message" className="p-[10px] rounded border border-loginFieldBorder text-[#666]  placeholder:text-[#666] text-sm " placeholder="Enter Your Comments Here...." rows="4" cols="30" onBlur={commentsBlurHandler} onChange={commentsChangeHandler} ref={commentsInputRef} value={commentsValue}></textarea>
                  </div>
                  <Button
                    disabled={!formIsValid}
                    type="submit"
                    className={!formIsValid ? "btn mt-8 submitBtn w-full bg-primaryBlue h-[46px] px-20 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center disabled mb-0 md:mb-10" : "btn mt-8 continueBtn w-full bg-primaryBlue h-[46px] px-20 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center cursor-pointer mb-0 md:mb-10"}
                  >
                  Submit
                  </Button>
                  <Button
                    className="submitBtn w-full text-primaryBlue font-bold leading-5 text-base  justify-center items-center rounded text-center cursor-pointer flex" onClick={goBack}
                  >
                    Skip Rating
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Feedback;