import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notificationList: null,
    unReadNotification: null
};

export const notificationSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        updateNotificationList: (state, action) => {
            state.loading = false;
            state.notificationList = action.payload;
        },
        updateUnReadCount: (state, action) => {
            state.loading = false;
            state.unReadNotification = action.payload;
        }
    },
});

export const { updateNotificationList, updateUnReadCount } = notificationSlice.actions;

export default notificationSlice.reducer;