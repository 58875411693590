import { useState, useRef, useEffect } from 'react';
import Input from '../UI/Input';
import useInput from '../../hooks/use-input';
import Modal from '../UI/Modal';
import Button from '../UI/Button';
import { getVideoSubmit } from '../../lib/api';
import contactus_video from "../../assets/videos/Email.gif";
import { useModal } from '../../store/ModalContext';
import Dropdown from '../UI/Dropdown';

const isNotEmpty = (value) => value.trim() !== '';
const isEmail = (value) => value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

const VideoSubmitForm = (props) => {

  const [needHelpOptionsList, setNeedHelpOptionsList] = useState([]);
  const [selectedNeedHelpOption, setSelectedNeedHelpOption] = useState(null);
  const [isNeedHelpValid, setNeedHelpValid] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [unCheckedError, setUnCheckedError] = useState('')  
  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();  
  const emailInputRef = useRef();  
  const { closeVideoSubmitModal } = useModal();

  const {
    value: firstNameValue,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
  } = useInput(isNotEmpty);

  const {
    value: lastNameValue,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
  } = useInput(isNotEmpty);

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(isEmail);

  
  let formIsValid = false;

  if (
    firstNameIsValid &&
    lastNameIsValid &&
    emailIsValid &&
    isNeedHelpValid
  ) {
    formIsValid = true
  }

  const handleNeedHelpOption = (option) => {
    setSelectedNeedHelpOption(option);
  };

  const handleBlur = (dropdownNumber, isValid) => {
    if (dropdownNumber === 1) {
        setNeedHelpValid(isValid);
    }
  };
  
  const submitHandler = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    if (!isChecked) {
      setUnCheckedError('Please check the checkbox to proceed further.')
      return
    }
  }  

  useEffect(() => {        
      videosubmitsendRequest();
  }, [])

  const videosubmitsendRequest = () => {        
    getVideoSubmit()
      .then((response) => {
        const options = response.map((details) => ({
            id: details.tid,
            value: details.name,
        }));        
        setNeedHelpOptionsList(options);        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeContactUs = () => {
    closeVideoSubmitModal();
  }

  return (
    <Modal>
      <div className="appWrapper h-screen landscape:h-screen sm:h-screen sm:landscape:h-screen md:landscape:h-screen w-full">
        <div className="supportFormHolder h-full flex justify-center">
        <div className="formHolder lg:max-w-[1162px] max-h-full rounded-none lg:rounded-lg sm:max-h-full h-full md:max-h-[700px] md-h-[700px] bg-white flex items-center justify-center m-auto overflow-auto w-full mx-0 lg:mx-4 xl:mx-0">
        <div className="px-10 sm:px-10 md:px-10 lg:px-14 w-full h-full py-10 flex">
            <div className="flex items-stretch flex-col sm:flex-row w-full">
                <div className="videoIconHolder basis-4/12 aspect-square self-center hidden lg:flex">
                    <img src={contactus_video} className="w-full h-full rounded-tl-lg rounded-tr-lg object-cover" alt='email-icon'/>
                </div>
                <div className="bar w-[1px] bg-[#7DA1C4] opacity-60 ml-[55px] mr-[46px] lg:flex hidden"></div>
                <div className="contactUsFormHolder basis-full lg:basis-8/12 h-full">
                    <div className="flex flex-col h-full">
                        <div className="formHeader">
                            <div className="flex justify-between items-center">
                                <h2 className="text-[#354665] barlowBold text-xl sm:text-2xl leading-normal uppercase">Contact Us For Video Submit</h2>
                                <div className="closeIconHolder cursor-pointer" onClick={closeContactUs}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect width="24" height="24" fill="white"/>
                                        <path d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z" fill="#717375" stroke="#717375"/>
                                        <path d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z" fill="#717375" stroke="#717375"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="contactUsForm sm:pt-8 pt-6 h-full">
                            <form className="flex flex-col justify-between h-full" onSubmit={submitHandler}>
                                <div className="space-y-6 sm:space-y-6">
                                    <div className="nameSection gap-x-8 flex flex-wrap gap-y-6 *:grow *:shrink">
                                    <div className="flex flex-col gap-[6px]">
                                        <Input
                                            ref={firstNameInputRef}
                                            label="First Name"
                                            labelClass="firstNameLabel text-[#333] text-sm font-normal leading-5"
                                            isManditory={true}
                                            input={{
                                            id: "firstName",
                                            name: "firstName",
                                            type: "text",
                                            value: firstNameValue,
                                            placeholder: "Enter first name",
                                            onChange: firstNameChangeHandler,
                                            onBlur: firstNameBlurHandler,
                                            className:
                                                "p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm placeholder:text-[#666]  !text-[#333]",
                                            }}
                                        />
                                        {firstNameHasError && (
                                            <p className="error-text">
                                            Please enter your first name.
                                            </p>
                                        )}                                        
                                    </div>
                                    <div className="flex flex-col gap-[6px]">
                                        <Input
                                            ref={lastNameInputRef}
                                            label="Last Name"
                                            labelClass="lastNameLabel text-[#333] text-sm font-normal leading-5"
                                            isManditory={true}
                                            input={{
                                            id: "lastName",
                                            name: "lastName",
                                            type: "text",
                                            value: lastNameValue,
                                            placeholder: "Enter last name",
                                            onChange: lastNameChangeHandler,
                                            onBlur: lastNameBlurHandler,
                                            className:
                                                "p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm placeholder:text-[#666]  !text-[#333]",
                                            }}
                                        />
                                        {lastNameHasError && (
                                            <p className="error-text">
                                            Please enter your last name.
                                            </p>
                                        )}                                        
                                    </div>
                                    </div>
                                    <div className="emailSection gap-[6px] flex flex-col relative">
                                        <Input
                                            ref={emailInputRef}
                                            label="Email"
                                            labelClass="emailNameLabel text-[#333] text-sm font-normal leading-5"
                                            isManditory={true}
                                            input={{
                                                id: "email",
                                                name: "email",
                                                type: "email",
                                                value: emailValue,
                                                placeholder: "Enter your email",
                                                onChange: emailChangeHandler,
                                                onBlur: emailBlurHandler,
                                                className:
                                                "p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm placeholder:text-[#666]  !text-[#333]",
                                            }}
                                        />
                                        {emailHasError && (
                                            <p className="error-text">
                                            Please enter your valid email.
                                            </p>
                                        )}                                        
                                    </div>
                                    <div className="interestedSection gap-[6px] flex flex-col relative">
                                        <Dropdown
                                            label="Video Category Type"
                                            labelClass="professtionLabel text-[#333] text-sm font-normal leading-5"
                                            placeholder="Select an option"
                                            isManditory={true}
                                            options={needHelpOptionsList}
                                            onSelect={handleNeedHelpOption}
                                            onBlur={(isValid) => handleBlur(1, isValid)}
                                            isValid={isNeedHelpValid}
                                            selectedValue={selectedNeedHelpOption}
                                        />
                                        {!isNeedHelpValid && (
                                            <p className="error-text">
                                            Please select your interest.
                                            </p>
                                        )}                                                                          
                                    </div>
                                    <div className="commentsSection gap-[6px] flex flex-col">
                                        <label className="titleLabel text-[#333] text-sm font-normal leading-5">Comments</label>
                                        <textarea className="p-[10px] rounded border border-loginFieldBorder text-[#666]  placeholder:text-[#666] text-sm " placeholder="Enter your comments here...." rows="4" cols="50"></textarea>
                                    </div>
                                    <div className="agreeSection !mt-7">
                                        <div className="flex gap-x-5 gap-y-6 flex-wrap xl:flex-nowrap sm:justify-between">
                                            <div className="flex gap-3 items-center">
                                            <input
                                                type="checkbox"
                                                className="h-6 w-6 rounded-lg shrink-0 border-2 border-loginFieldBorder"
                                                onChange={(event) => setIsChecked(event.target.checked)} />                                                
                                                <p className="text-[#333] leading-normal font-normal text-base">I agree to the Data Usage Consent</p>
                                            </div>                                            
                                        </div>
                                        {!isChecked && (<p className="error-text mt-1">{unCheckedError}</p>)}
                                    </div>
                                </div>
                                <Button
                                    disabled={!formIsValid}
                                    type="submit"
                                    className={!formIsValid ? "btn mt-8 submitBtn w-auto bg-primaryBlue h-[46px] px-20 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center disabled" : "btn mt-8 continueBtn w-auto bg-primaryBlue h-[46px] px-20 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center cursor-pointer"}
                                    >
                                    Submit
                                </Button>                               
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
        </div>
    </Modal>
  );
};

export default VideoSubmitForm;
