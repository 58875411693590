import { NavLink } from 'react-router-dom';
const NotFound = () => {
  return (
    <div className='notFoundHolder  px-5 sm:px-5 md:px-5 lg:px-14 py-8 sm:py-8 md:py-8 lg:py-12 flex grow items-center justify-center'>
        <div className="wrapper text-center">
            <div className="notFoundCodeHolder mx-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="248" height="110" viewBox="0 0 248 110" fill="none" className="w-[180px] sm:w-fit mx-auto">
                <path d="M77.4902 70.0928V84.1553H0.585938L0 73.5352L45.9961 1.46484H60.1318L44.8242 27.6855L18.3838 70.0928H77.4902ZM64.1602 1.46484V108.105H46.5088V1.46484H64.1602Z" fill="#D1DDE6"/>
                <path d="M158.539 45.8496V63.2812C158.539 71.6309 157.709 78.7598 156.049 84.668C154.438 90.5273 152.094 95.2881 149.018 98.9502C145.941 102.612 142.255 105.298 137.958 107.007C133.71 108.716 128.949 109.57 123.676 109.57C119.477 109.57 115.57 109.033 111.957 107.959C108.393 106.885 105.17 105.2 102.289 102.905C99.4082 100.61 96.9424 97.6562 94.8916 94.043C92.8896 90.3809 91.3271 86.0107 90.2041 80.9326C89.1299 75.8545 88.5928 69.9707 88.5928 63.2812V45.8496C88.5928 37.4512 89.4229 30.3711 91.083 24.6094C92.7432 18.7988 95.1113 14.0869 98.1875 10.4736C101.264 6.81152 104.926 4.15039 109.174 2.49023C113.471 0.830078 118.256 0 123.529 0C127.777 0 131.684 0.537109 135.248 1.61133C138.861 2.63672 142.084 4.27246 144.916 6.51855C147.797 8.76465 150.238 11.6943 152.24 15.3076C154.291 18.8721 155.854 23.1934 156.928 28.2715C158.002 33.3008 158.539 39.1602 158.539 45.8496ZM140.888 65.7715V43.2129C140.888 38.9648 140.644 35.2295 140.155 32.0068C139.667 28.7354 138.935 25.9766 137.958 23.7305C137.03 21.4355 135.858 19.5801 134.442 18.1641C133.026 16.6992 131.415 15.6494 129.608 15.0146C127.802 14.3311 125.775 13.9893 123.529 13.9893C120.795 13.9893 118.354 14.5264 116.205 15.6006C114.057 16.626 112.25 18.2861 110.785 20.5811C109.32 22.876 108.197 25.9033 107.416 29.6631C106.684 33.374 106.317 37.8906 106.317 43.2129V65.7715C106.317 70.0684 106.562 73.8525 107.05 77.124C107.538 80.3955 108.271 83.2031 109.247 85.5469C110.224 87.8418 111.396 89.7461 112.763 91.2598C114.179 92.7246 115.79 93.7988 117.597 94.4824C119.452 95.166 121.479 95.5078 123.676 95.5078C126.459 95.5078 128.925 94.9707 131.073 93.8965C133.222 92.8223 135.028 91.1133 136.493 88.7695C137.958 86.377 139.057 83.2764 139.789 79.4678C140.521 75.6592 140.888 71.0938 140.888 65.7715Z" fill="#D1DDE6"/>
                <path d="M247.498 70.0928V84.1553H170.594L170.008 73.5352L216.004 1.46484H230.14L214.832 27.6855L188.392 70.0928H247.498ZM234.168 1.46484V108.105H216.517V1.46484H234.168Z" fill="#D1DDE6"/>
              </svg>
            </div>
            <p className="mt-4 text-[#354665] barlowMedium text-xl leading-7 sm:text-3xl sm:leading-9 tracking-[-1px]">
              Page not found
            </p>
            <p className="mt-7 sm:mt-9 text-[#354665] text-sm leading-5 sm:text-base sm:leading-6 font-medium max-[420px]:max-w-[80%] sm:max-w-[60%] mx-auto">The link you clicked may be broken or the page may have been removed or renamed.</p>
            <NavLink to="/">
            <button className="btn mt-8 backToHomeBtn w-fit bg-primaryBlue h-[46px] px-5 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center" type="button">Back to Home</button>
            </NavLink>
        </div>
    </div>
  );
};

export default NotFound;
