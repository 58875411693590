import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getNotification, updateNotificationRead } from '../../lib/api'
import { updateNotificationList, updateUnReadCount } from '../../redux/notificationSlice';
import { useModal } from '../../store/ModalContext';

const Notification = ({ isToggle, notificationRef, handleShowFromNotification }) => {

  const history = useHistory();

  const { EncryptionData, DecryptionData } = useModal();

  const dispatch = useDispatch()

  const { notificationid } = useParams();

  const { currentUser } = useSelector((state) => state.user);
  const { notificationList } = useSelector((state) => state.notification);

  const [readNotificationId, setReadNotificationId] = useState(null);

  const luminance = {
    maskType: "luminance"
  };

  const getNotificationList = useCallback(() => {
    getNotification(currentUser)
      .then((response) => {
        dispatch(updateNotificationList(response));
        const unreadNotifications = response.filter(notification => notification.isread === "False").length;
        dispatch(updateUnReadCount(unreadNotifications))
      })
      .catch((error) => {
        console.log(error);
      })
  }, [currentUser, dispatch])

  const handleReadNotification = (id, isread) => {
    setReadNotificationId(id);
    const formData = {
      "data": {
        "type": "flagging_contact_message_read_status",
        "attributes": {
          "entity_type": "contact_message",
          "entity_id": id,
        },
        "relationships": {
          "uid": {
            "data": {
              "type": "UserProfile",
              "id": currentUser.user_extra_details.uuid
            }
          }
        }
      }
    };
    if (isread === "False") {
      updateNotificationRead(currentUser, formData)
        .then(async (res) => {
          console.log(res);
          if (res.ok) {
            getNotificationList()
          }
        })
        .catch((err) => {
          return err;
        });
    }

    if (window.innerWidth < 640) {
      handleShowFromNotification(true)
    }
    isToggle()
    history.push('/notificationDetails/' + EncryptionData(id));
  }

  const formatDate = (string) => {
    const trimmedDate = parseInt(string);

    const date = new Date(trimmedDate * 1000);

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const formattedDate = `${day} ${months[monthIndex]} ${year}`;
    return formattedDate
  }

  useEffect(()=>{
    if (notificationid){setReadNotificationId(DecryptionData(notificationid))}
  },[DecryptionData, notificationid])

  return (
    <div ref={notificationRef} className={"notificationHolder top-0 fixed right-0  z-50 bg-white shadow-activeShadow h-[100vh] overflow-y-auto w-[400px]"}>
      <div className="px-4 py-5">
        <div className="notificationHeader flex justify-between items-center">
          <h2 className="notificationTitle text-[#333] sm:text-[28px] barlowBold leading-5 text-xl sm:leading-9">
            Notifications
          </h2>
          <div className="closeIconHolder cursor-pointer" onClick={isToggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z"
                fill="#717375"
                stroke="#717375"
              />
              <path
                d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z"
                fill="#717375"
                stroke="#717375"
              />
            </svg>
          </div>
        </div>
        {notificationList && notificationList && <div className="notificationBody mt-5">
          {notificationList.map((details, key) => <div className={`notificationItem cursor-pointer ${readNotificationId === details.nid ?'active': ''}`} key={key}>
            <div className="flex items-center gap-4" onClick={() => handleReadNotification(details.nid, details.isread)}>
              <div className="updateMsgIcon">
                <svg
                  width="32"
                  height="34"
                  viewBox="0 0 32 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2523_10195)">
                    <circle cx="16" cy="18" r="16" fill="white" />
                    <mask
                      id="mask0_2523_10195"
                      style={luminance}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="2"
                      width="32"
                      height="32"
                    >
                      <circle cx="16" cy="18" r="16" fill="white" />
                    </mask>
                    <g mask="url(#mask0_2523_10195)">
                      <g opacity="0.2">
                        <circle
                          cx="16"
                          cy="18"
                          r="16"
                          fill="#AFCAE5"
                        />
                      </g>
                    </g>
                    {details.isread === "False" && <circle
                      cx="24.5"
                      cy="4.5"
                      r="3.5"
                      fill="#FC5C65"
                      stroke="white"
                      strokeWidth="2"
                    />}
                    <path
                      d="M26 18C26 12.5 21.5 8 16 8C10.5 8 6 12.5 6 18C6 23.5 10.5 28 16 28C21.5 28 26 23.5 26 18Z"
                      stroke="#717375"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16 14V19"
                      stroke="#717375"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.9946 22H16.0036"
                      stroke="#717375"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2523_10195">
                      <rect width="32" height="34" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="msg unReadMsg flex flex-col gap-0 border-b border-[#E4E8F0] pb-6 pt-3 grow">
                <p className={`text-[#333] leading-[22px] text-sm sm:text-base ${details.isread === "True" ? '':'font-bold'}`}>
                  {details.subject}
                </p>
                <p className="msgDateTime text-[#666] text-xs font-normal leading-[22px]">
                  {formatDate(details.created)}
                </p>
              </div>
            </div>
          </div>)}
        </div>}
      </div>
    </div>

  )
}

export default Notification;