import { useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import Button from '../UI/Button';
import LoadingSpinner from '../UI/LoadingSpinner';
import { getUserActivation } from "../../lib/api";

const UserActivation = () => {

  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');

  const goBackHome = () => {
    history.push(`/`);
    window.scrollTo(0, 0);
  }

  const useractivationsendRequest = useCallback(() => {
    let path = location.pathname.split("/");
    let user_id = path[2].split("_");
    getUserActivation(user_id[1], path[3])
    .then((response) => {
      setSuccessMessage(response.message);
      setIsLoading(false);
    })
    .catch((error) => {
        setIsLoading(false);
        setSuccessMessage(error.message);
    });
  }, [location.pathname]);  


  useEffect(() => {
    useractivationsendRequest();
  }, [useractivationsendRequest]);


  return (    
    <div className="resetPasswordHolder w-full sm:w-[592px] flex flex-1 justify-center items-stretch sm:items-center sm:m-auto">
      <div className="userActivationBg flex flex-col grow px-5 sm:px-5 md:p-7 py-5  lg:px-14  lg:py-10 m-5  bg-transparent sm:bg-white sm:landscape:bg-transparent md:landscape:bg-white rounded-lg justify-center sm:min-h-[220px] min-h-full ">
        {isLoading && (
          <div className="parentDisable">
            <LoadingSpinner />
          </div>
        )}      
        <div className="msgHeader">
          <div className="flex justify-between items-center gap-3 sm:gap-4 flex-wrap">
            <h2 className="title text-lg leading-5  barlowBold text-[#333]">
              User Activation
            </h2>
          </div>
        </div>
        {!successMessage ? (
        <>
        </>
        ) : (<div className="userActivationMsg mt-2"><p className="text-sm sm:text-base leading-5 text-[#333] font-normal tracking-[0.2px]">{successMessage}</p>
        <Button
          onClick={goBackHome}
          className="mt-10 btn backToLoginBtn w-full bg-primaryBlue text-white h-[46px] px-5 font-normal leading-5 text-base flex justify-center items-center rounded"
        >
          Back Home
        </Button>        
        </div>        
        )}
      </div>  
    </div>  
  );
}

export default UserActivation