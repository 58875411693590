import React, { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Webcam from 'react-webcam';

import Modal from "../UI/Modal";
import { useModal } from '../../store/ModalContext';

const CropImageModal = (props) => {
    const [scale, setScale] = useState(1)

    const { bodyBodyOverflow } = useModal()
    const editorRef = useRef(null);
    const webcamRef = useRef(null);

    const handleCapture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        props.setCropImage(imageSrc);
        props.setIsWebCamEnabled(false);
    };

    const handleCropImage = () => {
        if (editorRef.current) {
            const canvasScaled = editorRef.current.getImageScaledToCanvas().toDataURL();
            props.setUserData((prev) => ({
                ...prev,
                picture: canvasScaled,
            }));
            props.setImagePreview(true)
            props.onClose(false)
            bodyBodyOverflow()
        }
    };

    const handleScaleChange = (e) => {
        const scale = parseFloat(e.target.value);
        setScale(scale);
    };

    const handleCancel = () => {
        props.setCropImage()
        props.setUserData((prev) => ({
            ...prev,
            picture: props.initialValue?.picture,
        }));
        props.setImagePreview(false)
        props.setIsFileLoading(false)
        props.onClose(false)
        props.setIsWebCamEnabled(false)
        bodyBodyOverflow()
    }

    return (
        <Modal>
            <div className="cropImageHolder succesMsgHolder h-full flex grow mx-0 sm:mx-0 md:mx-4">
                <div className="flex items-stretch justify-normal rounded-none sm:rounded-lg max-w-full sm:max-w-[400px] max-h-full sm:max-h-full h-full sm:max-h-auto sm:h-auto m-auto bg-white relative grow px-10 py-8">
                    <div className="w-full flex flex-col justify-center max-[530px]:items-stretch items-center gap-5">
                        {props.isWebCamEnabled && <><Webcam audio={false} ref={webcamRef} /><button onClick={handleCapture}>Capture Image</button></>}
                        {props.cropImage && (<>
                            <AvatarEditor
                                ref={editorRef}
                                image={props.cropImage}
                                width={250}
                                height={250}
                                border={50}
                                color={[255, 255, 255, 0.6]} // RGBA
                                scale={scale}
                                rotate={0}
                            />

                            <input
                                type='range'
                                min={1}
                                max={5}
                                step={1}
                                value={scale}
                                onChange={handleScaleChange}
                            />
                            <br />
                            <div className='flex gap-6 flex-wrap sm:flex-nowrap'>
                                <button onClick={handleCropImage} className='btn bg-primaryBlue h-[46px] font-bold leading-5 text-base  justify-center items-center rounded text-white text-center cursor-pointer whitespace-nowrap min-w-full sm:min-w-[120px]'>Crop Image</button>
                                <button onClick={handleCancel} className=' createAccountBtn h-[46px] rounded text-[#005BC3] leading-5 text-base font-bold w-full border border-primaryBlue whitespace-wrap bg-white  flex items-center justify-center hover:bg-primaryBlue hover:text-white min-w-full sm:min-w-[120px]'>Cancel</button>
                            </div>
                        </>)}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CropImageModal;
