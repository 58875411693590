import { useCallback, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { getPeerSearch } from "../../lib/api";
import { useModal } from '../../store/ModalContext';
import { useSelector, useDispatch } from 'react-redux';
import { updatePeerSearch } from '../../redux/peersearchSlice';

const PeersSearch = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { EncryptionData } = useModal();
    const { peerskeywordList } = useSelector((state) => state.peersearch);

    const keywordsendRequest = useCallback(() => {
        getPeerSearch()
          .then((response) => {
            const unique = response.filter((obj, index) => {
                return index === response.findIndex(o => obj.keyword === o.keyword);
            });                        
            dispatch(updatePeerSearch(unique));
          })
          .catch((error) => {
            console.log(error);
        });
    }, [dispatch]);
        
    useEffect(() => {
        if(peerskeywordList === null || peerskeywordList.length === 0) {
            keywordsendRequest();
        }
    }, [keywordsendRequest, peerskeywordList]);



    const handlesearchVideo = (value) => {        
        if(value && value.length > 2) {      
          history.push('/searchall-result/' + EncryptionData(value));
        }      
    }            
    return (
        <section className="section peersSearchHolder">
        <div className="pearsSearch bg-[#E5F1FA]  bg-cover px-5 sm:px-5 md:px-5 lg:px-14 py-8 sm:py-8 md:py-8 lg:py-12">
            <h2 className="text-[#333] text-lg barlowBold leading-5 sm:text-[28px] sm:leading-9 sm:tracking-[-0.25px]">See What Ophthalmologists Search</h2>
            <div className="peersSearchList mt-6 sm:mt-8 gap-3 flex justify-center *:text-[#333] *:text-sm *:font-medium *: leading-7 *:tracking-[-0.25px] *:sm:text-base *:md:text-xl *:sm:leading-9 *:sm:tracking-[-0.25px] *:sm::px-5 *:sm:py-[5px] *:px-[10px] *:py-1 *:rounded-lg *:border *:border-[#7DA1C4] *:whitespace-nowrap flex-wrap *:bg-white *:cursor-pointer">
            {peerskeywordList && peerskeywordList.map((details) =>
                <span key={details.keyword} id={details.keyword} onClick={() => handlesearchVideo(details.keyword)}>{details.keyword}</span>
            )}
            </div>
        </div>
    </section>
    )
}

export default PeersSearch;