import { Fragment } from 'react';

import Header from './Header';
import Footer from './Footer';

const Layout = (props) => {
  return (
    <Fragment>
      <Header />
      <main className='grow flex flex-col'>{props.children}</main>
      <Footer/>
    </Fragment>
  );
};

export default Layout;
