import { useReducer } from 'react';

const initialInputState = {
  value: '',
  isTouched: false,
};

const inputStateReducer = (state, action) => {
  if (action.type === 'INPUT') {
    return { value: action.value, isTouched: state.isTouched };
  }
  if (action.type === 'BLUR') {
    return { isTouched: true, value: state.value };
  }
  if (action.type === 'RESET') {
    return { isTouched: false, value: '' };
  }
  return inputStateReducer;
};

const useInput = (validateValue, passwordValue) => {
  const [inputState, dispatch] = useReducer(
    inputStateReducer,
    initialInputState
  );

  const valueIsValid = validateValue(passwordValue ? inputState.value === passwordValue ? inputState.value : '' : inputState.value);
  const hasError = valueIsValid !== '' && inputState.isTouched;

  const valueChangeHandler = (event) => {
    const value = event.target.id === 'email' ? event.target.value.replace(/\s/g, '') : event.target.value;
    dispatch({ type: 'INPUT', value: value });
    if (event.target.id !== 'email') { dispatch({ type: 'BLUR' }); }
  };

  const inputBlurHandler = (event) => {
    dispatch({ type: 'BLUR' });
  };

  const reset = () => {
    dispatch({ type: 'RESET' });
  };

  return {
    value: inputState.value,
    isValid: valueIsValid,
    hasError: hasError,
    valueChangeHandler,
    inputBlurHandler,
    reset,
  };
};

export default useInput;