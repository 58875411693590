import { useModal } from "../../store/ModalContext";

const UserSettings = () => {
  const { openChangePasswordModal } = useModal();

  const handlerChangePassword = () => {
    openChangePasswordModal();
  }  
  return (
  <div className="profileSection bg-white p-5 sm:p-5 md:p-5 lg:px-14 py-9 lg:py-9">
    <div className="Profile">
      <div className="profileHeader items-center">
        <div className="flex items-center gap-4 flex-wrap md:flex-nowrap">
          <h2 className="title text-lg leading-5 sm:text-[28px] sm:leading-9 barlowBold uppercase">Settings</h2>
        </div>
      </div>
      <div className="settings mt-7 sm:mt-12  max-w-full lg:max-w-[50%]  mr-auto">
      <div className="security mt-10">
          <h3 className="professionalSubTitle text-[#333] text-base sm:text-lg font-bold leading-normal">Security</h3>
          <p className="pb-3 mt-1 text-[#666] text-xs leading-normal font-nor sm:text-sm">Enhance your app's security with comprehensive settings to safeguard your data and privacy</p>
        </div>
        <div className="divider bg-[#93AEC8] h-[1px]"></div>
        <div className="security mt-6">
          <div className="flex items-end justify-between gap-4 flex-wrap">
            <div>
              <h3 className="professionalSubTitle text-[#333] text-base sm:text-lg font-bold leading-normal">Change Password</h3>
              <p className="mt-1 text-[#666] text-xs leading-normal font-nor sm:text-sm">Update your password regularly to keep your data safe</p>
            </div>
            <button className="btn changePassword bg-primaryBlue text-white leading-normal font-medium text-sm h-[30px] rounded px-3" onClick={handlerChangePassword}>Change Password</button>
          </div>
        </div>
        <div className="emailNotifications mt-6">
          <div className="flex justify-between gap-4 items-center flex-wrap">
            <p className="text-[#333] text-sm sm:text-base font-medium leading-normal">Email Notifications</p>
            <label className="toggle">
                <input className="toggle-checkbox" type="checkbox" checked/>
                <div className="toggle-switch"></div>
                <span className="toggle-label"></span>
            </label>
          </div>
        </div>
        

        <div className="preference mt-10">
          <h3 className="professionalSubTitle text-[#333] text-base sm:text-lg font-bold leading-normal">Preference</h3>
          <p className="pb-3 mt-1 text-[#666] text-xs leading-normal font-nor sm:text-sm">Customize your preferences, including language and appearance.</p>
        </div>
        <div className="divider bg-[#93AEC8] h-[1px]"></div>
        <div className="language mt-6">
          <div className="flex justify-between gap-4 items-center">
            <p className="text-[#333] text-sm sm:text-base font-medium leading-normal">Language</p>
            <div className="disabled dropDown flex gap-[10px] px-2 w-[100px] h-[30px] rounded border border-[#D1DDE6] bg-white items-center cursor-pointer relative">
              <span className="text-[#0F0F0F] text-sm font-medium leading-5 tracking-[0.2px]">English</span>
              <span className="downArrowIcon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M11.9997 14.6615C11.8791 14.6615 11.767 14.6423 11.6631 14.6038C11.5593 14.5654 11.4606 14.4993 11.367 14.4058L6.87276 9.91154C6.73429 9.77309 6.66346 9.59905 6.66026 9.38944C6.65704 9.17982 6.72788 9.00258 6.87276 8.85771C7.01763 8.71284 7.19326 8.64041 7.39966 8.64041C7.60606 8.64041 7.78169 8.71284 7.92656 8.85771L11.9997 12.9308L16.0728 8.85771C16.2112 8.71926 16.3852 8.64843 16.5949 8.64521C16.8045 8.64201 16.9817 8.71284 17.1266 8.85771C17.2714 9.00258 17.3439 9.17822 17.3439 9.38464C17.3439 9.59104 17.2714 9.76667 17.1266 9.91154L12.6323 14.4058C12.5388 14.4993 12.44 14.5654 12.3362 14.6038C12.2324 14.6423 12.1202 14.6615 11.9997 14.6615Z" fill="black" />
                </svg>
              </span>
              <div className="dropdownContent left-0 absolute right-0 z-50 top-full mt-1 hidden">
                <div className="bg-white rounded-lg shadow-md w-max">
                  <ul className="*:px-[14px] *:py-2">
                    <li className="flex  gap-3 cursor-pointer hover:bg-[#F4F8FD]">
                      <span className="text-[#666] font-normal tracking-[0.15px] text-sm">Language 1</span>
                    </li>
                    <li className="flex  gap-3 cursor-pointer hover:bg-[#F4F8FD]">
                      <span className="text-[#666] font-normal tracking-[0.15px] text-sm">Language 2</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="appearance mt-6">
          <div className="flex justify-between gap-4 items-center flex-wrap">
            <p className="text-[#333] text-sm sm:text-base font-medium leading-normal">Appearance</p>
            <div className="disabled btnGroup">
              <div className="flex">
                <button className="lightTheme bg-primaryBlue text-white leading-normal font-medium text-sm h-[30px] rounded px-3">Light</button>
                <button className="darkTheme bg-white text-[#717375] leading-normal font-medium text-sm h-[30px] rounded px-3">dark</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default UserSettings;