import { useCallback, useEffect, useRef, useState } from 'react';
import { getHighlightsVideos } from '../../lib/api';
import Highlightsvideo from '../UI/Highlightsvideo';
import { useSelector, useDispatch } from 'react-redux';
import { updateHighlight } from '../../redux/highlightSlice';

const ExploreOurVideos = () => {
    const { highlightList } = useSelector((state) => state.highlight);
    const [lineClamp, setLineClamp] = useState(7);
    const highlightvideoRef = useRef(null);
    const dispatch = useDispatch();
    const video_type = "video/mp4";

    const highlightsvideosendRequest = useCallback(() => {
        getHighlightsVideos()
            .then((response) => {
                if(response) {
                    dispatch(updateHighlight(response[0]));
                }                
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dispatch]);

    const handleViewMore = () => {
        setLineClamp(18);
    }    
    const handleViewLess = () => {
        setLineClamp(7);
    }

    useEffect(() => {
        if (highlightList === null || highlightList.length === 0) {
            highlightsvideosendRequest();
        }        
    }, [highlightsvideosendRequest, highlightList]);    
    return (
    <section className="section exploreVideosSection">
    <div className="px-5 sm:px-5 md:px-5 lg:px-14 py-10 sm:py-10 md:py-10 lg:py-14">
    {highlightList ? ( 
        <div className="flex flex-wrap lg:flex-nowrap sm:gap-7 md:gap-[54px] gap-4 items-start">
            <div className="columnOne h-auto sm:h-auto md:h-auto lg:h-auto  relative bg-white aspect-video basis-full lg:basis-[40%]">
                {
                    highlightList.dam_asset_url &&
                    <Highlightsvideo src={highlightList.dam_asset_url} type={video_type} useRef={highlightvideoRef} />
                }
            </div>
            <div className="columnTwo flex flex-col justify-between basis-full lg:basis-[60%] gap-3">
                <div className="flex flex-col gap-3 sm:gap-4">
                    <h2 className="text-[#333] text-lg barlowBold leading-6 tracking-[-0.25px] sm:text-[28px] sm:leading-9 sm:tracking-[0.25px] ">
                        <span className="text-[#717375] text-sm leading-5 font-medium tracking-[0.035px] block">Highlights</span>
                        <span>{highlightList.title}</span>
                    </h2>
                    <p className={`text-[#333] text-sm font-normal leading-6 sm:text-xl sm:leading-[30px]w-full sm:w-full md:w-full lg:w-3/4 line-clamp-${lineClamp}`}>
                    <span dangerouslySetInnerHTML={{ __html: highlightList.description }}></span></p>
                </div>
                {lineClamp === 7 ? (
                <button className="exploreBtn rounded text-left text-base leading-6 font-bold text-primaryText bg-white sm:text-[18px] sm:leading-8" onClick={() => handleViewMore()}>View more</button>
                ): ( <button className="exploreBtn rounded  text-left text-base leading-6 font-bold text-primaryText bg-white sm:text-[18px] sm:leading-8" onClick={() => handleViewLess()}>View less</button>
                )}
            </div>
        </div>
    ): ( <></> )}
    </div>
    </section>
    )
}

export default ExploreOurVideos;