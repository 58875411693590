import { useState, useRef, useEffect } from 'react';
import { getProfessional, getSpeciality, validateEmail, register, getCountry } from '../../lib/api';
import Modal from '../UI/Modal';
import Button from '../UI/Button';
import Input from '../UI/Input';
import Dropdown from '../UI/Dropdown';
import useInput from '../../hooks/use-input';
import LoadingSpinner from '../UI/LoadingSpinner';
//import AuthContext from '../../store/auth-context';
//import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { isInstitution, isLastName, isFirstName, isPassword } from '../UI/Validations';
import { useModal } from "../../store/ModalContext";

const isNotEmpty = (value) => value.trim() !== '';

const RegisterForm = (props) => {
  //const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [unCheckedError, setUnCheckedError] = useState('');
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [unTermsError, setUnTermsError] = useState('');
  const [selectedProfessionalOption, setSelectedProfessionalOption] = useState(null);
  const [selectedSpecialityOption, setSelectedSpecialityOption] = useState(null);
  const [isProfessionalValid, setProfessionalValid] = useState(false);
  const [isSpecialityValid, setSpecialityIsValid] = useState(false);
  const [emailHasEmptyError, setEmailHasEmptyError] = useState(false);
  const [specialityOptionsList, setSpecialityOptionsList] = useState([]);
  const [professionalOptionsList, setProfessionalOptionsList] = useState([]);
  const [specialityMessage, setSpecialityMessage] = useState("");
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [emailValue, setEmailHasValue] = useState();
  const [emailHasError, setEmailhasError] = useState();
  const [professionHasError, setProfessionHasError] = useState();
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [isCountryValid, setCountryValid] = useState(false);
  const [countryOptionsList, setCountryOptionsList] = useState([]);
  const [countryHasError, setCountryHasError] = useState();

  const { openRegisterConfirmMessageModal } = useModal();

  const handleProfessionalOption = (option) => {
    setSpecialityOptionsList(null);
    setSelectedSpecialityOption(null);
    setSelectedProfessionalOption(option);
    setProfessionalValid(true);
    setProfessionHasError() // Reset validation when an option is selected   
    setSpecialityIsValid(false);
    specialitysendRequest(option.id);
    setIsLoading(true)
    setSpecialityMessage("Please select your speciality.");
  };

  const handleSpecialityOption = (option) => {
    setSelectedSpecialityOption(option);
    setSpecialityIsValid(true); // Reset validation when an option is selected
  };

  const handleBlur = (dropdownNumber, isValid) => {
    if (dropdownNumber === 1) {
      setProfessionalValid(isValid);
      setProfessionHasError("Please select your profession.") // Update the validation state for dropdown 1
    } else if (dropdownNumber === 2) {
      setSpecialityIsValid(isValid); // Update the validation state for dropdown 2
    }
  };

  const titleInputRef = useRef();
  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();
  const institutionNameInputRef = useRef();
  const emailInputRef = useRef();
  const createPasswordInputRef = useRef();
  const confirmPasswordInputRef = useRef();

  const handleEmailValidate = () => {
    validateEmail(emailInputRef?.current?.value)
      .then((response) => {
        if (response.length > 0) {
          return setIsEmailExist(true)
        }
        else return setIsEmailExist(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const emailValidation = (value) => {
    if (!value) { setEmailHasEmptyError(false); setEmailhasError("Please enter email") }
    else if (value[0] === ' ') { setEmailHasEmptyError(false); setEmailhasError("Email cannot start with space") }
    else if (!value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) { setEmailHasEmptyError(false); setEmailhasError("Please enter valid email") }
    else { handleEmailValidate(); setEmailHasEmptyError(true); setEmailhasError('') }
    setEmailHasValue(value.replace(/\s/g, ''))
  }

  const handleEmailChange = (e) => {
    e.preventDefault();
    setIsEmailExist(false)
    let { id, value } = e.target;
    if (id === "email") {
      emailValidation(value)
    }
  }

  const handleEmailBlur = (e) => {
    e.preventDefault();
    let { id, value } = e.target;
    if (id === "email") {
      emailValidation(value)
    }
  }

  const {
    value: titleValue,
    valueChangeHandler: titleChangeHandler,
    inputBlurHandler: titleBlurHandler,
  } = useInput(isNotEmpty);

  const {
    value: firstNameValue,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
  } = useInput(isFirstName);

  const {
    value: lastNameValue,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
  } = useInput(isLastName);

  const {
    value: institutionNameValue,
    isValid: institutionNameIsValid,
    hasError: institutionNameHasError,
    valueChangeHandler: institutionNameChangeHandler,
    inputBlurHandler: institutionNameBlurHandler,
  } = useInput(isInstitution);

  const {
    value: createPasswordValue,
    isValid: createPasswordIsValid,
    hasError: createPasswordHasError,
    valueChangeHandler: createPasswordChangeHandler,
    inputBlurHandler: createPasswordBlurHandler,
  } = useInput(isPassword);

  const {
    value: confirmPasswordValue,
    isValid: confirmPasswordIsValid,
    hasError: confirmPasswordHasError,
    valueChangeHandler: confirmPasswordChangeHandler,
    inputBlurHandler: confirmPasswordBlurHandler,
  } = useInput(isPassword, createPasswordValue);

  let formIsValid = false;

  if (
    !firstNameIsValid &&
    !lastNameIsValid &&
    isProfessionalValid &&
    isSpecialityValid &&
    !institutionNameIsValid &&
    emailHasEmptyError &&
    !isEmailExist &&
    !createPasswordIsValid &&
    !confirmPasswordIsValid &&
    isChecked &&
    isTermsChecked &&
    isCountryValid
  ) {
    formIsValid = true
  }

  const handleCheckbox = (event) => {
    setIsChecked(event.target.checked)
    if (!event.target.checked) {
      setUnCheckedError('Please check the checkbox to proceed further');
    } else setUnCheckedError('')
  }

  const handleTermsCheckbox = (event) => {
    setIsTermsChecked(event.target.checked)
    if (!event.target.checked) {
      setUnTermsError('Please check the checkbox to proceed further');
    } else setUnTermsError('')
  }

  const handleCountryOption = (option) => {
    setSelectedCountryOption(option);
    setCountryValid(true);
    setCountryHasError()
  };

  const handleBlurCountry = (isValid) => {
    setCountryValid(isValid);
    setCountryHasError("Please select your country.")
  };

  const countrysendRequest = () => {
    getCountry()
      .then((response) => {
        const options = response?.data.map((details) => ({
          id: details.id,
          value: details.country_name,
        }));
        setCountryOptionsList(options);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    setIsLoading(true);

    const enteredTitle = titleInputRef.current.value;
    const enteredFirstname = firstNameInputRef.current.value;
    const enteredLastname = lastNameInputRef.current.value;
    const enteredInstitutionName = institutionNameInputRef.current.value;
    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = createPasswordInputRef.current.value;

    const formData = {
      name: {
        value: enteredEmail,
      },
      pass: {
        value: enteredPassword,
      },
      field_name_title: {
        value: enteredTitle,
      },
      field_first_name: {
        value: enteredFirstname,
      },
      field_last_name: {
        value: enteredLastname,
      },
      field_institution_name: {
        value: enteredInstitutionName,
      },
      field_profession: {
        value: selectedProfessionalOption.id,
      },
      field_specialty: {
        value: selectedSpecialityOption.id,
      },
      mail: {
        value: enteredEmail,
      },
      field_country: {
        value: selectedCountryOption.value,
      },
    };
    setIsLoading(true);
    register(formData)
      .then(async (res) => {
        setIsLoading(false);
        if (res.uid.length > 0) {
          openRegisterConfirmMessageModal();
        }
      })
      .catch((error) => {
        setIsLoading(false);
      })
  }

  useEffect(() => {
    professionalsendRequest();
    countrysendRequest();
  }, [])

  const specialitysendRequest = (profession_id) => {
    getSpeciality(profession_id)
      .then((response) => {
        const options = response.map((details) => ({
          id: details.tid,
          value: details.name,
        }));
        setSpecialityOptionsList(options);
        if (options.length === 0) {
          setSpecialityMessage("No speciality found for selected profession!");
        }
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false)
      });
  };

  const professionalsendRequest = () => {
    getProfessional()
      .then((response) => {
        const options = response.map((details) => ({
          id: details.tid,
          value: details.name,
        }));
        setProfessionalOptionsList(options);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTermsandService = () => {
    window.open("/terms-service", "_blank");
  }

  const handlePrivacyPolicy = () => {
    window.open("/privacy-statement", "_blank");
  }

  const handleOrganizationMessage = () => {
    window.open("/messages-organization", "_blank");
  }

  const firstNameClasses = (firstNameIsValid)
    ? "nameSection gap-1 flex flex-col invalid"
    : "nameSection gap-1 flex flex-col";
  const lastNameClasses = (lastNameIsValid)
    ? "nameSection gap-1 flex flex-col invalid"
    : "nameSection gap-1 flex flex-col";
  const institutionClasses = (institutionNameIsValid)
    ? "institutionSection gap-1 flex flex-col invalid"
    : "institutionSection gap-1 flex flex-col";
  const emailClasses = (!emailHasEmptyError || isEmailExist)
    ? "emailSection gap-1 flex flex-col invalid"
    : "emailSection gap-1 flex flex-col";
  const passwordClasses = (createPasswordIsValid)
    ? "passwordSection gap-1 flex flex-col invalid"
    : "passwordSection gap-1 flex flex-col";
  const confirmPasswordClasses = confirmPasswordHasError
    ? "confirmPasswordSection gap-1 flex flex-col invalid"
    : "confirmPasswordSection gap-1 flex flex-col";

  return (
    <Modal>
      <div className="registerHolder h-full flex grow mx-0 sm:mx-0 md:mx-4 ">
        <div className="registerBgHolder flex items-stretch justify-normal rounded-none sm:rounded-none md:rounded-lg max-w-[1162px] max-h-full sm:max-h-full h-full md:max-h-[620px] md-h-[620px] m-auto bg-white relative px-10 py-8 grow overflow-auto">
          {isLoading && (
            <div className="parentDisable">
              <LoadingSpinner />
            </div>
          )}
          <form className="registerForm grow static z-50" onSubmit={submitHandler}>
            <div className="w-full relative flex flex-col justify-between gap-8 h-full">
              <div className="createAccount">
                <div className="flex justify-between items-center gap-6">
                  <h2 className="pageTitle  text-[#354665] barlowBold text-xl uppercase">
                    Create Account
                  </h2>
                  <div
                    className="closeIconHolder cursor-pointer"
                    onClick={props.onClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect width="24" height="24" fill="white" />
                      <path
                        d="M4.7475 20.1575C4.5575 20.1575 4.3675 20.0875 4.2175 19.9375C3.9275 19.6475 3.9275 19.1675 4.2175 18.8775L17.8775 4.2175C18.1675 3.9275 18.6475 3.9275 18.9375 4.2175C19.2275 4.5075 19.2275 4.9875 18.9375 5.2775L5.2775 19.9375C5.1375 20.0875 4.9375 20.1575 4.7475 20.1575Z"
                        fill="#717375"
                        stroke="#717375"
                      />
                      <path
                        d="M18.4075 20.1575C18.2175 20.1575 18.0275 20.0875 17.8775 19.9375L4.2175 5.2775C3.9275 4.9875 3.9275 4.5075 4.2175 4.2175C4.5075 3.9275 4.9875 3.9275 5.2775 4.2175L18.9375 18.8775C19.2275 19.1675 19.2275 19.6475 18.9375 19.9375C18.7875 20.0875 18.5975 20.1575 18.4075 20.1575Z"
                        fill="#717375"
                        stroke="#717375"
                      />
                    </svg>
                  </div>
                </div>

                <div className="registerFormHolder mt-6">
                  <div className="formFielditemsHolder grid justify-between grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-6 sm:gap-x-8  md:gap-x-10 lg:gap-x-24">
                    <div className="titleSection gap-1 flex flex-col">
                      {/* <label className="titleLabel text-[#333] text-sm font-normal leading-5">Title</label>
                                    <input type="text" className="p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm" placeholder="Enter Enter Title "/> */}
                      <Input
                        ref={titleInputRef}
                        label="Title"
                        labelClass="firstNameLabel text-[#333] text-sm font-normal leading-5"
                        input={{
                          id: "title",
                          name: "title",
                          type: "text",
                          value: titleValue,
                          placeholder: "Mr/Ms/Mrs",
                          onChange: titleChangeHandler,
                          onBlur: titleBlurHandler,
                          className:
                            "p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm placeholder:text-[#666] !text-[#333]",
                        }}
                      />
                    </div>
                    <div className={firstNameClasses}>
                      <Input
                        ref={firstNameInputRef}
                        label="First Name"
                        labelClass="firstNameLabel text-[#333] text-sm font-normal leading-5"
                        isManditory={true}
                        input={{
                          id: "firstName",
                          name: "firstName",
                          type: "text",
                          value: firstNameValue,
                          placeholder: "Enter First Name",
                          onChange: (event) => {
                            firstNameChangeHandler(event);
                          },
                          onBlur: firstNameBlurHandler,
                          className:
                            "p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm placeholder:text-[#666]  !text-[#333]",
                        }}
                      />
                      {firstNameHasError && (
                        <p className="error-text">
                          {firstNameIsValid}
                        </p>
                      )}
                    </div>
                    <div className={lastNameClasses}>
                      <Input
                        ref={lastNameInputRef}
                        label="Last Name"
                        labelClass="firstNameLabel text-[#333] text-sm font-normal leading-5"
                        isManditory={true}
                        input={{
                          id: "lastName",
                          name: "lastName",
                          type: "text",
                          value: lastNameValue,
                          placeholder: "Enter Last Name",
                          onChange: (event) => {
                            lastNameChangeHandler(event);
                          },
                          onBlur: lastNameBlurHandler,
                          className:
                            "p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm placeholder:text-[#666]  !text-[#333]",
                        }}
                      />
                      {lastNameHasError && (
                        <p className="error-text">
                          {lastNameIsValid}
                        </p>
                      )}
                    </div>
                    <div className="countrySection gap-[6px] flex flex-col relative">
                      <Dropdown
                        label="Country"
                        labelClass="countryLabel text-[#333] text-sm font-normal leading-5"
                        placeholder="Select a Country"
                        isManditory={true}
                        options={countryOptionsList}
                        onSelect={handleCountryOption}
                        onBlur={(isValid) => handleBlurCountry(isValid)}
                        isValid={isCountryValid} // Pass validation state for dropdown 1
                        selectedValue={selectedCountryOption}
                      />
                      {(!isCountryValid && countryHasError) && (
                        <p className="error-text">
                          {countryHasError}
                        </p>
                      )}
                    </div>
                    <div className="professionSection gap-1 flex flex-col relative">
                      <Dropdown
                        label="Profession"
                        labelClass="professtionLabel text-[#333] text-sm font-normal leading-5"
                        placeholder="Select a Profession"
                        isManditory={true}
                        options={professionalOptionsList}
                        onSelect={handleProfessionalOption}
                        onBlur={(isValid) => handleBlur(1, isValid)}
                        isValid={isProfessionalValid} // Pass validation state for dropdown 1
                        selectedValue={selectedProfessionalOption}
                      />

                      {(!isProfessionalValid && professionHasError) && (
                        <p className="error-text">
                          {professionHasError}
                        </p>
                      )}
                    </div>

                    <div className="specialitySection gap-1 flex flex-col relative">
                      <Dropdown
                        label="Speciality"
                        labelClass="specialityLabel text-[#333] text-sm font-normal leading-5"
                        placeholder="Select a Speciality"
                        isManditory={true}
                        options={specialityOptionsList}
                        onSelect={handleSpecialityOption}
                        onBlur={(isValid) => handleBlur(2, isValid)}
                        isValid={isSpecialityValid}
                        selectedValue={selectedSpecialityOption}
                      />

                      {!isSpecialityValid && (
                        <p className="error-text">
                          {specialityMessage}
                        </p>
                      )}
                    </div>
                    <div className={institutionClasses}>
                      <Input
                        ref={institutionNameInputRef}
                        label="Institution Name"
                        labelClass="firstNameLabel text-[#333] text-sm font-normal leading-5"
                        isManditory={true}
                        input={{
                          id: "institution",
                          name: "institution",
                          type: "text",
                          value: institutionNameValue,
                          placeholder: "Enter Institution Name",
                          onChange: institutionNameChangeHandler,
                          onBlur: institutionNameBlurHandler,
                          className:
                            "p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm placeholder:text-[#666]  !text-[#333]",
                        }}
                      />
                      {institutionNameHasError && (
                        <p className="error-text">
                          {institutionNameIsValid}
                        </p>
                      )}
                    </div>
                    <div className={emailClasses}>
                      <Input
                        ref={emailInputRef}
                        label="Email"
                        labelClass="emailNameLabel text-[#333] text-sm font-normal leading-5"
                        isManditory={true}
                        input={{
                          id: "email",
                          name: "email",
                          type: "email",
                          value: emailValue,
                          placeholder: "Enter Your Email",
                          onChange: (e) => handleEmailChange(e),
                          onBlur: (e) => handleEmailBlur(e),
                          className:
                            "p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm placeholder:text-[#666]  !text-[#333]",
                        }}
                      />
                      {!emailHasEmptyError && (
                        <p className="error-text">
                          {emailHasError}
                        </p>
                      )}
                      {(isEmailExist) && (
                        <p className='error-text'>
                          This Email is already taken. Please try with another email.
                        </p>
                      )}
                    </div>
                    <div className={passwordClasses}>
                      <Input
                        ref={createPasswordInputRef}
                        label="Password"
                        labelClass="passwordLabel text-[#333] text-sm font-normal leading-5"
                        isManditory={true}
                        input={{
                          id: "password",
                          name: "password",
                          type: "password",
                          value: createPasswordValue,
                          placeholder: "Enter Your Password",
                          onChange: createPasswordChangeHandler,
                          onBlur: createPasswordBlurHandler,
                          className:
                            "p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm placeholder:text-[#666]  !text-[#333]",
                        }}
                      />
                      {createPasswordHasError && (
                        <p className="error-text">
                          {createPasswordIsValid}
                        </p>
                      )}
                    </div>
                    <div className={confirmPasswordClasses}>
                      <Input
                        ref={confirmPasswordInputRef}
                        label="Confirm Password"
                        labelClass="confirmPasswordLabel text-[#333] text-sm font-normal leading-5"
                        isManditory={true}
                        input={{
                          id: "confirmPassword",
                          name: "confirmPassword",
                          type: "password",
                          value: confirmPasswordValue,
                          placeholder: "Please Re-enter Your Password",
                          onChange: confirmPasswordChangeHandler,
                          onBlur: confirmPasswordBlurHandler,
                          className:
                            "p-[10px] rounded border border-loginFieldBorder text-[#666] h-10 !text-sm placeholder:text-[#666]  !text-[#333]",
                        }}
                      />
                      {confirmPasswordHasError && (
                        <p className="error-text">Passwords doesn't matched!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <GoogleReCaptchaProvider reCaptchaKey="6LenOb8pAAAAADs2rIP_G_8qjmJLTXWdPwvlzHqU">
                <GoogleReCaptcha
                  className="google-recaptcha-custom-class"
                />
              </GoogleReCaptchaProvider>
              <div className="accountCreationAccpect pb-8 sm:pb-0">
                <div className="flex gap-3 items-start">
                  <input
                    type="checkbox"
                    className="size-5 rounded-lg shrink-0 border-2 border-loginFieldBorder mt-1"
                    onChange={(event) => handleCheckbox(event)} />
                  <p className="text-[#333] leading-normal font-normal text-sm sm:text-base">
                    I permit MastersVideoLibrary to send me updates from its personalized
                    alerts and <span className="text-primaryBlue leading-5 font-normal text-sm cursor-pointer" onClick={handleOrganizationMessage}>messages from organization.</span>
                  </p>
                </div>
                {!isChecked && unCheckedError && <p className="error-text mt-1">{unCheckedError}</p>}
                <div className="flex gap-3 items-start mt-2">
                  <input
                    type="checkbox"
                    className="size-5 rounded-lg shrink-0 border-2 border-loginFieldBorder mt-1"
                    onChange={(event) => handleTermsCheckbox(event)} />
                  <div className='flex flex-col items-start'>
                    <p className="text-[#333] leading-normal font-normal text-sm sm:text-base">
                      I agree to the <span className="text-primaryBlue leading-5 font-normal text-sm cursor-pointer" onClick={handleTermsandService} >Terms of Service</span>
                    </p>
                    <p className="text-[#333] leading-normal font-normal text-sm sm:text-base">
                      Learn how we collect, use and share your data in our <span className="text-primaryBlue leading-5 font-normal text-sm cursor-pointer" onClick={handlePrivacyPolicy} >Privacy Policy</span> and how we use cookies in our Cookies Policy.
                    </p>
                  </div>
                </div>
                {!isTermsChecked && unTermsError && <p className="error-text mt-1">{unTermsError}</p>}
                <div className="flex gap-3 items-center">

                </div>
                {!isLoading && (
                  <Button
                    disabled={!formIsValid}
                    type="submit"
                    // onClick={props.handleGdprConsent}
                    className={!formIsValid ? "btn mt-8 continueBtn w-auto bg-primaryBlue h-[46px] px-20 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center disabled" : "btn mt-8 continueBtn w-auto bg-primaryBlue h-[46px] px-20 py-[10px] font-bold leading-5 text-base justify-center  rounded text-white inline-flex items-center cursor-pointer"}
                  >
                    Create Account
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default RegisterForm;