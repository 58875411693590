import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { useModal } from '../../store/ModalContext';
import { getPages } from '../../lib/api';

import LoadingSpinner from '../UI/LoadingSpinner';
import logo from '../../assets/images/logoColor.svg';
import hoyaLogo from '../../assets/images/hoyaLogo.svg';
import { updateFooterLink } from '../../redux/footerlinkSlice';

const Footer = () => {
    const history = useHistory();
    const location = useLocation();
    const { pathname } = useLocation();
    const { openContactUsModal } = useModal();
    const { currentUser } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const { footerlinkList } = useSelector((state) => state.footerlink);

    const footerLinkHandle = (linkName) => {
        history.push(`${linkName}`)
    }

    const goBackHome = () => {
        let path = location.pathname.split("/");
        if (path[1] === '') {
            window.scrollTo(0, 0);
        } else {
            history.push(`/`);
            window.scrollTo(0, 0);
        }
    }

    const contactusHandle = () => {
        if (currentUser === null) {
            openContactUsModal();
        } else {
            history.push('/support')
        }
    }

    const handleLinkendInURL = () => {
        window.open("https://www.linkedin.com/company/hoyasurgicaloptics", '_blank')
    }

    useEffect(() => {
        if (footerlinkList === null || footerlinkList.length === 0) {
            getPages()
            .then((response) => {
                setIsLoading(false);
                const remainingLinks = response.filter((details) => {return details.title !== 'Messages from organization'});
                dispatch(updateFooterLink(remainingLinks));
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error);
            });
        }
    }, [footerlinkList, dispatch])

    if (pathname === "/specialization") return null;

    return (
        <footer className='shadow-footerShadow static z-[99]'>
            {isLoading && (
                <div className="parentDisable">
                    <LoadingSpinner />
                </div>
            )}
            <div className="bg-footerBg px-5 sm:px-5 md:px-5 lg:px-14 py-5">
                <div className="flex lg:justify-between xl:justify-between gap-4 flex-wrap justify-center sm:justify-center md:justify-center items-center">
                    <div className="footerLogoSection">
                        <div className="flex gap-3 sm:gap-[14px]">
                            <img src={logo} className="footerLogoEyeLearn shrink w-[180px] sm:w-[220px] md:w-[260px] cursor-pointer" alt="mastersvideolibraryLogo" onClick={goBackHome}/>
                            <div className="bar w-[1px] bg-[#8594A6] opacity-50"></div>
                            <a href="https://www.hoyasurgicaloptics.com/" target="_blank" rel="noreferrer"><img src={hoyaLogo} className="hoyaLogo shrink w-16 sm:w-auto" alt="hoyaLogo" /></a>
                        </div>
                    </div>
                    <div className="footerMenu">
                        <ul className="flex flex-wrap justify-center sm:gap-4 gap-x-2 gap-y-1 items-center *:text-footerMenuColor font-normal *:sm:text-base *:sm:tracking-[-0.04px] *:text-sm *:leading-5 *:tracking-[-0.035px] *:cursor-pointer">
                            {footerlinkList && footerlinkList.map((item, key) => <li key={key} onClick={() => footerLinkHandle(item.slug_url)}>{item.title}</li>)}
                            <li onClick={contactusHandle}>Contact Us</li>
                        </ul>
                    </div>
                </div>
                <div className="mt-4 flex gap-4  flex-wrap justify-center md:justify-between items-center">
                    <div className="copyRighSection">
                        <p className="text-[#717375] font-normal text-sm sm:tracking-[-0.04px]">Copyright © 2024 · All Rights Reserved</p>
                    </div>
                    <div className="socialMediaIcons">
                        <div className="flex items-center flex-wrap justify-center gap-6 gap-y-2">
                            <div className='flex items-center gap-3'>
                                <p className="caption text-[#717375] font-normal sm:text-base text-sm leading-4 tracking-[-0.04px]">Visit Us @</p>
                                <button className="text-primaryText text-base sm:text-[18px] leading-normal font-medium tracking-[-0.045px]"><a href="https://www.hoyasurgicaloptics.com" target='_blank' rel="noreferrer">www.hoyasurgicaloptics.com</a></button>
                            </div>
                            <div className='flex items-center gap-3'>
                                <p className="caption text-[#717375] font-normal sm:text-base text-sm leading-4 tracking-[-0.04px]">Follow Us</p>
                                <div className='LinkedInIcon cursor-pointer' onClick={handleLinkendInURL}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                        <g clipPath="url(#clip0_2957_4598)">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M15 30C17.9667 30 20.8668 29.1203 23.3336 27.472C25.8003 25.8238 27.7229 23.4811 28.8582 20.7403C29.9935 17.9994 30.2906 14.9834 29.7118 12.0737C29.133 9.16395 27.7044 6.49118 25.6066 4.39339C23.5088 2.29561 20.8361 0.867008 17.9264 0.28823C15.0166 -0.290548 12.0006 0.00649226 9.25974 1.14181C6.51884 2.27712 4.17618 4.1997 2.52795 6.66643C0.879733 9.13317 0 12.0333 0 15C0 16.9698 0.387995 18.9204 1.14182 20.7403C1.89564 22.5601 3.00052 24.2137 4.3934 25.6066C5.78628 26.9995 7.43985 28.1044 9.25974 28.8582C11.0796 29.612 13.0302 30 15 30ZM15 28.2C17.6107 28.2 20.1628 27.4258 22.3335 25.9754C24.5043 24.525 26.1961 22.4634 27.1952 20.0514C28.1943 17.6394 28.4557 14.9853 27.9464 12.4248C27.4371 9.86425 26.1799 7.51224 24.3338 5.66619C22.4877 3.82014 20.1357 2.56297 17.5752 2.05364C15.0146 1.54432 12.3606 1.80572 9.94858 2.8048C7.53659 3.80388 5.47503 5.49574 4.0246 7.66646C2.57416 9.83719 1.79999 12.3893 1.79999 15C1.79999 18.5008 3.19072 21.8583 5.6662 24.3338C8.14168 26.8093 11.4991 28.2 15 28.2Z" fill="#0057B8" />
                                            <path d="M19.0641 21.7275V17.415C19.0641 16.361 19.0751 15.0146 17.6221 15.0146C16.1691 15.0146 16.0621 16.153 16.0621 17.333V21.7334H13.3101V12.6699H15.7491V13.8584H15.8691C16.1881 13.4708 16.5949 13.1641 17.0557 12.9648C17.5165 12.7656 18.0181 12.6789 18.519 12.7119C20.828 12.7119 21.5711 13.8115 21.7791 15.5225C21.8151 15.9665 21.8264 16.4131 21.813 16.8584V21.7354L19.0641 21.7275ZM8.18604 21.7275V12.665H11.4991V21.7275H8.18604ZM8.35406 9.84863C8.35406 9.43012 8.52029 9.02933 8.81622 8.7334C9.11216 8.43747 9.51356 8.27051 9.93207 8.27051C10.3506 8.27051 10.7519 8.43747 11.0479 8.7334C11.3438 9.02933 11.5101 9.43012 11.5101 9.84863C11.5101 10.2671 11.3438 10.6689 11.0479 10.9648C10.7519 11.2608 10.3506 11.4268 9.93207 11.4268C9.51356 11.4268 9.11216 11.2608 8.81622 10.9648C8.52029 10.6689 8.35406 10.2671 8.35406 9.84863Z" fill="#0057B8" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2957_4598">
                                                <rect width="30" height="30" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;