import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '../UI/Card';
import { getFeaturedVideos } from '../../lib/api';
import { useModal } from '../../store/ModalContext';
import defaultThumbnail from '../../assets/images/thumbnails/thumbnailsPlaceholder.png';

const FeatureVideos = () => {
    const history = useHistory()
    const cardRef = useRef();
    const { secondsToMinutes } = useModal();
    const [leftscroll, setLeftScroll] = useState('');
    const [rightscroll, setRightScroll] = useState('');
    const [featuredvideoList, setFeaturedVideoList] = useState([]);

    const handleNav = (speed, distance, step) => {
      let scrollAmount = 0;
      const slideTimer = setInterval(() => {
        cardRef.current.scrollLeft += step;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
            if(cardRef.current.scrollLeft === cardRef.current.scrollLeftMax) {
                setRightScroll('disabled');
                setLeftScroll('');
            } else {                
                setRightScroll('');
                setLeftScroll('');
            }
            if(cardRef.current.scrollLeft > 10 ) {
                setLeftScroll('');        
            } else {
                setLeftScroll('disabled');
            }
            clearInterval(slideTimer);
        }
      }, speed);      
    };
    
    const featuredvideosendRequest = useCallback(() => {
        getFeaturedVideos()
          .then((response) => {
            response.sort((a, b)=>{return a.order > b.order ? 1 : -1});
            setFeaturedVideoList(response);        
          })
          .catch((error) => {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        featuredvideosendRequest();
    }, [featuredvideosendRequest]);

    const handleViewFeaturedVideo = (title) => {
        history.push('/video-details'+title);
    }

    const onImageError = (e) => {
        e.target.src = defaultThumbnail;
    }

    return (
    <section className="section featuredVideos bg-[#F4F8FD]">
        <div className="VideosSection px-5 sm:px-5 md:px-5 lg:px-14 pt-5 sm:pt-5 md:pt-5 lg:pt-7  pb-5 sm:pb-5 md:pb-5 lg:pb-7">           
        <div className="videosList">
            <div className="flex justify-between items-center gap-4">
                <h2 className="sectionTitle text-secondaryTitle text-lg leading-5 sm:text-[28px] barlowBold sm:leading-9 tracking-[-0.25px]">Featured Videos</h2>
                <div className="scrollControls  gap-4 flex max-[1024px]:hidden">
                    <div onClick={() => handleNav(5, 400, -10)} className={`leftScrollIcon  items-center h-full  size-7 cursor-pointer opacity-80 ${leftscroll}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="32" viewBox="0 0 20 36.351"><path id="union" d="M16.59.591.8,16.545A2.048,2.048,0,0,0,.8,19.8L16.59,35.76a1.983,1.983,0,0,0,2.825,0,2.032,2.032,0,0,0,0-2.854L4.836,18.175,19.415,3.445a2.032,2.032,0,0,0,0-2.854,1.983,1.983,0,0,0-2.825,0Z" transform="translate(0 0)" fill="#80CCFF"></path></svg>
                    </div>
                    <div onClick={() => handleNav(5, 400, +10)} className={`rightScrollIcon items-center h-full  size-7 cursor-pointer opacity-80 ${rightscroll}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="32" viewBox="0 0 20 36.351"><path id="union" d="M16.59,35.76.8,19.806a2.048,2.048,0,0,1,0-3.257L16.59.591a1.983,1.983,0,0,1,2.825,0,2.032,2.032,0,0,1,0,2.854L4.836,18.176l14.578,14.73a2.032,2.032,0,0,1,0,2.854,1.983,1.983,0,0,1-2.825,0Z" transform="translate(20 36.351) rotate(180)" fill="#80CCFF"></path></svg>
                    </div>
                </div>
            </div>
            <div className="cards flex gap-4 sm:gap-6 *:w-[310px] *:sm:w-[372px] mt-3 sm:mt-6 *:rounded-lg *:shadow-cardShadow *:cursor-pointer overflow-x-auto *:flex-shrink-0 pb-[6px]"  ref={cardRef}>
            {featuredvideoList.map((details) =>
            <Card key={details.mid} onClick={() => handleViewFeaturedVideo(details.slug_url)}>
            <div className="thumbnail h-[190px] sm:h-[206px] relative group">
                <img src={details.thumbnail_url || defaultThumbnail} className="w-full h-full rounded-tl-lg rounded-tr-lg object-cover" alt="thumbnail" onError={onImageError} />
                {details.duration && details.duration > 0 ?
                <p className="duration  bg-videoDurationBg text-white text-xs font-normal leading-4 rounded px-2 py-1 absolute right-4 bottom-4">{secondsToMinutes(details.duration)}</p>
                : <></>}
                <div className="playBtn absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 cursor-pointer hidden group-hover:block">
                    <svg width="38" height="44" viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M36.9612 23.5936L2.99228 43.0044C1.65896 43.7663 0 42.8036 0 41.2679V21.8571V2.44636C0 0.910713 1.65896 -0.052021 2.99227 0.709873L36.9611 20.1207C38.3048 20.8884 38.3048 22.8258 36.9612 23.5936Z" fill="white" fillOpacity="0.8"/>
                    </svg>
                </div>
                <div className="overlay"></div>
            </div>
            <div className="description bg-white p-4 flex flex-col gap-1">
                <p className="videoCatagory text-videoCatagory text-xs sm:text-sm leading-5 font-medium tracking-[0.035px] ">{details.title}</p>
                <p className="videoCaption text-videoCaption  leading-5 sm:leading-6 font-medium  text-sm sm:text-base">{details.description}</p>
            </div>
            </Card>
            )}
            </div>
        </div>
        </div>
    </section>
    )
}

export default FeatureVideos;