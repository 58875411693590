import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    footerlinkList: null,
};

export const footerlinkSlice = createSlice({
    name: "footerlink",
    initialState,
    reducers: {
        updateFooterLink: (state, action) => {
            state.footerlinkList = action.payload;
        },
        clearFooterLink: (state) => {
            state.footerlinkList = null;
        },        
    },
});

export const { updateFooterLink, clearFooterLink } = footerlinkSlice.actions;

export default footerlinkSlice.reducer;