export const isComments = (value) => {
    if (value[0] === ' ') return "Comments cannot start with space";
    if (value.trim().length === 0) return "Please enter comments";
    return '';
};

export const isSubject = (value) => {
    if (!value) return "Please enter subject";
    else if (value[0] === ' ') return "Subject cannot start with space";
    else return '';
};

export const isEmail = (value) => {
    if (!value) return "Please enter email";
    else if (value[0] === ' ') return "Email cannot start with space";
    else if (!value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) return "Please enter valid email";
    else return '';
};

export const isFirstName = (value) => {
    if (!value) return "Please enter first name";
    else if (value[0] === ' ') return "First name cannot start with space";
    else if (value.length > 15) return "Allowed max limit is 15 characters";
    else if (value.match(/\d/)) return "First name cannot contain numeric values";
    else if (value.match(/[\]\\!@=+~`_/[;'#$%^&*(),.?":{}|<>]/)) return "First name cannot contain special characters";
    else return '';
};

export const isLastName = (value) => {
    if (value.length > 20) return "Allowed max limit is 20 characters";
    else if (value.match(/\d/)) return "Last name cannot contain numeric values";
    else if (value.match(/[\]\\!@=+~`_/[;'#$%^&*(),.?":{}|<>]/)) return "Last name cannot contain special characters";
    else if (value[0] === ' ') return "Last name cannot start with space";
    else if (!value) return "Please enter last name";
    else return '';
};

export const isCountry = (value) => {
    if (value.length > 50) return "Allowed max limit is 50 characters";
    else if (value[0] === ' ') return "Country cannot start with space";
    else if (!value) return "Please enter country";
    else if (value.match(/\d/)) return "Country cannot contain numeric values";
    else if (value.match(/[\]\\!@=+~`_/[;'#$%^&*(),.?":{}|<>]/)) return "Country cannot contain special characters";
    else return '';
};

export const isPassword = (value) => {
    if (!value) return "Please enter new password";
    else if (!value.match(/^\S*$/)) return "Password cannot contain space";
    else if (value.length > 20 || value.length < 6) return "Allowed max limit is between 6-20 characters";
    else return '';
};

export const isOldPassword = (value) => {
    if (!value) return "Please enter old password";
    else if (!value.match(/^\S*$/)) return "Password cannot contain space";
    else return '';
}

export const isLoginPassword = (value) => {
    if (!value) return "Please enter password";
    else if (!value.match(/^\S*$/)) return "Password cannot contain space";
    else return '';
};

export const isInstitution = (value) => {
    if (!value) return "Please enter institution name";
    else if (value[0] === ' ') return "Institution name cannot start with space"
    else if (value.length > 100) return "Allowed max limit is 100 characters"
    else if (value.match(/\d/)) return "Institution name cannot contain numeric values"
    else if (value.match(/[\]\\!@=+~`_/[;'#$%^&*(),.?":{}|<>]/)) return "Institution name cannot contain special characters"
    else return ''
}
