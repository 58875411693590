import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import orientationReducer from "./orientationSlice";
import deviceTypeReducer from "./deviceTypeSlice";
import authorReducer from "./authorSlice";
import categoryReducer from "./categorySlice";
import notificationReducer from "./notificationSlice";
import highlightReducer from "./highlightSlice";
import peersearchReducer from "./peersearchSlice";
import footerlinkReducer from "./footerlinkSlice";
import videodetailReducer from "./videodetailSlice";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({ 
  user: userReducer, 
  orientation: orientationReducer, 
  deviceType: deviceTypeReducer, 
  author: authorReducer, 
  notification : notificationReducer, 
  category : categoryReducer, 
  highlight : highlightReducer, 
  peersearch : peersearchReducer, 
  footerlink : footerlinkReducer, 
  videodetail : videodetailReducer, 
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store)