import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { getNotification } from '../../lib/api';
import { useModal } from '../../store/ModalContext';
import Notification from '../Modals/Notification';

import LoadingSpinner from '../UI/LoadingSpinner';

const NotificationDetail = (props) => {

  const { currentUser } = useSelector((state) => state.user)

  const { DecryptionData } = useModal();

  const { notificationid } = useParams();

  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [notificationDetail, setNotificationDetail] = useState()
  const [show, setDetailsShow] = useState(false)

  const getNotificationDetails = useCallback(() => {
    setIsLoading(true)
    getNotification(currentUser, DecryptionData(notificationid))
      .then((res) => {
        setIsLoading(false)
        setNotificationDetail(res[0]);
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      })
  }, [currentUser, notificationid, DecryptionData])

  const handleBackNavigation = () => {
    history.go(-1);
  }

  const handleShowFromNotification = (data) => {
    setDetailsShow(data);
  };

  useEffect(() => {
    getNotificationDetails()
  }, [getNotificationDetails])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 640) {
        handleShowFromNotification(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[])

  return (
    <div className="notificationsDetailsWrapper w-full flex grow flex-col relative">
      <div className="notification-detail-holder detailsPage flex grow absolute size-full ">
        {isLoading && (
          <div className="parentDisable">
            <LoadingSpinner />
          </div>
        )}
        <div className={show === true ? "notificationPanelLeft hidden pt-6 h-full border-0 sm:border-r border-[#C2CED7] shrink basis-full sm:basis-[330px] md:basis-[400px] grow sm:grow-0 sm:shrink-0" : "notificationPanelLeft pt-6 h-full border-0 sm:border-r border-[#C2CED7] shrink basis-full sm:basis-[330px] md:basis-[400px] grow sm:grow-0 sm:shrink-0"}>
          <div className="flex flex-col gap-5 h-full">
            <div className="flex gap-4 px-4 cursor-pointer w-fit">
              <div
                className="backtoIconHolder sm:pt-2 pt-0 cursor-pointer sm:ml-0 -mt-1 sm:-mt-[4px]"
                onClick={handleBackNavigation}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M20.1201 26.5604L11.4268 17.8671C10.4001 16.8404 10.4001 15.1604 11.4268 14.1338L20.1201 5.44043"
                    stroke="#0057B8"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </div>
              <h2 className="notificationTitle text-[#333] sm:text-[28px] barlowBold leading-5 text-xl sm:leading-9">
                Notifications
              </h2>
            </div>
            <Notification isToggle={() => false} handleShowFromNotification={handleShowFromNotification} />
          </div>
        </div>
        <div className={show === true ? "grow detailedNotification absolute w-full left-0 transition-all delay-500 duration-500 h-full sm:block px-5 sm:px-5 md:px-5 lg:pl-6 lg:pr-14 py-6 overflow-auto" : "grow detailedNotification  p-0  sm:px-5 md:px-5 lg:pl-6 lg:pr-14 py-6 overflow-auto absolute left-full right-0 sm:w-full sm:static"}>
          {notificationDetail && (
            <>
              <div className='flex gap-3 cursor-pointer w-fit items-center  mb-4 sm:mb-5'>
                <div className="backtoIconHolder cursor-pointer block sm:hidden ml-[-8px] sm:ml-0" onClick={() => setDetailsShow(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M20.1201 26.5604L11.4268 17.8671C10.4001 16.8404 10.4001 15.1604 11.4268 14.1338L20.1201 5.44043"
                      stroke="#0057B8"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </div>
                <h2 className="text-[#333] sm:text-2xl barlowBold leading-5 text-lg sm:leading-7">
                  {notificationDetail.subject}
                </h2>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: notificationDetail.message,
                }}
              ></div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationDetail;