import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    orientation: null,
    afterHashKeyword: null
};

export const orientationSlice = createSlice({
    name: "orientation",
    initialState,
    reducers: {
        checkOrientation: (state, action) => {
            state.orientation = action.payload;
        },
        checkURL: (state, action) => {
            state.afterHashKeyword = action.payload;
        }
    },
});

export const { checkURL, checkOrientation } = orientationSlice.actions;

export default orientationSlice.reducer;
