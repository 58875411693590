const ContactInfo = (props) => {

    const { email } = props.userData;

    return (
        <div className="contactInfo flex flex-col gap-5">
            <div className="flex justify-between gap-4 items-center pb-4 border-b border-b-[#ddd]">
                <h3 className="professionalSubTitle text-[#333] text-base sm:text-lg font-bold leading-normal">Contact Info</h3>
            </div>
            <div className="infoHolder flex flex-col gap-6 pl-5">
                <div className="flex gap-6 sm:gap-6 responsiveGrid">
                    <div className="email">
                        <div className="flex flex-col gap-[6px]">
                            <label className="text-[#999] font-normal leading-normal sm:text-sm text-xs">Email Address  </label>
                            <p className="text-[#373A3C] leading-normal font-medium text-sm sm:text-base">{email}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;