import React from 'react';

const Input = React.forwardRef((props, ref) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };
  return (
    <div onKeyDown={handleKeyDown} className={props.className ? props.className : 'flex flex-col gap-[6px]'}>
      {props.label !== "" ? <label htmlFor={props.input.id} className={props.labelClass}>{props.label}  {props.isManditory && <span> *</span>}</label> : ""}
      <input ref={ref} {...props.input} />
    </div>
  );
});

export default Input;
