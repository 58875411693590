import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    categoryList: null,
};

export const categorySlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        updateCategoryList: (state, action) => {
            state.categoryList = action.payload;
        },
        clearCategoryList: (state) => {
            state.categoryList = null;
        },        
    },
});

export const { updateCategoryList, clearCategoryList } = categorySlice.actions;

export default categorySlice.reducer;